import Services from "../../services";

class PermintaanAsetApi {
  // API SERVER
  get(params) {
    return Services.get("/hrdu/approval_seleksi_vendor_aset/list", { params });
  }

  getHistory(params) {
    return Services.get("/hrdu/approval_seleksi_vendor_aset/history/list", {
      params,
    });
  }

  getSingle(params) {
    return Services.get("/hrdu/approval_seleksi_vendor_aset/detail", {
      params,
    });
  }

  approve(data) {
    return Services.post("/hrdu/approval_seleksi_vendor_aset/approve", data);
  }
}

export default new PermintaanAsetApi();
