const InfoItemHorizontal = ({
  label,
  text,
  width,
  style,
  className,
  fontSize,
}) => (
  <div
    className={`d-flex align-items-top text-nowrap ${className}`}
    style={style}
  >
    <div
      style={{ width: width ? width : 180, fontSize: fontSize ? fontSize : 14 }}
    >
      {label}
    </div>
    <div className="pl-3 pr-2" style={{ fontSize: fontSize ? fontSize : 14 }}>
      :
    </div>
    <div style={{ fontSize: fontSize ? fontSize : 14 }}>{text}</div>
  </div>
);

export default InfoItemHorizontal;
