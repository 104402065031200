import React, { useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import {
  Input,
  DatePicker,
} from "components";
import { RAEApi } from 'api';

const FormRAE = ({ formik, type }) => {
  const today = new Date().toISOString().slice(0, 10);
  const { values, errors, touched, setFieldValue } = formik;

  const getNomorRAE = (date) => {
    RAEApi.getNomorRAE({ tanggal: date })
      .then(({ data }) => {
        formik.setFieldValue("tgl_rae", date);
        formik.setFieldValue("no_rae", data.data);
      })
      .catch(() => window.alert("Nomor RAE gagal di generate!"));
  };

  useEffect(() => {
    type === 'CREATE' && getNomorRAE(today);
  }, [])

  return (
    <Row>
      <Col sm={6}>
        <DatePicker
          disabled={Boolean(type === 'DETAIL')}
          label="Tanggal Unit Cost"
          placeholderText="Pilih tanggal unit cost"
          selected={values.tgl_rae ? new Date(values.tgl_rae) : ""}
          onChange={(val) => {
            const value = val.toISOString().slice(0, 10);
            setFieldValue("tgl_rae", value);
            type === 'CREATE' && getNomorRAE(value);
          }}
          error={errors.tgl_rae && touched.tgl_rae && true}
          errorText={errors.tgl_rae && touched.tgl_rae && errors.tgl_rae}
        />
      </Col>
      <Col sm={6}>
        <Input
          label="Nomor Unit Cost"
          placeholder="Pilih tanggal untuk menentukan nomor unit cost"
          value={values.no_rae}
          readOnly={true}
          error={errors.no_rae && touched.no_rae && true}
          errorText={errors.no_rae && touched.no_rae && errors.no_rae}
        />
      </Col>
    </Row>
  );
};

export default FormRAE;