import React, { useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import {
  Input,
  DatePicker,
} from "components";
import { PenawaranApi } from 'api';

const FormPenawaran = ({ formik, type }) => {
  const today = new Date().toISOString().slice(0, 10);
  const { values, errors, touched, setFieldValue } = formik;

  const getNomorPenawaran = (date) => {
    PenawaranApi.getNomorPenawaran({ tanggal: date })
      .then(({ data }) => {
        formik.setFieldValue("tgl_penawaran", date);
        formik.setFieldValue("no_penawaran", data.data);
      })
      .catch(() => window.alert("Nomor Penawaran gagal di generate!"));
  };

  useEffect(() => {
    type === 'CREATE' && getNomorPenawaran(today);
  }, [])

  return (
    <Row>
      <Col sm={6}>
        <DatePicker
          disabled={Boolean(type === 'DETAIL')}
          label="Tanggal Penawaran"
          placeholderText="Pilih tanggal Penawaran"
          selected={values.tgl_penawaran ? new Date(values.tgl_penawaran) : ""}
          onChange={(val) => {
            const value = val.toISOString().slice(0, 10);
            setFieldValue("tgl_penawaran", value);
            type === 'CREATE' && getNomorPenawaran(value);
          }}
          error={errors.tgl_penawaran && touched.tgl_penawaran && true}
          errorText={errors.tgl_penawaran && touched.tgl_penawaran && errors.tgl_penawaran}
        />
      </Col>
      <Col sm={6}>
        <Input
          label="Nomor Penawaran"
          placeholder="Pilih tanggal untuk menentukan nomor Penawaran"
          value={values.no_penawaran}
          readOnly={true}
          error={errors.no_penawaran && touched.no_penawaran && true}
          errorText={errors.no_penawaran && touched.no_penawaran && errors.no_penawaran}
        />
      </Col>
    </Row>
  );
};

export default FormPenawaran;