// React
import { useRef, useState, useCallback } from "react";

// Components
import { ButtonGroup } from "react-bootstrap";
import { ActionButton, DeleteModal, Table, ThFixed, Th, Td } from "components";

// Files
import FileSaver from "file-saver";

// Icons
import {
  IoDocumentOutline,
  IoDownloadOutline,
  IoTrashOutline,
} from "react-icons/io5";

// Form
import { useFormikContext } from "formik";

// API
import { ApprovalPurchaseOrderApi } from "api";

const TabFile = ({ readOnly }) => {
  // Variables
  const types = ["pdf", "xlsx", "dwg", "docx", "skp", "zip", "rar"];
  const base_url = process.env.REACT_APP_PRO_FILE_BASE_URL;
  let no = 0;

  // Hooks
  const inputFileRef = useRef();
  const formik = useFormikContext();

  // States
  const [modalDeleteConfig, setModalDeleteConfig] = useState({
    index: "",
    show: false,
    loading: false,
  });
  const [submit, setSubmit] = useState(false);

  // Trigger input file agar terclick
  const onClickFilesHandler = () => !readOnly && inputFileRef.current.click();

  // Menangani saat file dipilih
  const onChangeFileHandler = (e) => {
    const file = e.target.files[0];

    // Check apakah ada file yang dipilih
    if (!file) return null;

    // Ambil file type lalu split dengan '/' dan ambil index ke 1 (cth: image/png menjadi png)
    const generalFileType = file.name.split(".").pop();

    if (types.includes(generalFileType)) {
      setSubmit(true);

      const formData = new FormData();
      formData.append(`file_0`, file);
      console.log(formData);

      ApprovalPurchaseOrderApi.upload(formData)
        .then((res) => {
          const file = formik?.values?.file;
          file.push(res.data.data?.file_0);

          formik.setFieldValue("file", file);
        })
        .finally(() => setSubmit(false));
    } else {
      window.alert(
        "File tidak valid! harap masukan file berformat .pdf .xlsx .dwg .docx .skp .zip .rar"
      );
    }
  };

  // Menangani hapus file
  const onDeleteHandler = useCallback(() => {
    setModalDeleteConfig({ ...modalDeleteConfig, loading: true });

    const file = formik.values.file;
    file.splice(modalDeleteConfig.index, 1);

    formik.setFieldValue("file", file);
    setModalDeleteConfig({
      index: "",
      show: false,
      loading: false,
    });

    // eslint-disable-next-line
  }, [modalDeleteConfig]);

  return (
    <div>
      {/* Button Section */}
      <div className="mb-3 text-right">
        <input
          ref={inputFileRef}
          type="file"
          style={{ display: "none" }}
          onChange={onChangeFileHandler}
        />
        {!readOnly && (
          <ActionButton
            text="Tambah File"
            onClick={onClickFilesHandler}
            loading={submit}
          />
        )}
      </div>

      {/* Table */}
      <Table>
        <thead className="bg-light">
          <tr>
            <ThFixed>No</ThFixed>
            <Th>Nama File</Th>
            <Th width={60}>Aksi</Th>
          </tr>
        </thead>
        <tbody>
          {formik.values.file?.filter((val) => {
            // eslint-disable-next-line
            const file = val.split(".");

            return types.includes(file[file.length - 1]);
          }).length > 0 ? (
            formik.values.file?.map((val, index) => {
              const file = val.split("/");
              const type = val.split(".");
              const nama = file[file.length - 1];
              const link = `${base_url}/${val}`;

              if (types.includes(type[type.length - 1])) {
                no += 1;

                return (
                  <tr>
                    <Td textCenter>{no}</Td>
                    <Td>{nama}</Td>
                    <Td className="text-center">
                      <ButtonGroup className="m-1">
                        {/* Button Download */}
                        <ActionButton
                          size="sm"
                          onClick={() => FileSaver.saveAs(link, nama)}
                        >
                          <IoDownloadOutline />
                        </ActionButton>

                        {/* Button Delete */}
                        {!readOnly && (
                          <ActionButton
                            size="sm"
                            variant="danger"
                            onClick={() => {
                              setModalDeleteConfig({
                                show: true,
                                loading: false,
                                index: index,
                              });
                            }}
                          >
                            <IoTrashOutline />
                          </ActionButton>
                        )}
                      </ButtonGroup>
                    </Td>
                  </tr>
                );
              }

              return null;
            })
          ) : (
            <tr style={{ cursor: "pointer" }} onClick={onClickFilesHandler}>
              <Td colSpan={3}>
                <div className="d-flex flex-column justify-content-center align-items-center py-5">
                  <IoDocumentOutline size={50} />
                  <b style={{ fontSize: 16 }} className="mt-2">
                    Tidak ada file
                  </b>
                </div>
              </Td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Modal */}
      <DeleteModal
        show={modalDeleteConfig.show}
        title="File"
        loading={modalDeleteConfig.loading}
        onConfirm={onDeleteHandler}
        onHide={() =>
          setModalDeleteConfig({
            show: false,
            index: "",
          })
        }
      />
    </div>
  );
};

export default TabFile;
