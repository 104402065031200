import { Formik } from "formik";
import React, { useEffect } from "react";
import {
  FormPengajuanPettyCashContent,
  TableHistoryPengajuanPettyCash,
  TablePengajuanPettyCashBelumDisetujui,
} from "./__PengajuanPettyCashComps__";
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
} from "./__PengajuanPettyCashUtils__";
import { Card } from "react-bootstrap";
import { ButtonBack, ButtonCreate } from "@bhawanadevteam/react-core";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useMutation, useQuery } from "react-query";
import PengajuanPettyCashApi from "./__PengajuanPettyCashApi__";
import { DataStatus } from "components";
import { useModalConfirm } from "hooks2";

export const PengajuanPettyCashCreate = ({ setNavbarTitle }) => {
  const modalConfirm = useModalConfirm();
  const { id } = useParams();
  const { state } = useLocation();
  const history = useHistory();

  const { data, isFetching, isError } = useQuery({
    queryKey: ["single", "pengajuan_petty_cash", id],
    queryFn: () =>
      PengajuanPettyCashApi.getSingle({
        id_unit_produksi: id,
        id_penerima_petty_cash: state?.id_penerima_petty_cash,
      }),
  });

  const createPengajuanPettyCash = useMutation((data) =>
    PengajuanPettyCashApi.create(data)
  );

  const onSubmitForm = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    await modalConfirm.trigger({
      size: "md",
      type: "create",

      component: (
        <Formik initialValues={values}>
          <FormPengajuanPettyCashContent action="DETAIL" />
        </Formik>
      ),
      onSubmit: async () => {
        modalConfirm.loading();

        return await createPengajuanPettyCash
          .mutateAsync(formSubmitValueMapper(values), resetForm)
          .then(() => {
            modalConfirm.infoSuccess({
              title: "Data Pengajuan Petty Cash Berhasil Ditambah",
              customTextInfoDetail: "Data telah tersimpan ke database",
              size: "md",
              onHide: () => {
                modalConfirm.close();
                history.push("/human-resource/transaksi/pengajuan-petty-cash/");
              },
            });
          })
          .catch((err) => {
            modalConfirm.infoError({
              title: "Data Pengajuan Petty Cash Gagal Ditambah",
              customTextInfoTitle: "Penyebab Kegagalan:",
              customTextInfoDetail: err?.response?.data?.message,
            });
          });
      },
      onHide: () => modalConfirm.close(),
    });
  };

  useEffect(() => setNavbarTitle("Pengajuan Petty Cash"), []);

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues(data?.data?.data)}
      onSubmit={onSubmitForm}
      validationSchema={formValidationSchema}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <>
          {isFetching ? (
            <DataStatus loading text="Memuat Data..." />
          ) : (
            <>
              <div className="d-flex justify-content-between mb-2">
                <small>
                  <b>List History Pengajuan Petty Cash</b>
                </small>
                <ButtonBack
                  onClick={() =>
                    history.push(
                      "/human-resource/transaksi/pengajuan-petty-cash"
                    )
                  }
                />
              </div>
              <Card className="mb-3">
                <Card.Body>
                  <TableHistoryPengajuanPettyCash />
                </Card.Body>
              </Card>
              <small>
                <b>List Pengajuan Petty Cash yang Belum Diterima</b>
              </small>
              <Card className="mb-3">
                <Card.Body>
                  <TablePengajuanPettyCashBelumDisetujui />
                </Card.Body>
              </Card>
              <small>
                <b>Tambah Pengajuan Petty Cash</b>
              </small>
              <Card>
                <Card.Body>
                  <FormPengajuanPettyCashContent action="CREATE" />
                </Card.Body>
              </Card>

              <div className="d-flex justify-content-end mt-3">
                <ButtonCreate
                  text="Simpan"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                />
              </div>
            </>
          )}
        </>
      )}
    </Formik>
  );
};
