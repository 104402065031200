import React, { useState, useEffect, useCallback } from "react"
import { useDropzone } from "react-dropzone"
import ImageViewer from "react-simple-image-viewer"
import {
    IoTrashBinOutline,
    IoDownloadOutline,
    IoAddOutline,
} from "react-icons/io5"
import { saveAs } from 'file-saver'
import { Button } from "react-bootstrap"
import {
    CRUDLayout,
    THead,
    TBody,
    ThFixed,
    TdFixed,
    Tr,
    Td,
    Th,
    DeleteButton,
    ReadButton,
    DataStatus,
} from "components";

const FilesUpload = ({ filesUpload, setFilesUpload, deleteRemoteFile, handleUpdateFilesOnDrop, action }) => {    // action : create | update | detail
    const path = require("path");
    const typeImage = [".jpg", ".png", ".gif", ".jpeg"];          // ACCEPTED IMAGES EXTENSION
    const typeDoc = [".pdf", ".xlsx", ".dwg", ".docx", ".skp"];   // ACCEPTED FILES EXTENSION
    const [isPreviewImage, setIsPreviewImage] = useState({ show: false, index: "" })

    // STYLE
    const btnDownloadImgStyle = {
        borderRadius: '5px',
        opacity: .8,
        position: 'absolute',
        zIndex: 1,
        top: '10px',
        cursor: 'pointer'
    }
    action !== 'detail' ? btnDownloadImgStyle.right = '50px' : btnDownloadImgStyle.right = '10px'

    const btnDownloadFileStyle = {
        borderRadius: '5px',
        opacity: .8,
        cursor: 'pointer'
    }

    const inputStyle = {
        textAlign: "center",
        borderRadius: 20,
        margin: '.5rem',
        border: "3px dashed #eeeeee"
    };

    const thumb = {
        borderRadius: 20,
        margin: '.5rem',
        display: 'grid',
        height: '8.5rem',
        boxSizing: "border-box",
    };

    const thumbInner = {
        position: 'relative'
    };

    const img = {
        display: "block",
        width: '15.5rem',
        height: '7.5rem',
        objectFit: 'cover',
        borderRadius: 20,
    };

    const btnDeleteImgStyle = {
        borderRadius: '5px',
        opacity: .8,
        position: 'absolute',
        zIndex: 1,
        right: '10px',
        top: '10px',
        cursor: 'pointer'
    }
    // END STYLE

    const createFilesOnDrop = (acceptedFiles) => {
        acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }))
        setFilesUpload((prevFiles) => [...prevFiles, ...acceptedFiles])
    }

    const updateFilesOnDrop = (acceptedFiles) => {
        const formData = new FormData();

        acceptedFiles.reduce((acc, file, index) => {
            formData.append("file_" + index, file);
            return file;
        }, {});

        handleUpdateFilesOnDrop(formData)
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: ".jpg, .png, .gif, .jpeg, .pdf, .xlsx, .dwg, .docx, .skp",
        onDrop: (acceptedFiles) => {
            if (action === 'create') { createFilesOnDrop(acceptedFiles) }
            if (action === 'update') { updateFilesOnDrop(acceptedFiles) }
        },
    });

    // SHOW IMAGE PREVIEW
    const openImageViewer = useCallback((index) => {
        setIsPreviewImage({ show: true, index })
    }, []);

    // MENGHAPUS GAMBAR PADA LOCAL STATE SAAT CREATE DATA
    const removeImg = (img) => {
        let temp = filesUpload.filter((res, index) => res.name !== img.name);
        setFilesUpload([...temp]);
    }

    // DOWNLOAD IMAGE FROM SERVER
    const downloadImg = (img) => {
        saveAs(img.path_gambar, img.path_gambar.split("/").pop())
        // fetch(img.path_gambar, { method: 'GET', mode: 'no-cors' })
        //     .then((res) => {
        //         res.arrayBuffer().then((buffer) => {
        //             console.log(buffer)
        //             const url = window.URL.createObjectURL(new Blob([buffer]))
        //             console.log('url', url)
        //             const link = document.createElement("a");
        //             link.href = url;
        //             link.setAttribute("download", img.path_gambar.split("/").pop())
        //             document.body.appendChild(link)
        //             link.click()
        //         })
        //     }).catch((err) => console.log('err', err))
    }

    // CLOSE IMAGE PREVIEW
    const closeImageViewer = () => setIsPreviewImage({ show: false, index: "" })

    // REMOVE DOCUMENT FROM LIST
    const removeDoc = (doc) => {
        let temp = filesUpload.filter((res) => res.name !== doc.name);
        setFilesUpload([...temp]);
    };

    // VIEW DOCUMENT IN NEW TAB
    const viewDoc = (val) => window.open(val)

    // LIST IMAGE PREVIEW 
    const filesViewer = filesUpload
        .filter((res) => typeImage.includes(path.extname(res?.name || res.path_gambar)))
        .map((res) => res?.preview || res.path_gambar);

    useEffect(() => {
        filesUpload.forEach((file) => URL.revokeObjectURL(file))
    }, [filesUpload])

    // KOMPONEN BUTTON DELETE IMAGE
    const DeleteImageBtn = ({ onClick }) => (
        <Button
            variant="danger"
            size="sm"
            className="m-1"
            style={btnDeleteImgStyle}
            onClick={onClick}
        >
            <IoTrashBinOutline />
        </Button>
    )

    // KOMPONEN BUTTON DOWNLOAD IMAGE
    const DownloadImageBtn = ({ onClick }) => {
        return <Button
            variant="primary"
            size="sm"
            className="m-1"
            style={btnDownloadImgStyle}
            onClick={onClick}
        >
            <IoDownloadOutline />
        </Button>
    }
    // KOMPONEN BUTTON DOWNLOAD FILE
    const DownloadFileBtn = ({ onClick }) => {
        return <Button
            variant="primary"
            size="sm"
            className="m-1"
            style={btnDownloadFileStyle}
            onClick={onClick}
        >
            <IoDownloadOutline />
        </Button>
    }

    // KOMPONEN THUMBNAIL GAMBAR
    const ImagesThumbnail = () => filesUpload
        .filter((res) => typeImage.includes(path.extname(res?.name || res.path_gambar))).map((val, index) => {
            const path_gambar_last = val.path_gambar.split('/').pop()
            return (<div key={index} style={thumb}>
                <div style={thumbInner}>
                    <img src={val?.preview || val.path_gambar} style={img} onClick={() => openImageViewer(index)} />
                    <small>{val?.name || `...${path_gambar_last.substring(path_gambar_last.length, path_gambar_last.length - 28)}`}</small>
                    {action !== 'detail' && action === 'create' && <DeleteImageBtn onClick={() => removeImg(val)} />}
                    {action !== 'detail' && action === 'update' && <DeleteImageBtn onClick={() => deleteRemoteFile(val.id_item_buaso_gambar_barang_jadi)} />}

                    {val.path_gambar && <DownloadImageBtn onClick={() => downloadImg(val)} />}
                </div>
                {isPreviewImage.show && (
                    <ImageViewer
                        src={filesViewer}
                        currentIndex={isPreviewImage.index}
                        disableScroll={false}
                        onClose={closeImageViewer}
                    />
                )}
            </div>
            )
        });

    // DOCUMENT LIST COMPONENT
    const DocumentList = () => (
        <CRUDLayout.Table>
            <THead>
                <Tr>
                    <ThFixed>No.</ThFixed>
                    <Th>Nama File</Th>
                    <ThFixed>Aksi</ThFixed>
                </Tr>
            </THead>
            <TBody>
                {filesUpload.filter((res) => typeDoc.includes(path.extname(res?.name || res.path_gambar)))
                    .map((val, index) => (
                        <Tr key={index}>
                            <TdFixed>{index + 1}</TdFixed>
                            <Td>{val?.name || val.path_gambar.split("/").pop()}</Td>
                            <TdFixed>
                                <div>
                                    <div className="d-flex justify-content-center">
                                        {
                                            path.extname(val?.name || val.path_gambar) === ".pdf" &&
                                            <ReadButton onClick={() => { viewDoc(val?.preview || val.path_gambar) }} />
                                        }
                                        {val.path_gambar && path.extname(val.path_gambar) !== ".pdf" && <DownloadFileBtn onClick={() => downloadImg(val)} />}
                                        {action !== 'detail' && action === 'create' && <DeleteButton onClick={() => removeDoc(val)} />}
                                        {action !== 'detail' && action === 'update' && <DeleteButton onClick={() => deleteRemoteFile(val.id_item_buaso_gambar_barang_jadi)} />}
                                    </div>
                                </div>
                            </TdFixed>
                        </Tr>
                    ))}
            </TBody>
        </CRUDLayout.Table>
    );

    return (
        <div>
            <h7>File Images</h7>
            <section className="bg-light d-flex flex-wrap justify-content-start" style={inputStyle}>
                {filesUpload.filter((res) => typeImage.includes(path.extname(res?.name || res.path_gambar))).length > 0 ? <ImagesThumbnail /> : <h6 className="m-3">Tidak Ada File Gambar </h6>}
                {/* INPUT FILES */}
                {action !== 'detail' &&
                    <div {...getRootProps({ className: "w-100 d-flex flex-column justify-content-center align-items-center mt-3 dropzone" })} style={{ cursor: 'pointer' }}>
                        <input {...getInputProps()} />
                        <div className="px-2" style={inputStyle}>
                            <IoAddOutline size={35} />
                            <small>UPLOAD FILES</small>
                        </div>
                    </div>}
            </section >
            <div className="mt-4">
                <h6>File Documents</h6>
                <DocumentList />
            </div>
        </div >
    )
}

export default FilesUpload
