import {
  ButtonCreate,
  ButtonDelete,
  ButtonDetail,
  ButtonUpdate,
} from "@bhawanadevteam/react-core";
import {
  ActionButton,
  DataStatus,
  Input,
  SelectSearch,
  TBody,
  Table,
  Td,
  Th,
  ThFixed,
  Tr,
} from "components";
import Thead from "components/Table/THead";
import { Formik, useFormikContext } from "formik";
import React, { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import * as Yup from "yup";

export const TablePermintaanAset = ({
  dataTable = [],
  type,
  action,
  setModalConfig,
  modalConfig,
}) => {
  const { values, setValues } = useFormikContext();

  const onDeleteDataTable = (ind) => {
    const filterData = dataTable?.filter((val, index) => index !== ind);
    setValues({ ...values, detail: filterData });
  };

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Grup Aset</ThFixed>
            <Th>Kategori Aset</Th>
            <Th>Jenis Aset</Th>
            <Th>Nama Item Aset</Th>
            <Th>Keperluan</Th>
            <Th>Metode Pengadaan</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </Thead>
        <TBody>
          {/* {action !== "detail" && <FormInputTable action="create" />} */}

          {dataTable?.length > 0 ? (
            dataTable?.map((val, ind) => (
              <Tr key={ind}>
                <Td>{ind + 1}</Td>
                <Td>{val.namaGrupAset ?? "-"}</Td>
                <Td>{val.namaKategoriAset ?? "-"}</Td>
                <Td>{val.namaJenisAset ?? "-"}</Td>
                <Td>{val.namaAset ?? "-"}</Td>
                <Td>{val.keperluanLabel ?? "-"}</Td>
                <Td>{val.metodePengadaanLabel ?? "-"}</Td>

                <Td>
                  <ButtonGroup>
                    <ButtonDetail
                      noText
                      icon
                      size="sm"
                      variant="primary"
                      onClick={() =>
                        setModalConfig({
                          ...modalConfig,
                          show: true,
                          type: "read",
                          data: val,
                        })
                      }
                    />
                    {action !== "detail" && (
                      <>
                        <ButtonUpdate
                          noText
                          icon
                          variant="success"
                          size="sm"
                          onClick={() =>
                            setModalConfig({
                              ...modalConfig,
                              show: true,
                              type: "update",
                              data: val,
                              ind: ind,
                            })
                          }
                        />
                        <ButtonDelete
                          noText
                          icon
                          size="sm"
                          onClick={() => onDeleteDataTable(ind)}
                        />
                      </>
                    )}
                  </ButtonGroup>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="8">
                <DataStatus text="Tidak Ada Data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </div>
  );
};
