import React, { useEffect, useState, useContext } from "react";
import { Modal, Spinner, ButtonGroup } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  IoTrashOutline,
  IoPencilOutline,
  IoOptionsOutline,
  IoAddOutline,
  IoCloseOutline,
  IoCheckmark,
} from "react-icons/io5";
import {
  Alert,
  SelectSearch,
  Input,
  ActionButton,
  Table,
  Td,
} from "components";
import AnalisaBarangJadiRAE from "../AnalisaBarangJadi";
import { DecimalConvert, RupiahConvert } from "utilities";

const TableBarangJadi = ({ dataBarangJadi, setDataBarangJadi }) => {
  const [processedData, setProcessedData] = useState({});
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });

  const grandTotal = dataBarangJadi.reduce((acc, { harga_satuan, qty }) => {
    const subtotal = parseFloat(harga_satuan ?? 0) * parseFloat(qty ?? 0);
    return parseFloat(acc + subtotal);
  }, 0);

  const TableSection = () => {
    return (
      <>
        <div className="py-2 p-1">
          <b>List Barang Jadi</b>
        </div>

        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => setAlertConfig({ ...alertConfig, show: false })}
        />

        <table
          className="table table-sm table-bordered"
          style={{ fontSize: "14px" }}
        >
          <thead className="text-center bg-light">
            <tr>
              <th style={{ width: "100px" }}>Kode Barang Jadi</th>
              <th className="align-middle" style={{ width: "350px" }}>
                Barang Jadi
              </th>
              <th className="align-middle">Qty</th>
              <th className="align-middle">Satuan</th>
              <th className="align-middle">Harga Satuan</th>
              <th className="align-middle">Sub Total</th>
            </tr>
          </thead>
          <tbody>
            {dataBarangJadi?.map((val, index) => {
              const checkQty = val.qty ? parseFloat(val.qty) : 0;
              const checkSatuan = val.harga_satuan
                ? parseFloat(val.harga_satuan)
                : 0;
              const subTotal = parseFloat(checkQty * checkSatuan);

              return (
                <tr key={index}>
                  <td>{val.kode_item}</td>
                  <td>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setProcessedData({
                          ...val,
                          index: index,
                        });
                        setModalConfig({
                          show: true,
                          type: "analisa",
                          title: (
                            <span className="text-primary">
                              Analisa Barang Jadi Unit Cost
                            </span>
                          ),
                        });
                      }}
                    >
                      {val.nama_item}
                    </a>
                  </td>
                  <td className="text-right">
                    {DecimalConvert(val.qty).getWithComa}
                  </td>
                  <td>{val.satuan}</td>
                  <td className="text-right">
                    {
                      RupiahConvert(
                        parseFloat(val.harga_satuan ?? 0).toString()
                      ).getWithComa
                    }
                  </td>
                  <td className="text-right">
                    {RupiahConvert(subTotal?.toString()).getWithComa}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {/* Total */}
            <tr className="bg-light">
              <td colSpan={5} className="text-right py-2 align-middle">
                <b>Grand Total :</b>
              </td>
              <td className="text-right align-middle">
                <b>{RupiahConvert(String(grandTotal)).getWithComa}</b>
              </td>
            </tr>
          </tfoot>
        </table>
      </>
    );
  };

  const ModalSection = ({
    processedData,
    modalConfig,
    setModalConfig,
    dataBarangJadi,
    setDataBarangJadi,
  }) => {
    const AnalisaBarangJadiModal = ({
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig,
    }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadiRAE
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            modalConfig={modalConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      );
    };

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === "delete" ? "md" : "xl"}
        onHide={() => setModalConfig({ ...modalConfig, show: false })}
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <AnalisaBarangJadiModal
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </Modal>
    );
  };

  return (
    <>
      <TableSection />
      {modalConfig.show && (
        <ModalSection
          processedData={processedData}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
        />
      )}
    </>
  );
};

export default TableBarangJadi;
