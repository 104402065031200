import * as Yup from 'yup';

export const formValidationSchema = Yup.object().shape({
  tgl_penerimaan_petty_cash: Yup.string().required(
    'Pilih Tanggal Penerimaan Petty Cash'
  ),
  keterangan: Yup.string().required('Masukkan Keterangan'),
  bukti: Yup.object()
    .required('Masukkan bukti')
    .typeError('Masukkan bukti berupa file .jpg/.jpeg/.png'),
});
