import {
  DataStatus,
  TBody,
  THead,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
  Tr,
} from "components";
import { useFormikContext } from "formik";
import React from "react";
import { DateConvert, RupiahConvert } from "utilities";

export const TableHistoryPengajuanPettyCash = () => {
  const { values } = useFormikContext();
  const totalPettyCashBelumDikembalikan =
    values?.history?.length > 0
      ? values?.history?.reduce((acc, curr) => {
          const sum =
            parseFloat(curr?.nominal_penerimaan_petty_cash ?? 0) -
            parseFloat(curr?.nominal_realisasi_petty_cash ?? 0);

          return parseFloat(acc) + sum;
        }, 0)
      : 0;

  return (
    <Table>
      <THead>
        <Tr>
          <ThFixed>No.</ThFixed>
          <ThFixed>Informasi Pengajuan Petty Cash</ThFixed>
          <ThFixed>Informasi Penerimaan Petty Cash</ThFixed>
          <ThFixed>Informasi Realisasi Petty Cash</ThFixed>
          <Th>Pengajuan Petty Cash</Th>
          <Th>Penerimaan Petty Cash</Th>
          <Th>Realisasi Petty Cash</Th>
          <Th>Sisa Petty Cash</Th>
          <Th>Petty Cash Yang Belum Dikembalikan</Th>
        </Tr>
      </THead>
      <TBody>
        {values?.history?.length > 0 ? (
          <>
            {values?.history?.map((val, ind) => (
              <Tr key={ind}>
                <TdFixed>{ind + 1}</TdFixed>
                <TdFixed>
                  <div>
                    {val?.tgl_pengajuan_petty_cash
                      ? DateConvert(new Date(val?.tgl_pengajuan_petty_cash))
                          .custom
                      : "-"}
                  </div>
                  <div>{val?.no_pengajuan_petty_cash ?? "-"}</div>
                </TdFixed>
                <TdFixed>
                  <div>
                    {val?.tgl_penerimaan_petty_cash
                      ? DateConvert(new Date(val?.tgl_penerimaan_petty_cash))
                          .custom
                      : "-"}
                  </div>
                  <div>{val?.no_penerimaan_petty_cash ?? "-"}</div>
                </TdFixed>
                <TdFixed>
                  <div>
                    {val?.tgl_realisasi_petty_cash
                      ? DateConvert(new Date(val?.tgl_realisasi_petty_cash))
                          .custom
                      : "-"}
                  </div>
                  <div>{val?.no_realisasi_petty_cash ?? "-"}</div>
                </TdFixed>
                <Td textRight>
                  {
                    RupiahConvert(
                      parseFloat(
                        val?.nominal_pengajuan_petty_cash ?? 0
                      ).toString()
                    ).getWithComa
                  }
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(
                      parseFloat(
                        val?.nominal_penerimaan_petty_cash ?? 0
                      ).toString()
                    ).getWithComa
                  }
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(
                      parseFloat(
                        val?.nominal_realisasi_petty_cash ?? 0
                      ).toString()
                    ).getWithComa
                  }
                </Td>
                <Td textRight>
                  {parseFloat(val?.nominal_realisasi_petty_cash) > 0
                    ? RupiahConvert(
                        String(
                          parseFloat(val?.nominal_penerimaan_petty_cash ?? 0) -
                            parseFloat(val?.nominal_realisasi_petty_cash ?? 0)
                        )
                      ).getWithComa
                    : RupiahConvert("0").getWithComa}
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(
                      String(
                        parseFloat(val?.nominal_penerimaan_petty_cash ?? 0) -
                          parseFloat(val?.nominal_realisasi_petty_cash ?? 0)
                      )
                    ).getWithComa
                  }
                </Td>
              </Tr>
            ))}
            <Tr>
              <Td className="text-right font-weight-bold" colSpan="8">
                TOTAL PETTY CASH BELUM DIKEMBALIKAN
              </Td>
              <Td className="text-right font-weight-bold">
                {
                  RupiahConvert(String(totalPettyCashBelumDikembalikan ?? 0))
                    .getWithComa
                }
              </Td>
            </Tr>
          </>
        ) : (
          <Tr>
            <Td colSpan="9">
              <DataStatus text="Tidak Ada Data" />
            </Td>
          </Tr>
        )}
      </TBody>
    </Table>
  );
};
