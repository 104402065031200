import React from "react";
import { Button, Spinner } from "react-bootstrap";

const StatusInfoPeluangButton = ({
  status,
  onClick,
  size,
  disabled,
  loading,
}) => {
  const checkStatus =
    status === "1"
      ? { text: "INFO", variant: "warning" }
      : status === "2"
      ? { text: "PERSPEKTIF", variant: "success" }
      : { text: "TARGET", variant: "primary" };
  return (
    <div>
      <Button
        variant={checkStatus.variant}
        size={size}
        block
        disabled={disabled}
        className="text-white"
        onClick={onClick}>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="d-flex align-items-center">
              <Spinner animation="border" variant="white" size="sm" />
              <div className="ml-2">Memproses . . .</div>
            </div>
          </div>
        ) : (
          <span className="text-uppercase">{checkStatus.text}</span>
        )}
      </Button>
    </div>
  );
};

export default StatusInfoPeluangButton;
