import React, { useState, useEffect, useContext, memo } from "react";
import { Card, Row, Col, Modal } from "react-bootstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import {
  Alert,
  BackButton,
  DataStatus,
  TextArea,
  ActionButton
} from "components";
import { FormRAB, InfoSection, TableBarangJadi } from "./Section";
import { DateConvert } from "utilities";
import { RABApi } from "api";

const DetailRAB = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const { id_rab } = useParams();
  const { no_rab, tab } = location?.state || {};

  const ContentDetailRAB = memo(() => {
    const [dataRAB, setDataRAB] = useState({});
    const [dataApproval, setDataApproval] = useState([]);
    const [approveStatus, setApproveStatus] = useState("V");
    const [isPageLoading, setIsPageLoading] = useState(false)
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);
    const [dataBarangJadi, setDataBarangJadi] = useState([]);
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    });
    const [modalConfig, setModalConfig] = useState({
      show: false,
      type: "",
      title: "",
    });

    const getInitialData = () => {
      setIsPageLoading(true)

      RABApi.getSingle({ no_rab })
        .then(rab => {
          const { profit } = rab?.data?.data?.detail
          const behavior = String(rab?.data?.data?.behavior).toUpperCase();
          const mapDataBarangJadi = rab?.data?.data?.detail?.detail?.map(val => Object({
            ...val,
            qty: val.qty_rab,
            harga_satuan: val.unit_cost,
            profit: parseInt(val.profit),
            harga_profit: ((parseInt(profit) / 100) + 1) * parseFloat(val.unit_cost),
            satuan: val.nama_satuan_beli,
            unique: Math.random().toString(36).substring(2, 9)
          }))

          setDataRAB(rab?.data?.data?.detail ?? {})
          setDataBarangJadi(mapDataBarangJadi ?? [])
          setApproveStatus(behavior ?? 'V')
          setDataApproval(rab?.data?.data?.approval ?? [])
        })
        .catch((err) => {
          setIsFetchingFailed(true);
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal dimuat!",
          });
        })
        .finally(() => setIsPageLoading(false));
    }

    const formInitialValues = {
      id_rab: id_rab,
      id_rae: dataRAB?.id_rae,
      tgl_rab: dataRAB?.tgl_rab ?? "",
      no_rab: dataRAB?.no_rab ?? "",
      profit: parseInt(dataRAB?.profit ?? 0)
    };
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
    });

    useEffect(() => {
      setNavbarTitle("Rencana Anggaran Biaya (RAB)");
      getInitialData();
    }, [])

    const FormCard = ({ values, handleChange, validateForm, errors, touched, setTouched, dirty }) => {
      const dataPengaju = dataRAB?.approval ? dataRAB.approval : [];
      const dataDetail = dataRAB.detail ? dataRAB.detail : "-";

      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Approval Rencana Anggaran Biaya</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {dataApproval.map((val, index) => (
                <Col sm>
                  <InfoItem
                    title1={
                      val.status_approval == "VER" ? `Pemeriksa ${val.approval_level}` : "Pengesah"
                    }
                    value1={val.nama_karyawan ?? "-"}
                    title2="Catatan"
                    value2={val.catatan ?? "-"}
                  />
                </Col>
              ))}
            </Row>
            <hr />
            {dataPengaju.length > 1 && <hr />}
            <div>
              <TextArea
                label="Catatan"
                name="catatan"
                placeholder="Masukan catatan"
                value={values.catatan}
                onChange={handleChange}
                error={errors.catatan && true}
                errorText={errors.catatan}
              />
              <div className="d-flex justify-content-end mt-3">
                <ActionButton
                  text="Reject"
                  variant="danger"
                  className="m-1"
                  onClick={() => {
                    if (dirty) {
                      setModalConfig({
                        show: true,
                        type: "REJ",
                        title: <span className="text-danger">Reject Rencana Anggaran Biaya</span>,
                      });
                    }
                    validateForm();
                  }}
                />
                {approveStatus !== 'A' && <ActionButton
                  text="Revise"
                  variant="warning"
                  className="m-1 text-white"
                  onClick={() => {
                    if (dirty) {
                      setModalConfig({
                        show: true,
                        type: "REV",
                        title: (
                          <span className="text-warning">Revise Rencana Anggaran Biaya</span>
                        ),
                      });
                    }
                    validateForm();
                  }}
                />}
                <ActionButton
                  text={approveStatus === "A" ? "Approve" : "Verify"}
                  variant="success"
                  className="m-1"
                  onClick={() => {
                    if (dirty) {
                      setModalConfig({
                        show: true,
                        type: "APP",
                        title: (
                          <span className="text-success">
                            {approveStatus === "A" ? "Approve" : "Verify"} Rencana Anggaran Biaya
                          </span>
                        ),
                      });
                    }
                    validateForm();
                  }}
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      );
    };

    const CatatanApproval = () => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Approval RAB</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {dataRAB?.stakeholder?.map((val, index) => index !== 0 && val.status_approval !== "PEN" && (
                <Col sm>
                  <InfoItem
                    title1={
                      val.status_approval === "VER" || val.status_approval === "REV"
                        ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                        : val.status_approval === "APP"
                          ? "Pengesah"
                          : "Di Tolak Oleh"
                    }
                    value1={val.nama_karyawan ?? "-"}
                    title2="Catatan"
                    value2={val.catatan ?? "-"}
                  />
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
      const ModalContent = () => (
        <>
          <Modal.Body className="text-center">
            <p>
              <h6>
                <b>{modalConfig.title} dengan catatan :</b>
              </h6>
              <span>{values.catatan}</span>
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <ActionButton
              variant="outline-secondary"
              text="Batal"
              className="px-4"
              onClick={() =>
                setModalConfig({
                  ...modalConfig,
                  show: false,
                })
              }
            />
            <ActionButton
              text={
                modalConfig.type === "APP"
                  ? approveStatus === "A"
                    ? "APPROVE"
                    : "VERIFY"
                  : modalConfig.type == "REV"
                    ? "REVISE"
                    : "REJECT" + " RAB"
              }
              variant={
                modalConfig.type === "APP"
                  ? "success"
                  : modalConfig.type === "REV"
                    ? "warning"
                    : "danger"
              }
              className="m-1 px-3 text-white"
              onClick={handleSubmit}
              loading={isSubmitting}
            />
          </Modal.Footer>
        </>
      );

      return (
        <Modal
          show={modalConfig.show}
          onHide={() =>
            setModalConfig({
              ...modalConfig,
              show: false,
            })
          }
        >
          <Modal.Header closeButton className="py-2 d-flex align-items-center">
            <Modal.Title>
              <small>{modalConfig.title}</small>
            </Modal.Title>
          </Modal.Header>
          <ModalContent />
        </Modal>
      );
    };

    return (
      <>
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Detail Data RAB</b>
            <BackButton onClick={() => history.goBack()} />
          </Card.Header>
          <Card.Body>
            <Alert
              showCloseButton
              show={alertConfig.show}
              variant={alertConfig.variant}
              text={alertConfig.text}
              onClose={() => setAlertConfig({ ...alertConfig, show: false })} />
            {
              isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." />
                : isFetchingFailed ? <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
                  : <>
                    <InfoSection data={dataRAB} />
                    <hr />
                    <FormRAB formik={formik} type='DETAIL' />
                    <hr />
                    <TableBarangJadi
                      dataBarangJadi={dataBarangJadi}
                      setDataBarangJadi={setDataBarangJadi}
                      profit={formik.values.profit} />
                  </>
            }
          </Card.Body>
        </Card>

        {!isPageLoading && tab !== 'history' && <Formik
          initialValues={{ catatan: "" }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required("Masukan Catatan"),
          })}
          onSubmit={(values) => {
            const finalValues = {
              ...values,
              no_transaksi: dataRAB.no_rab,
              status_approval: modalConfig.type,
              tgl_approval: DateConvert(new Date()).default,
              approval_baseline: dataRAB.baseline,
            };

            RABApi.approve(finalValues)
              .then((res) => {
                history.push("/human-resource/approval/rab", {
                  alert: {
                    show: true,
                    variant: "primary",
                    text: `${modalConfig.type == "APP"
                      ? "Approve"
                      : modalConfig.type == "REV"
                        ? "Revise"
                        : "Reject"
                      } data berhasil!`,
                  },
                });
              })
              .catch((err) => {
                setAlertConfig({
                  variant: "danger",
                  text: `Ubah data gagal! (${err.response.data.message})`,
                });
              })
              .finally(() => setModalConfig({ show: false }));
          }}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleSubmit,
            errors,
            touched,
            validateForm,
            setTouched,
            dirty,
          }) => (
            <>
              {!isPageLoading && (
                <FormCard
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  validateForm={validateForm}
                  setTouched={setTouched}
                  dirty={dirty}
                />
              )}
              <ModalSection
                values={values}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </Formik>
        }

        {!isPageLoading && tab === 'history' && <CatatanApproval />}
      </>
    )
  })

  return (
    <ContentDetailRAB />
  )
};

export default DetailRAB;