import React from 'react';
import { Modal, Row, Col, Card, Tab, Nav } from 'react-bootstrap';
import { Input, InputCurrency, Select, TextArea } from 'components2';
import { rupiahConvert } from 'utilities2';
import { TabGambar } from './TabGambar';
import { TabFile } from './TabFile';
import {
  accDocTypes,
  accImageTypes,
} from '../__PengembalianSisaPettyCashUtils__';

export const ModalFormRincian = ({ show, type, data = {}, onHide }) => {
  const isCreate = type === 'CREATE';
  const isDetail = type === 'DETAIL';

  return (
    <Modal size="xl" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        {isDetail ? '' : isCreate ? 'Tambah' : 'Ubah'} Detail Rincian Petty Cash
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="6">
            <Input
              disabled
              label="Item"
              name="nama_item"
              placeholder="Masukkan Nama Item"
              value={data.nama_item}
            />
          </Col>

          <Col md="3">
            <Input
              disabled
              label="Qty"
              name="qty"
              placeholder="Masukkan Qty."
              type="number"
              value={data.qty}
            />
          </Col>

          <Col md="3">
            <Select
              disable
              name="id_satuan"
              label="Satuan"
              placeholder="Pilih Satuan"
              defaultValue={{
                value: data.id_satuan,
                label: data.nama_satuan,
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <InputCurrency
              disabled
              label="Harga Satuan"
              name="harga_satuan"
              placeholder="Rp0"
              className="text-right"
              value={rupiahConvert().getWithComa(data.harga_satuan)}
            />
          </Col>

          <Col md="6">
            <InputCurrency
              disabled
              label="Jumlah"
              name="jumlah"
              placeholder="Rp0"
              className="text-right"
              value={
                Math.round(data.harga_satuan ?? 0) * Math.round(data.qty ?? 0)
              }
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <TextArea
              disabled={isDetail}
              label="Keterangan"
              name="keterangan"
              placeholder="Masukkan Keterangan"
              value={data.keterangan}
            />
          </Col>
        </Row>

        <Card className="border mt-4">
          <Tab.Container defaultActiveKey="gambar">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="gambar">Gambar</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="documents">File</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>

            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="gambar">
                  <TabGambar
                    dataGambar={
                      data?.files?.filter((e) =>
                        accImageTypes?.includes(e?.nama?.split('.')?.pop())
                      ) ?? []
                    }
                    type={type}
                  />
                </Tab.Pane>

                <Tab.Pane eventKey="documents">
                  <TabFile
                    dataFile={
                      data?.files?.filter((e) =>
                        accDocTypes?.includes(e?.nama?.split('.')?.pop())
                      ) ?? []
                    }
                    type={type}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Tab.Container>
        </Card>

        <small className="font-weight-bold ">
          Format yang didukung untuk gambar adalah .jpg, .jpeg, .png dan untuk
          file adalah .pdf
        </small>
      </Modal.Body>
    </Modal>
  );
};
