import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Nav } from "react-bootstrap";
import { ApprovalPurchaseOrder, HistoryApprovalPurchaseOrder } from "./Tabs";

const MainApprovalPurchaseOrder = ({ setNavbarTitle }) => {
  useEffect(() => {
    setNavbarTitle("Approval Purchase Order");
    return () => {};
  }, []);
  const [tabs, setTabs] = useState("approval"); //State untuk menampung tabs yang aktif

  // Menangani perubahan pada tabs
  const onTabsChangeHandler = (e, newPage) => {
    e.preventDefault();
    setTabs(newPage);
  };

  const TabsNav = ({ tab, label }) => (
    <Nav.Item>
      <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
        {label}
      </Nav.Link>
    </Nav.Item>
  );

  return (
    <Card>
      <Card.Header>
        <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
          <TabsNav tab="approval" label="Approval" />
          <TabsNav tab="history" label="History" />
        </Nav>
      </Card.Header>
      <Card.Body>
        {tabs === "approval" ? (
          <ApprovalPurchaseOrder />
        ) : (
          <HistoryApprovalPurchaseOrder />
        )}
      </Card.Body>
    </Card>
  );
};

export default MainApprovalPurchaseOrder;
