import React, { useState, useEffect } from "react";
import { Card, Row, Col, Modal } from "react-bootstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Axios from 'axios';
import {
  Alert,
  BackButton,
  DataStatus,
  TextEditor,
  ActionButton,
  TextArea
} from "components";
import { Form, InfoSection, TableBarangJadi } from "./Section";
import { SalesOrderSPKApi } from "api";
import { DateConvert } from "utilities";

const DetailApprovalSOSPK = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const { id_sales_order_spk } = useParams();
  const { no_sales_order_spk, tab } = location.state || {}
  const [approveStatus, setApproveStatus] = useState("V");
  const [dataApproval, setDataApproval] = useState([]);
  const [dataSOSPK, setDataSOSPK] = useState({});
  const [dataBarangJadi, setDataBarangJadi] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true)

    Axios.all([
      SalesOrderSPKApi.getSingleSOSPK({ no_sales_order_spk })])
      .then(Axios.spread((resSingle) => {
        const behavior = String(resSingle?.data?.data?.behavior).toUpperCase();
        const data = resSingle?.data?.data?.detail ?? {}
        const barangJadi = data?.detail?.map(item => Object({
          ...item,
          kode_item: item.kode_barang,
          nama_item: item.nama_barang,
          satuan: item.kode_satuan ?? item.nama_satuan,
          qty: parseFloat(item.qty_sales_order_spk),
          harga_satuan: item.unit_cost,
        }))

        setDataSOSPK(data)
        setDataBarangJadi(barangJadi)
        setApproveStatus(behavior ?? 'V')
        setDataApproval(resSingle?.data?.data?.approval ?? [])
      }))
      .catch((err) => {
        console.log(err)
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  }

  const formInitialValues = {
    id_sales_order_spk: id_sales_order_spk,
    tgl_sales_order_spk: dataSOSPK?.tgl_sales_order_spk ?? "",
    no_sales_order_spk: dataSOSPK?.no_sales_order_spk ?? "",
    batas_waktu: dataSOSPK?.batas_waktu ?? "",
    id_sales: dataSOSPK?.id_sales ?? "",
    nama_sales: dataSOSPK?.nama_sales ?? "",
    diskon: parseFloat(dataSOSPK?.diskon ?? 0),
    ppn: parseFloat(dataSOSPK?.ppn ?? 0),
  };
  const formValidationSchema = Yup.object().shape({
    tgl_sales_order_spk: Yup.string().required("Pilih tanggal Sales Order SPK"),
    no_sales_order_spk: Yup.string().required("Pilih tanggal untuk menentukan nomor Sales Order SPK"),
    batas_waktu: Yup.string().required("Pilih tanggal Batas Waktu"),
    id_sales: Yup.string().required("Pilih Sales"),
  });
  const formSubmitHandler = (values) => {
    const finalValues = {
      ...values,
      barang_jadi: dataBarangJadi
    }

    console.log('finalValues', finalValues)

    SalesOrderSPKApi.save(finalValues)
      .then(() =>
        history.push("/transaksi/penawaran", {
          alert: {
            show: true,
            variant: "primary",
            text: "Data berhasil ditambah!",
          },
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          type: "danger",
          text: "Data gagal ditambah!",
        });
      });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: formSubmitHandler,
  });

  useEffect(() => {
    setNavbarTitle("Sales Order SPK");
    getInitialData();
  }, [])

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>
                {modalConfig.title === "APP"
                  ? approveStatus === "A"
                    ? "Approve"
                    : "Verify"
                  : modalConfig.title}{" "}
                dengan catatan :
              </b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "APP"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type === "REJ"
                  ? "Reject"
                  : "Revise"
            }
            variant={
              modalConfig.type === "APP"
                ? "success"
                : modalConfig.type === "REV"
                  ? "warning"
                  : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({ values, handleChange, dirty, validateForm, errors }) => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="col-md-4 mb-4">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Sales Order SPK</b>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-content-between flex-wrap">
            {dataApproval.map((val, index) => (
              <InfoItem
                key={index}
                title1={
                  val.status_approval == "VER" ? `PEMERIKSA ${val.approval_level}` : "PENGESAH"
                }
                value1={val.nama_karyawan ?? "-"}
                title2={"Catatan"}
                value2={val.catatan ?? "-"}
              />
            ))}
          </div>
          <hr />
          <div>
            <TextArea
              label="Catatan"
              name="catatan"
              placeholder="Masukan catatan"
              value={values.catatan}
              onChange={handleChange}
              error={errors.catatan && true}
              errorText={errors.catatan}
            />
            <div className="d-flex justify-content-end mt-3">
              <ActionButton
                text="Reject"
                variant="danger"
                className="m-1"
                onClick={() => {
                  if (dirty) {
                    setModalConfig({
                      show: true,
                      type: "REJ",
                      title: <span className="text-danger">Reject Sales Order SPK</span>,
                    });
                  }
                  validateForm();
                }}
              />
              {approveStatus !== 'A' && <ActionButton
                text="Revise"
                variant="warning"
                className="m-1 text-white"
                onClick={() => {
                  if (dirty) {
                    setModalConfig({
                      show: true,
                      type: "REV",
                      title: <span className="text-warning">Revise Sales Order SPK</span>,
                    });
                  }
                  validateForm();
                }}
              />}
              <ActionButton
                text={approveStatus === "A" ? "Approve" : "Verify"}
                variant="success"
                className="m-1"
                onClick={() => {
                  if (dirty) {
                    setModalConfig({
                      show: true,
                      type: "APP",
                      title: <span className="text-success">Approve Sales Order SPK</span>,
                    });
                  }
                  validateForm();
                }}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const CatatanApproval = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Sales Order SPK</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataSOSPK?.stakeholder?.map((val, index) => index !== 0 && val.status_approval !== "PEN" && (
              <Col sm>
                <InfoItem
                  title1={
                    val.status_approval === "VER" || val.status_approval === "REV"
                      ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                      : val.status_approval === "APP"
                        ? "Pengesah"
                        : "Di Tolak Oleh"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2="Catatan"
                  value2={val.catatan ?? "-"}
                />
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Sales Order SPK</b>
          <div className='d-flex align-items-center justify-content-top'>
            <BackButton onClick={() => history.goBack()} />
          </div>
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setAlertConfig({ ...alertConfig, show: false })} />
          {
            isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." />
              : isFetchingFailed ? <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
                : <>
                  <InfoSection data={dataSOSPK} />
                  <hr />
                  <Form
                    type='DETAIL'
                    formik={formik} />
                  <TableBarangJadi
                    type='DETAIL'
                    dataBarangJadi={dataBarangJadi}
                    setDataBarangJadi={setDataBarangJadi}
                    diskon={formik.values.diskon}
                    ppn={formik.values.ppn}
                    setFieldValue={formik.setFieldValue} />
                </>
          }
        </Card.Body>
      </Card>

      {!isPageLoading && tab !== 'history' && <Formik
        initialValues={{ catatan: "" }}
        validationSchema={Yup.object().shape({
          catatan: Yup.string().required("Masukan Catatan"),
        })}
        onSubmit={(values) => {
          const finalValues = {
            ...values,
            no_transaksi: dataSOSPK.no_sales_order_spk,
            status_approval: modalConfig.type,
            tgl_approval: DateConvert(new Date()).default,
            approval_baseline: dataSOSPK.baseline,
          };

          SalesOrderSPKApi.approve(finalValues)
            .then((res) => {
              history.push("/human-resource/approval/sales-order-spk", {
                alert: {
                  show: true,
                  variant: "primary",
                  text: `${modalConfig.type == "APP"
                    ? "Approve"
                    : modalConfig.type == "REV"
                      ? "Revise"
                      : "Reject"
                    } data berhasil!`,
                },
              });
            })
            .catch((err) => {
              setAlertConfig({
                variant: "danger",
                text: `Ubah data gagal! (${err.response.data.message})`,
              });
            })
            .finally(() => setModalConfig({ show: false }));
        }}
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          validateForm,
          dirty,
          errors,
          touched,
        }) => (
          <>
            <FormCard
              values={values}
              handleChange={handleChange}
              dirty={dirty}
              validateForm={validateForm}
              errors={errors}
              touched={touched}
            />
            <ModalSection
              values={values}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </>
        )}
      </Formik>}

      {!isPageLoading && tab === 'history' && <CatatanApproval />}
    </>
  )
}

export default DetailApprovalSOSPK;
