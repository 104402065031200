import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { Row, Col, Card, Nav, Tab } from "react-bootstrap";
import { Formik, useFormikContext } from "formik";
import {
  Input,
  InfoItemHorizontal,
  Select,
  TextArea,
  ButtonBack,
  ButtonCreate,
} from "components2";

import { DataStatus, DatePicker, InfoItemVertical } from "components";
import { TablePermintaanAset } from "./TablePermintaanAset";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ModalForm } from "./ModalForm";
import * as Yup from "yup";
import { DateConvert, RupiahConvert } from "utilities";
import TabGambar from "./TabGambar";
import TabFile from "./TabFile";

const InfoMaintenanceRequest = () => {
  const { values } = useFormikContext();
  return (
    <>
      <Row>
        <Col>
          <InfoItemHorizontal
            label="Tgl. Permintaan Aset"
            text={
              values?.tgl_permintaan_aset
                ? DateConvert(new Date(values?.tgl_permintaan_aset)).detail
                : "-"
            }
          />

          <InfoItemHorizontal
            label="No. Permintaan Aset"
            text={values?.no_permintaan_aset ?? "-"}
          />

          <InfoItemHorizontal
            label="Catatan Permintaan"
            text={values?.catatan_permintaan ?? "-"}
          />
          <InfoItemHorizontal
            label="Entitas Aset"
            text={values?.nama_entitas_aset ?? "-"}
          />
          <InfoItemHorizontal
            label="Grup Aset"
            text={values?.nama_grup_aset ?? "-"}
          />
          <InfoItemHorizontal
            label="Kategori Aset"
            text={values?.nama_kategori_aset ?? "-"}
          />
          <InfoItemHorizontal
            label="Jenis Aset"
            text={values?.nama_jenis_aset ?? "-"}
          />
          <InfoItemHorizontal
            label="Nama Aset"
            text={values?.nama_aset ?? "-"}
          />
        </Col>
        <Col>
          <InfoItemHorizontal
            label="Keperluan"
            text={values?.keperluan ?? "-"}
          />
          <InfoItemHorizontal
            label="Metode Pengadaan"
            text={values?.metode_pengadaan ?? "-"}
          />
          <InfoItemHorizontal
            label="Unit Organisasi"
            text={values?.nama_unit_organisasi ?? "-"}
          />
          <InfoItemHorizontal
            label="Keterangan"
            text={values?.keterangan ?? "-"}
          />
        </Col>
      </Row>
    </>
  );
};

const CatatanApprovalCard = ({ dataStakeholder }) => {
  const InfoItem = ({ title1, value1, title2, value2 }) => (
    <div className="mb-2">
      <small>{title1}</small>
      <br />
      <b>{value1}</b>
      <div className="mt-1" />
      <small>{title2}</small>
      <br />
      <b>{value2}</b>
    </div>
  );

  return (
    <Card className="mt-4 mb-5">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Catatan Approval Permintaan Aset</b>
      </Card.Header>
      <Card.Body>
        {dataStakeholder ? (
          <Row>
            {dataStakeholder.map((val, index) => (
              <Col sm>
                <InfoItem
                  title1={
                    val.status_approval === "VER" ||
                    val.status_approval === "REV"
                      ? `Pemeriksa ${
                          val.approval_level !== "0" ? val.approval_level : ""
                        }`
                      : val.status_approval === "APP"
                      ? "Pengesah"
                      : val.status_approval === "PEN"
                      ? "Pembuat"
                      : "Di Tolak Oleh"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2="Catatan"
                  value2={val.catatan ?? "-"}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <div className="d-flex justify-content-center">
            <DataStatus text="Tidak ada catatan" />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export const FormPermintaanAsetContent = ({ action }) => {
  const { values, handleSubmit, handleChange, setValues, errors, touched } =
    useFormikContext();
  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
    type: "add",
    ind: undefined,
  });

  const modalInitialValues = {
    idEntitasAset: modalConfig?.data?.idEntitasAset ?? undefined,
    namaEntitasAset: modalConfig?.data?.namaEntitasAset ?? "",
    idGrupAset: modalConfig?.data?.idGrupAset ?? undefined,
    namaGrupAset: modalConfig?.data?.namaGrupAset ?? "",
    idKategoriAset: modalConfig?.data?.idKategoriAset ?? undefined,
    namaKategoriAset: modalConfig?.data?.namaKategoriAset ?? "",
    idJenisAset: modalConfig?.data?.idJenisAset ?? undefined,
    namaJenisAset: modalConfig?.data?.namaJenisAset ?? "",
    idUnitProduksi: modalConfig?.data?.idUnitProduksi ?? undefined,
    namaUnitProduksi: modalConfig?.data?.namaUnitProduksi ?? "",
    keperluan: modalConfig?.data?.keperluan ?? undefined,
    keperluanLabel: modalConfig?.data?.keperluanLabel ?? "",
    metodePengadaan: modalConfig?.data?.metodePengadaan ?? undefined,
    metodePengadaanLabel: modalConfig?.data?.metodePengadaanLabel ?? "",
    namaAset: modalConfig?.data?.namaAset ?? "",
    keterangan: modalConfig?.data?.keterangan ?? "",
    file: modalConfig?.data?.file ?? [],
  };

  const modalValidationSchema = Yup.object().shape({
    idEntitasAset: Yup.string().required("Pilih entitas aset"),
    idGrupAset: Yup.string().required("Pilih grup aset"),
    idKategoriAset: Yup.string().required("Pilih kategori aset"),
    idJenisAset: Yup.string().required("Pilih jenis aset"),
    idUnitProduksi: Yup.string().required("Pilih unit produksi"),
    keperluan: Yup.string().required("Pilih keperluan"),
    metodePengadaan: Yup.string().required("Pilih metode pengadaan"),
    namaAset: Yup.string().required("Masukan item aset"),
  });

  const modalSubmitHandler = (value, { setSubmitting, resetForm }) => {
    modalConfig.type === "add" &&
      setValues({ ...values, detail: [...values?.detail, value] });
    modalConfig.type === "update" &&
      setValues({
        ...values,
        detail: values?.detail?.map((res, ind) =>
          ind === modalConfig?.ind ? value : res
        ),
      });

    setSubmitting(false);
    resetForm();
    setModalConfig({ ...modalConfig, show: false });
  };

  return (
    <Fragment>
      <Card>
        <Card.Body>
          <InfoMaintenanceRequest />
          <Card>
            <Tab.Container defaultActiveKey="tabGambar">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="tabGambar">Gambar</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="tabFile">File</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Tab.Content>
                <Tab.Pane eventKey="tabGambar">
                  <div className="m-2">
                    <TabGambar readOnly />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="tabFile">
                  <div className="m-2">
                    <TabFile readOnly />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card>
          <hr />
          <Row>
            <Col>
              <InfoItemVertical
                label="Tgl. Seleksi Vendor Aset"
                text={
                  values?.tgl_seleksi_vendor_aset
                    ? DateConvert(new Date(values?.tgl_seleksi_vendor_aset))
                        .detail
                    : "-"
                }
              />
              <InfoItemVertical
                label="Vendor"
                text={values?.nama_vendor ?? "-"}
              />
            </Col>
            <Col>
              <InfoItemVertical
                label="No. Seleksi Vendor Aset"
                text={values?.no_seleksi_vendor_aset ?? "-"}
              />
              <InfoItemVertical
                label="Harga Kesepakatan"
                text={
                  RupiahConvert(
                    parseFloat(values?.harga_kesepakatan ?? 0).toString()
                  ).getWithComa
                }
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {action !== "detail" && (
        <div className="d-flex justify-content-end mt-3">
          <ButtonCreate
            text="Simpan"
            variant={action === "update" ? "success" : "primary"}
            onClick={handleSubmit}
          />
        </div>
      )}

      {modalConfig.show && (
        <Formik
          initialValues={modalInitialValues}
          validationSchema={modalValidationSchema}
          onSubmit={modalSubmitHandler}
        >
          <ModalForm
            setModalConfig={setModalConfig}
            modalConfig={modalConfig}
          />
        </Formik>
      )}
    </Fragment>
  );
};
