import React, { useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import {
  Input,
  DatePicker,
} from "components";
import { SuratPerjanjianKerjaApi } from 'api';

const FormSPK = ({ formik, type }) => {
  const today = new Date().toISOString().slice(0, 10);
  const { values, errors, touched, setFieldValue } = formik;

  return (
    <Row>
      <Col sm={6}>
        <DatePicker
          disabled={Boolean(type === 'DETAIL')}
          label="Tanggal SPK"
          placeholderText="Pilih tanggal SPK"
          selected={values.tgl_spk ? new Date(values.tgl_spk) : ""}
          onChange={(val) => {
            const value = val.toISOString().slice(0, 10);
            setFieldValue("tgl_spk", value);
          }}
          error={errors.tgl_spk && touched.tgl_spk && true}
          errorText={errors.tgl_spk && touched.tgl_spk && errors.tgl_spk}
        />
      </Col>
      <Col sm={6}>
        <Input
          label="Nomor SPK"
          placeholder="Pilih tanggal untuk menentukan nomor SPK"
          value={values.no_spk}
          readOnly={true}
          error={errors.no_spk && touched.no_spk && true}
          errorText={errors.no_spk && touched.no_spk && errors.no_spk}
        />
      </Col>
    </Row>
  );
};

export default FormSPK;