import { dateConvert } from "utilities2";

export const formSubmitValueMapper = (data = {}) => ({
  id_penerimaan_petty_cash: data?.id_penerimaan_petty_cash ?? undefined,
  tgl_penerimaan_petty_cash: dateConvert().getDashYMD(
    new Date(data.tgl_penerimaan_petty_cash)
  ),
  nominal_penerimaan_petty_cash: data.nominal_penerimaan_petty_cash,
  keterangan: data.keterangan,
  kode_modul: data.kode_modul,
  foto_penerimaan_petty_cash: data.foto_penerimaan_petty_cash,
  no_bukti_mutasi: data.no_bukti_mutasi,
  status_approval: data?.status_approval,
});
