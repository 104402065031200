import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { debounce } from "lodash";
import {
  CRUDLayout,
  Table,
  ThFixed,
  TdFixed,
  Th,
  Td,
  Pagination,
} from "components";
import {
  InputSearch,
  DataStatus,
  ButtonCreate,
  ButtonDetail,
} from "components2";
import { tableNumber, dateConvert, rupiahConvert } from "utilities2";
import PengembalianSisaPettyCashApi from "./__PengembalianSisaPettyCashApi__";

const PengembalianSisaPettyCashList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const [pagination, setPagination] = useState({
    q: "",
    page: 1,
    per_page: 10,
  });

  const getPengembalianPettyCash = useQuery(
    ["pengembalian_sisa_petty_cash", "list", pagination],
    () => PengembalianSisaPettyCashApi.getList(pagination)
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setPagination({
      ...pagination,
      q: searchKey,
    });
  };

  const onCreateButtonClickHandler = () =>
    history.push(
      "/human-resource/transaksi/pengembalian-sisa-petty-cash/realisasi-petty-cash"
    );

  const onPaginationChange = ({ selected }) =>
    setPagination({ ...pagination, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setPagination({ ...pagination, page: 1, per_page: e.target.value });

  useEffect(() => setNavbarTitle("Pengembalian Sisa Petty Cash"), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch onChange={debounce(searchHandler, 1500)} />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ButtonCreate size="md" icon onClick={onCreateButtonClickHandler} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getPengembalianPettyCash.isLoading ||
      getPengembalianPettyCash.isError ? (
        <DataStatus
          loading={getPengembalianPettyCash.isLoading}
          text={
            getPengembalianPettyCash.isLoading
              ? "Memuat . . ."
              : "Data gagal dimuat"
          }
        />
      ) : (
        <>
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Pengembalian Sisa Petty Cash
            </small>
          </div>

          <Table>
            <thead>
              <tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Aksi</ThFixed>
                <Th style={{ minWidth: 100 }} className="p-1">
                  Informasi Pengembalian Sisa Petty Cash
                </Th>
                <Th style={{ minWidth: 100 }} className="p-1">
                  Informasi Realisasi Petty Cash
                </Th>
                <Th style={{ minWidth: 300 }} className="p-1">
                  Unit Produksi
                </Th>
                <Th style={{ width: 150 }} className="p-1">
                  Sisa Petty Cash
                </Th>
              </tr>
            </thead>
            <tbody>
              {getPengembalianPettyCash?.data?.data?.length > 0 ? (
                getPengembalianPettyCash?.data?.data?.map((e, index) => (
                  <tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: pagination.page,
                        dataLength: pagination.per_page,
                        index,
                      })}
                    </TdFixed>
                    <Td>
                      <ButtonDetail
                        icon
                        noText
                        variant="primary"
                        onClick={() =>
                          history.push(
                            `/human-resource/transaksi/pengembalian-sisa-petty-cash/detail/${e.id_pengembalian_sisa_petty_cash}`
                          )
                        }
                      />
                    </Td>

                    <Td>
                      <div>
                        {e.tgl_pengembalian_sisa_petty_cash
                          ? dateConvert().getSlashDMY(
                              new Date(e.tgl_pengembalian_sisa_petty_cash)
                            )
                          : "-"}
                      </div>
                      <div>{e.no_pengembalian_sisa_petty_cash ?? "-"}</div>
                    </Td>

                    <Td>
                      <div>
                        {dateConvert().getSlashDMY(
                          new Date(e.tgl_realisasi_petty_cash)
                        )}
                      </div>
                      <div>{e.no_realisasi_petty_cash}</div>
                    </Td>

                    <Td>{e.nama_unit_produksi}</Td>

                    <Td textRight>
                      {rupiahConvert().getWithComa(e.nominal_sisa_petty_cash)}
                    </Td>
                  </tr>
                ))
              ) : (
                <tr>
                  <Td colSpan={6}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </tr>
              )}
            </tbody>
          </Table>

          {getPengembalianPettyCash?.data?.data?.length > 0 && (
            <Pagination
              dataLength={pagination?.per_page}
              dataNumber={
                pagination?.page * pagination?.per_page -
                pagination?.per_page +
                1
              }
              dataPage={
                pagination?.dataCount < pagination?.per_page
                  ? pagination?.dataCount
                  : pagination?.page * pagination?.per_page
              }
              dataCount={getPengembalianPettyCash?.data?.data_count}
              currentPage={pagination?.page}
              totalPage={getPengembalianPettyCash?.data?.total_page}
              onPaginationChange={onPaginationChange}
              onDataLengthChange={onPaginationDataLengthChange}
            />
          )}
        </>
      )}
    </CRUDLayout>
  );
};

export default PengembalianSisaPettyCashList;
