import Services from "../../services";

class AuthApi {
  login(value) {
    return Services.post("/hrdu/auth/login", value);
  }

  notif(params) {
    return Services.get("/hrdu/notification/counter_notif", { params });
  }


  // LOCAL BAYU
  // login(value) {
  //   return Services.post("/auth/login", value);
  // }
}

export default new AuthApi();
