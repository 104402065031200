import { useFormikContext } from "formik";
import { Card, Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import {
  ButtonCreate,
  Input,
  InputCurrency,
  Select,
  TextArea,
} from "components2";
import { rupiahConvert } from "utilities2";
import { accDocTypes, accImageTypes } from "../utils";
import TabFile from "./TabFile";
import TabGambar from "./TabGambar";

export const ModalFormRincian = ({ show, type, onHide }) => {
  const isCreate = type === "CREATE";
  const isDetail = type === "DETAIL";
  const {
    values: valuesRincian,
    errors: errorsRincian,
    touched: touchedRincian,
    setFieldValue: setFieldValueRincian,
    handleChange: handleChangeRincian,
    handleSubmit: handleSubmitRincian,
  } = useFormikContext();

  return (
    <Modal size="xl" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        {isDetail ? "" : isCreate ? "Tambah" : "Ubah"} Detail Rincian Petty Cash
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="6">
            <Input
              disabled={isDetail}
              label="Item"
              name="nama_item"
              placeholder="Masukkan Nama Item"
              value={valuesRincian.nama_item}
              onChange={handleChangeRincian}
              error={errorsRincian.nama_item && touchedRincian.nama_item}
              errorText={errorsRincian.nama_item}
            />
          </Col>

          <Col md="3">
            <Input
              disabled={isDetail}
              label="Qty"
              name="qty"
              placeholder="Masukkan Qty."
              type="number"
              value={valuesRincian.qty}
              onChange={handleChangeRincian}
              error={errorsRincian.qty && touchedRincian.qty}
              errorText={errorsRincian.qty}
            />
          </Col>

          <Col md="3">
            <Select
              disable={isDetail}
              name="id_satuan"
              label="Satuan"
              placeholder="Pilih Satuan"
              defaultValue={{
                value: valuesRincian.id_satuan,
                label: valuesRincian.nama_satuan,
              }}
              error={errorsRincian.id_satuan && touchedRincian.id_satuan}
              errorText={
                errorsRincian.id_satuan &&
                touchedRincian.id_satuan &&
                errorsRincian.id_satuan
              }
            />
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <InputCurrency
              disabled={isDetail}
              label="Harga Satuan"
              name="harga_satuan"
              placeholder="Rp0"
              className="text-right"
              value={rupiahConvert().getWithComa(valuesRincian.harga_satuan)}
              onChange={(e) => setFieldValueRincian("harga_satuan", e)}
              error={
                errorsRincian.harga_satuan &&
                touchedRincian.harga_satuan &&
                true
              }
              errorText={errorsRincian.harga_satuan}
            />
          </Col>

          <Col md="6">
            <InputCurrency
              disabled
              label="Jumlah"
              name="jumlah"
              placeholder="Rp0"
              className="text-right"
              value={
                Math.round(valuesRincian.harga_satuan ?? 0) *
                Math.round(valuesRincian.qty ?? 0)
              }
              error={!!errorsRincian.jumlah}
              errorText={errorsRincian.jumlah}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <TextArea
              disabled={isDetail}
              label="Keterangan"
              name="keterangan"
              placeholder="Masukkan Keterangan"
              value={valuesRincian.keterangan}
              onChange={handleChangeRincian}
              error={errorsRincian.keterangan && touchedRincian.keterangan}
              errorText={errorsRincian.keterangan}
            />
          </Col>
        </Row>

        <Card className="border mt-4">
          <Tab.Container defaultActiveKey="gambar">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="gambar">Gambar</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="documents">File</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>

            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="gambar">
                  <TabGambar
                    dataGambar={
                      valuesRincian?.files?.filter((e) =>
                        accImageTypes?.includes(e.nama.split(".").pop())
                      ) ?? []
                    }
                    type={type}
                  />
                </Tab.Pane>

                <Tab.Pane eventKey="documents">
                  <TabFile
                    dataFile={
                      valuesRincian?.files?.filter((e) =>
                        accDocTypes?.includes(e.nama.split(".").pop())
                      ) ?? []
                    }
                    type={type}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Tab.Container>
        </Card>

        <small className="font-weight-bold ">
          Format yang didukung untuk gambar adalah .jpg, .jpeg, .png dan untuk
          file adalah .pdf
        </small>
      </Modal.Body>

      <Modal.Footer>
        {type !== "DETAIL" && (
          <ButtonCreate
            size="md"
            text="Simpan Data"
            onClick={() => handleSubmitRincian()}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};
