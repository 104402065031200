import React, { useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import {
  Input,
  DatePicker,
} from "components";
import { RABApi } from 'api';

const FormRAB = ({ formik, type }) => {
  const today = new Date().toISOString().slice(0, 10);
  const { values, errors, touched, setFieldValue } = formik;

  const getNomorRAB = (date) => {
    RABApi.getNomorRAB({ tanggal: date })
      .then(({ data }) => {
        formik.setFieldValue("tgl_rab", date);
        formik.setFieldValue("no_rab", data.data);
      })
      .catch(() => window.alert("Nomor RAB gagal di generate!"));
  };

  useEffect(() => {
    type === 'CREATE' && getNomorRAB(today);
  }, [])

  return (
    <Row>
      <Col sm={6}>
        <DatePicker
          disabled={Boolean(type === 'DETAIL')}
          label="Tanggal RAB"
          placeholderText="Pilih tanggal RAB"
          selected={values.tgl_rab ? new Date(values.tgl_rab) : ""}
          onChange={(val) => {
            const value = val.toISOString().slice(0, 10);
            setFieldValue("tgl_rab", value);
            type === 'CREATE' && getNomorRAB(value);
          }}
          error={errors.tgl_rab && touched.tgl_rab && true}
          errorText={errors.tgl_rab && touched.tgl_rab && errors.tgl_rab}
        />
      </Col>
      <Col sm={6}>
        <Input
          label="Nomor RAB"
          placeholder="Pilih tanggal untuk menentukan nomor RAB"
          value={values.no_rab}
          readOnly={true}
          error={errors.no_rab && touched.no_rab && true}
          errorText={errors.no_rab && touched.no_rab && errors.no_rab}
        />
      </Col>
    </Row>
  );
};

export default FormRAB;