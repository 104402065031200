// Components
import { Table, Td, TdFixed, Th, ThFixed } from "components"
import { Card } from "react-bootstrap"
import { RupiahConvert } from "utilities"

const SummarySection = ({ summary, total }) => (
	<>
		<b>Summary Biaya</b>
		<Card>
			<Card.Body>
				<Table>
					<thead>
						<tr>
							<ThFixed>No.</ThFixed>
							<Th>Grup BUASO</Th>
							<ThFixed>Total</ThFixed>
						</tr>
					</thead>
					<tbody>
						{summary.map((val, index) => (
							<tr>
								<TdFixed>{index + 1}</TdFixed>
								<Td>{val.buaso}</Td>
								<TdFixed className="text-nowrap" textRight>{val.total ? RupiahConvert(String(val.total)).detail : RupiahConvert("0").detail}</TdFixed>
							</tr>
						))}
						<tr className="font-weight-bold">
							<TdFixed colSpan={2} textRight>Total</TdFixed>
							<TdFixed className="text-nowrap" textRight>{RupiahConvert(String(total)).detail}</TdFixed>
						</tr>
					</tbody>
				</Table>
			</Card.Body>
		</Card>
	</>
)

export default SummarySection