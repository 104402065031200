import { ActionButton, TextArea } from 'components';
import { Card } from 'react-bootstrap';
import { DateConvert } from 'utilities';

const InfoItem = ({ title1, value1, title2, value2, createdAt }) => {
  const inputDate = new Date(createdAt);
  const time = inputDate.toLocaleTimeString('en-US', { hour12: false });

  return (
    <div className="col-md-4 mb-4">
      <div className="mt-1">
        <small>Tanggal</small>
        <br />
        <b>{DateConvert(new Date(createdAt)).detail}</b>
        <div>{time}</div>
      </div>

      <div className="mt-1">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
      </div>

      <div className="mt-1">
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    </div>
  );
};

const Approval = ({
  title,
  withLine = true,
  data,
  tab,
  approveStatus,
  setModalConfig,
  values,
  handleChange,
  dirty,
  validateForm,
  errors,
}) => {
  return (
    <Card className="mt-4 mb-5">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Approval {title}</b>
      </Card.Header>
      <Card.Body>
        <div className="d-flex align-content-between flex-wrap">
          {data.map((val, index) =>
            val.status_approval !== 'PEN' ? (
              <InfoItem
                key={index}
                title1={
                  val.status_approval === 'VER'
                    ? `PEMERIKSA ${val.approval_level}`
                    : 'PENGESAH'
                }
                value1={val.nama_karyawan ?? '-'}
                title2={'Catatan'}
                value2={val.catatan ?? '-'}
                tanggal={val.tgl_approval}
                createdAt={val.created_at}
              />
            ) : (
              ''
            )
          )}
        </div>

        {data[data?.length - 1]?.status_approval !== 'PEN' && withLine && (
          <hr />
        )}

        {tab !== 'history' && (
          <div>
            <TextArea
              label="Catatan"
              name="catatan"
              placeholder="Masukan catatan"
              value={values.catatan}
              onChange={handleChange}
              error={errors?.catatan && true}
              errorText={errors?.catatan}
            />
            <div className="d-flex justify-content-end mt-3">
              <ActionButton
                text="Reject"
                variant="danger"
                className="m-1"
                onClick={() => {
                  if (dirty) {
                    setModalConfig({
                      show: true,
                      type: 'REJ',
                      title: (
                        <span className="text-danger">Reject {title}</span>
                      ),
                    });
                  }
                  validateForm();
                }}
              />
              {approveStatus !== 'A' && (
                <ActionButton
                  text="Revise"
                  variant="warning"
                  className="m-1 text-white"
                  onClick={() => {
                    if (dirty) {
                      setModalConfig({
                        show: true,
                        type: 'REV',
                        title: (
                          <span className="text-warning">Revise {title}</span>
                        ),
                      });
                    }
                    validateForm();
                  }}
                />
              )}
              <ActionButton
                text={approveStatus === 'A' ? 'Approve' : 'Verify'}
                variant="success"
                className="m-1"
                onClick={() => {
                  if (dirty) {
                    setModalConfig({
                      show: true,
                      type: approveStatus === 'A' ? 'APP' : 'VER',
                      title: (
                        <span className="text-success">
                          {approveStatus === 'A' ? 'Approve' : 'Verify'} {title}
                        </span>
                      ),
                    });
                  }
                  validateForm();
                }}
              />
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default Approval;
