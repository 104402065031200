import React, { useEffect, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import {
  CRUDLayout,
  Pagination,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
} from "components";
import { ButtonBack, ButtonCreate, DataStatus, InputSearch } from "components2";
import { dateConvert, rupiahConvert, tableNumber } from "utilities2";
import RealisasiPettyCashApi from "./__PengembalianSisaPettyCashApi__";

const RealisasiPengembalianSisaPettyCashList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const [pagination, setPagination] = useState({
    q: "",
    page: 1,
    per_page: 10,
  });

  const getRealisasi = useQuery(
    ["pengembalian_sisa_petty_cash", "list_realisasi", pagination],
    () => RealisasiPettyCashApi.getListRealisasi(pagination)
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setPagination({
      ...pagination,
      q: searchKey,
    });
  };

  const onCreateButtonClickHandler = (id) =>
    history.push(
      `/human-resource/transaksi/pengembalian-sisa-petty-cash/tambah/${id}`
    );

  const onPaginationChange = ({ selected }) =>
    setPagination({ ...pagination, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setPagination({ ...pagination, page: 1, per_page: e.target.value });

  useEffect(() => setNavbarTitle("Pengembalian Sisa Petty Cash"), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch onChange={debounce(searchHandler, 1500)} />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ButtonBack size="md" onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getRealisasi.isLoading || getRealisasi.isError ? (
        <DataStatus
          loading={getRealisasi.isLoading}
          text={getRealisasi.isLoading ? "Memuat . . ." : "Data gagal dimuat"}
        />
      ) : (
        <>
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Realisasi Petty Cash Yang Siap Dikembalikan Sisanya
            </small>
          </div>

          <Table>
            <thead>
              <tr>
                <ThFixed rowSpan={2}>No.</ThFixed>
                <Th rowSpan={2} style={{ minWidth: 100 }} className="p-1">
                  Informasi Realisasi Petty Cash
                </Th>
                <Th rowSpan={2} style={{ width: 90 }} className="p-1">
                  Informasi Penerimaan Petty Cash
                </Th>
                <Th rowSpan={2} style={{ minWidth: 150 }} className="p-1">
                  Unit Produksi
                </Th>
                <Th colSpan={2} style={{ minWidth: 150 }} className="p-1">
                  Masa Berlaku Petty Cash
                </Th>
                <Th rowSpan={2} style={{ width: 100 }} className="p-1">
                  Sisa Masa Berlaku Petty Cash
                </Th>
                <Th rowSpan={2} style={{ width: 100 }} className="p-1">
                  Jumlah Pengeluaran Petty Cash
                </Th>
                <Th rowSpan={2} style={{ width: 100 }} className="p-1">
                  Sisa Petty Cash
                </Th>
                <ThFixed rowSpan={2}>Aksi</ThFixed>
              </tr>
              <tr>
                <Th>Tgl. Awal Petty Cash</Th>
                <Th>Tgl. Akhir Petty Cash</Th>
              </tr>
            </thead>
            <tbody>
              {getRealisasi?.data?.data?.length > 0 ? (
                getRealisasi?.data?.data?.map((e, index) => (
                  <tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: pagination.page,
                        dataLength: pagination.per_page,
                        index,
                      })}
                    </TdFixed>
                    <Td>
                      <div>
                        {dateConvert().getSlashDMY(
                          new Date(e.tgl_realisasi_petty_cash)
                        )}
                      </div>
                      <div>{e.no_realisasi_petty_cash}</div>
                    </Td>

                    <Td>
                      <div>
                        {dateConvert().getSlashDMY(
                          new Date(e.tgl_penerimaan_petty_cash)
                        )}
                      </div>
                      <div>{e.no_penerimaan_petty_cash}</div>
                    </Td>

                    <Td>{e.nama_unit_produksi}</Td>

                    <Td>
                      {dateConvert().getSlashDMY(
                        new Date(e.tgl_awal_petty_cash)
                      )}
                    </Td>

                    <Td>
                      {dateConvert().getSlashDMY(
                        new Date(e.tgl_akhir_petty_cash)
                      )}
                    </Td>

                    <Td textCenter>{e.sisa_durasi} Hari</Td>
                    <Td textCenter>
                      {rupiahConvert().getWithComa(
                        e.nominal_realisasi_petty_cash
                      )}
                    </Td>
                    <Td textCenter>
                      {rupiahConvert().getWithComa(e.sisa_saldo_petty_cash)}
                    </Td>
                    <TdFixed>
                      <ButtonGroup>
                        <ButtonCreate
                          icon
                          noText
                          onClick={() =>
                            onCreateButtonClickHandler(
                              e.id_realisasi_petty_cash
                            )
                          }
                        />
                      </ButtonGroup>
                    </TdFixed>
                  </tr>
                ))
              ) : (
                <tr>
                  <Td colSpan={10}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </tr>
              )}
            </tbody>
          </Table>

          {getRealisasi?.data?.data?.length > 0 && (
            <Pagination
              dataLength={pagination?.per_page}
              dataNumber={
                pagination?.page * pagination?.per_page -
                pagination?.per_page +
                1
              }
              dataPage={
                pagination?.dataCount < pagination?.per_page
                  ? pagination?.dataCount
                  : pagination?.page * pagination?.per_page
              }
              dataCount={getRealisasi?.data?.data_count}
              currentPage={pagination?.page}
              totalPage={getRealisasi?.data?.total_page}
              onPaginationChange={onPaginationChange}
              onDataLengthChange={onPaginationDataLengthChange}
            />
          )}
        </>
      )}
    </CRUDLayout>
  );
};

export default RealisasiPengembalianSisaPettyCashList;
