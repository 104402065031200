import React, {
  useState,
  useEffect
} from 'react'
import {
  Row,
  Col,
  Card,
} from 'react-bootstrap'
import {
  useHistory,
  useParams
} from 'react-router-dom'
import htmlParser from 'html-react-parser'
import Axios from 'axios'
import {
  Alert,
  ActionButton,
  BackButton,
  DataStatus,
  SelectSearch
} from 'components'
import {
  RupiahConvert
} from 'utilities'
import {
  ApprovalRAPApi
} from 'api'
import {
  TableListHardwood,
  TableListPlywood,
  TableListTPFS,
  TableListTPLC,
  TableListTPMP,
  TableListTPBOP,
  TableListFNBP,
  TableListFNSC,
  TableListFNLC
} from './Table'
import FilesUpload from "../../../FilesUpload"

const AnalisaBarangJadiRAE = ({ processedData, dataBarangJadi, setDataBarangJadi, alertConfig, setAlertConfig, modalConfig, setModalConfig }) => {
  const history = useHistory()
  const id = processedData.id_barang_jadi
  const ref_uid = processedData.ref_uid
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isFetchingFailed, setIsFetchingFailed] = useState(false)
  const [dataAnalisaBarangJadi, setDataAnalisaBarangJadi] = useState({})
  const [dataKayu, setDataKayu] = useState({})
  const [dataKelompokTipeBarangJadi, setDataKelompokTipeBarangJadi] = useState([])
  const [dataTipeBarangJadi, setDataTipeBarangJadi] = useState([])
  const [dataFinishingBarangJadi, setDataFinishingBarangJadi] = useState([])
  const [filesUpload, setFilesUpload] = useState([])

  const [alertModalConfig, setAlertModalConfig] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    ApprovalRAPApi.getAnalisa({
      id_barang_jadi: id,
      ref_uid: ref_uid ? ref_uid : ''
    })
      .then(barang => {
        setDataAnalisaBarangJadi(barang.data.data)
        setFilesUpload(barang?.data?.data?.gambar ?? [])
      })
      .catch(() => {
        setIsFetchingFailed(true)
        setAlertModalConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!'
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  useEffect(() => {
    getInitialData()

    return () => {
      setIsPageLoading(false)
    }
  }, [])

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <b>{value}</b>
      </div>
    )

    const InfoItemUraian = ({ title, value }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <div>{value}</div>
      </div>
    )

    const InfoItemLink = ({ title, value }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <b>
          <a href={value} target="_blank">{value}</a>
        </b>
      </div>
    )

    return (
      <Row>
        <Col>
          <InfoItem
            title="Kode Barang Jadi"
            value={dataAnalisaBarangJadi.kode_item ? dataAnalisaBarangJadi.kode_item : '-'}
          />
          <InfoItem
            title="Nama Barang Jadi"
            value={dataAnalisaBarangJadi.nama_item ? dataAnalisaBarangJadi.nama_item : '-'}
          />
          <InfoItem
            title="Jenis Barang Jadi"
            value={dataAnalisaBarangJadi.nama_jenis ? dataAnalisaBarangJadi.nama_jenis : '-'}
          />
          <InfoItem
            title="Satuan Jual"
            value={dataAnalisaBarangJadi.nama_satuan ? dataAnalisaBarangJadi.nama_satuan : '-'}
          />
          {dataAnalisaBarangJadi.link_referensi
            ? <InfoItemLink
              title="Link Referensi"
              value={dataAnalisaBarangJadi.link_referensi}
            />
            : <InfoItem
              title="Link Referensi"
              value="-"
            />
          }
        </Col>
        <Col>
          <InfoItemUraian
            title="Uraian"
            value={htmlParser(dataAnalisaBarangJadi?.uraian ?? "-")}
          />
        </Col>
      </Row>
    )
  }

  const TableSection = () => {
    const [dataHardwood, setDataHardwood] = useState([])
    const [dataPlywood, setDataPlywood] = useState([])
    const [dataTipe, setDataTipe] = useState([])
    const [dataTPFS, setDataTPFS] = useState([])
    const [dataTPLC, setDataTPLC] = useState([])
    const [dataTPMP, setDataTPMP] = useState([])
    const [dataTPBOP, setDataTPBOP] = useState([])
    const [dataSelectTPFS, setDataSelectTPFS] = useState([])
    const [dataSelectTPLC, setDataSelectTPLC] = useState([])
    const [dataSelectTPMP, setDataSelectTPMP] = useState([])
    const [dataSelectTPBOP, setDataSelectTPBOP] = useState([])
    const [dataFNBP, setDataFNBP] = useState([])
    const [dataFNSC, setDataFNSC] = useState([])
    const [dataFNLC, setDataFNLC] = useState([])
    const [dataSelectFNBP, setDataSelectFNBP] = useState([])
    const [dataSelectFNSC, setDataSelectFNSC] = useState([])
    const [dataSelectFNLC, setDataSelectFNLC] = useState([])
    const [kelompokTipe, setKelompokTipe] = useState({})
    const [tipeBarang, setTipeBarang] = useState({})
    const [finishingBarang, setFinishingBarang] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState({
      tipe: false,
      finishing: false
    })


    const filterDataHardwood = () => {
      let finalData = []
      const data = dataAnalisaBarangJadi.analisa_hardwood
      const sorting = data.sort((a, b) => {
        if (a.urutan_item < b.urutan_item) return -1
        if (a.urutan_item > b.urutan_item) return 1

        return 0
      })

      sorting.map(val => {
        finalData.push({
          id_barang_jadi: id,
          urutan_item: val.urutan_item,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          nama_kayu: val.nama_kayu,
          id_kayu: val.id_kayu,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          qty_final: val.qty_final,
          t_final: val.t_final,
          w_final: val.w_final,
          l_final: val.l_final,
          unit_price: val.harga_satuan,
          konstanta: val.koefisien,
        })
      })

      setDataHardwood(finalData)
    }

    const filterDataPlywood = () => {
      let finalData = []
      const data = dataAnalisaBarangJadi.analisa_plywood
      const sorting = data.sort((a, b) => {
        if (a.urutan_item < b.urutan_item) return -1
        if (a.urutan_item > b.urutan_item) return 1

        return 0
      })

      sorting.map(val => {
        finalData.push({
          id_barang_jadi: id,
          urutan_item: val.urutan_item,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          nama_kayu: val.nama_kayu,
          id_kayu: val.id_kayu,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          unit_price: val.harga_satuan,
          konstanta: val.koefisien,
        })
      })

      setDataPlywood(finalData)
    }

    const filterDataTipeBarangJadi = () => {
      const filterData = data => {
        const newData = []
        data && data.length > 0 && data.map((val, index) => {
          newData.push({
            id_barang_jadi: val.id_barang_jadi,
            id_item_buaso: val.id_item_bahan,
            qty: val.qty,
            unit_price: val.harga_satuan,
            konstanta: val.koefisien,
            kode_item_bahan: val.kode_item_bahan,
            nama_item: val.nama_item,
            nama_satuan: val.nama_satuan,
            urutan_item: index + 1
          })
        })
        return newData
      }

      const newDataTPFS = filterData(dataAnalisaBarangJadi.analisa_fin_tp_fs)
      const newDataTPLC = filterData(dataAnalisaBarangJadi.analisa_fin_tp_lc)
      const newDataTPMP = filterData(dataAnalisaBarangJadi.analisa_fin_tp_mp)
      const newDataTPBOP = filterData(dataAnalisaBarangJadi.analisa_fin_tp_bop)

      setDataTPFS(newDataTPFS)
      setDataTPLC(newDataTPLC)
      setDataTPMP(newDataTPMP)
      setDataTPBOP(newDataTPBOP)
    }

    const filterDataFinishingBarangJadi = () => {
      const filterData = data => {
        const newData = []
        data && data.length > 0 && data.map((val, index) => {
          newData.push({
            id_barang_jadi: val.id_barang_jadi,
            id_item_bahan: val.id_item_bahan,
            qty: val.qty,
            unit_price: val.harga_satuan,
            konstanta: val.koefisien,
            kode_item_bahan: val.kode_item_bahan,
            nama_item: val.nama_item,
            nama_satuan: val.nama_satuan,
            urutan_item: index + 1
          })
        })
        return newData
      }

      const newDataFNBP = filterData(dataAnalisaBarangJadi.analisa_fin_fn_bp)
      const newDataFNSC = filterData(dataAnalisaBarangJadi.analisa_fin_fn_sc)
      const newDataFNLC = filterData(dataAnalisaBarangJadi.analisa_fin_fn_lc)

      setDataFNBP(newDataFNBP)
      setDataFNSC(newDataFNSC)
      setDataFNLC(newDataFNLC)
    }

    const getGrandTotal = () => {
      let totalHardwood = 0
      let totalPlywood = 0
      let totalTPFS = 0
      let totalTPLC = 0
      let totalTPMP = 0
      let totalTPBOP = 0
      let totalFNBP = 0
      let totalFNSC = 0
      let totalFNLC = 0

      dataHardwood.map(val => {
        const countTotalUnit = parseFloat((parseFloat(val.qty_raw).toFixed(6) * parseFloat(val.t_raw).toFixed(6) * parseFloat(val.w_raw).toFixed(6) * parseFloat(val.l_raw).toFixed(6)) / 1000000).toFixed(6)
        const totalUnit = parseFloat(countTotalUnit) < parseFloat(0.01) ? parseFloat(0.01) : parseFloat(countTotalUnit)
        const totalPrice = parseInt(parseFloat(totalUnit) * parseInt(val.unit_price) * parseFloat(val.konstanta))
        const checkPrice = val.is_header ? 0 : totalPrice
        totalHardwood = parseInt(parseInt(totalHardwood) + parseInt(checkPrice))
      })

      dataPlywood.map(val => {
        const countTotalUnit = parseFloat((parseFloat(val.qty_raw).toFixed(6) * parseFloat(val.t_raw).toFixed(6) * parseFloat(val.w_raw).toFixed(6) * parseFloat(val.l_raw).toFixed(6)) / 1000000).toFixed(6)
        const totalUnit = parseFloat(countTotalUnit) < parseFloat(0.01) ? parseFloat(0.01) : parseFloat(countTotalUnit)
        const totalPrice = parseInt(parseFloat(totalUnit) * parseInt(val.unit_price) * parseFloat(val.konstanta))
        const checkPrice = val.is_header ? 0 : totalPrice
        totalPlywood = parseInt(parseInt(totalPlywood) + parseInt(checkPrice))
      })

      dataTPFS.map(val => {
        const total = parseInt(parseFloat(val.qty).toFixed(2) * parseInt(val.unit_price) * parseFloat(val.konstanta).toFixed(2))
        totalTPFS = parseInt(totalTPFS) + parseInt(total)
      })

      dataTPLC.map(val => {
        const total = parseInt(parseFloat(val.qty).toFixed(2) * parseInt(val.unit_price) * parseFloat(val.konstanta).toFixed(2))
        totalTPLC = parseInt(totalTPLC) + parseInt(total)
      })

      dataTPMP.map(val => {
        const total = parseInt(parseFloat(val.qty).toFixed(2) * parseInt(val.unit_price) * parseFloat(val.konstanta).toFixed(2))
        totalTPMP = parseInt(totalTPMP) + parseInt(total)
      })

      dataTPBOP.map(val => {
        const total = parseInt(parseFloat(val.qty).toFixed(2) * parseInt(val.unit_price) * parseFloat(val.konstanta).toFixed(2))
        totalTPBOP = parseInt(totalTPBOP) + parseInt(total)
      })

      dataFNBP.map(val => {
        const total = parseInt(parseFloat(val.qty).toFixed(2) * parseInt(val.unit_price) * parseFloat(val.konstanta).toFixed(2))
        totalFNBP = parseInt(totalFNBP) + parseInt(total)
      })

      dataFNSC.map(val => {
        const total = parseInt(parseFloat(val.qty).toFixed(2) * parseInt(val.unit_price) * parseFloat(val.konstanta).toFixed(2))
        totalFNSC = parseInt(totalFNSC) + parseInt(total)
      })

      dataFNLC.map(val => {
        const total = parseInt(parseFloat(val.qty).toFixed(2) * parseInt(val.unit_price) * parseFloat(val.konstanta).toFixed(2))
        totalFNLC = parseInt(totalFNLC) + parseInt(total)
      })

      const grandTotal = parseInt(totalHardwood) + parseInt(totalPlywood) + parseInt(totalTPFS) + parseInt(totalTPLC) + parseInt(totalTPMP) + parseInt(totalTPBOP) + parseInt(totalFNBP) + parseInt(totalFNSC) + parseInt(totalFNLC)

      return RupiahConvert(grandTotal.toString()).detail
    }

    useEffect(() => {
      filterDataHardwood()
      filterDataPlywood()
      filterDataTipeBarangJadi()
      filterDataFinishingBarangJadi()
    }, [])

    const GrandTotalSection = () => (
      <div className="p-2 bg-white text-right border">
        <b className="text-nowrap pr-3" style={{ fontSize: '14px' }}>
          Grand Total : {getGrandTotal()}
        </b>
      </div>
    )

    return (
      <div>
        <TableListHardwood
          dataHardwood={dataHardwood}
          setDataHardwood={setDataHardwood}
        />
        <TableListPlywood
          dataPlywood={dataPlywood}
          setDataPlywood={setDataPlywood}
        />
        <hr />
        <TableListTPFS
          dataTPFS={dataTPFS}
          setDataTPFS={setDataTPFS}
        />
        <TableListTPLC
          dataTPLC={dataTPLC}
          setDataTPLC={setDataTPLC}
        />
        <TableListTPMP
          dataTPMP={dataTPMP}
          setDataTPMP={setDataTPMP}
        />
        <TableListTPBOP
          dataTPBOP={dataTPBOP}
          setDataTPBOP={setDataTPBOP}
        />
        <hr />
        <TableListFNBP
          dataFNBP={dataFNBP}
          setDataFNBP={setDataFNBP}
        />
        <TableListFNSC
          dataFNSC={dataFNSC}
          setDataFNSC={setDataFNSC}
        />
        <TableListFNLC
          dataFNLC={dataFNLC}
          setDataFNLC={setDataFNLC}
        />
        <GrandTotalSection />
      </div>
    )
  }

  return (
    <>
      {isPageLoading
        ? <DataStatus
          loading={true}
          text="Memuat data . . ."
        />
        : isFetchingFailed
          ? <DataStatus text="Data gagal dimuat!" />
          :
          (
            <>
              <InfoSection />
              <FilesUpload filesUpload={filesUpload} setFilesUpload={setFilesUpload} action="detail" />
              <TableSection />
            </>
          )
      }
    </>
  )
}

export default AnalisaBarangJadiRAE
