import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import {
  IoDownloadOutline,
  IoImageOutline,
  IoTrashOutline,
} from 'react-icons/io5';
import { useFormikContext } from 'formik';
import FileSaver from 'file-saver';
import ImageViever from 'react-simple-image-viewer';
import { ActionButton, DeleteModal } from 'components';

export const TabGambar = ({ type: TYPE, dataGambar }) => {
  const inputFileRef = useRef();
  const { values, setValues } = useFormikContext();
  const [modalDeleteConfig, setModalDeleteConfig] = useState({
    index: '',
    show: false,
    loading: false,
  });
  const [viewImageConfig, setViewImageConfig] = useState({
    show: false,
    index: '',
  });

  // Filter data gambar agar array hanya terisi link gambar
  const filterImageHandler = useMemo(() => {
    return dataGambar.map((val) => val.link);
  }, [dataGambar]);

  // Menampilkan ImageViewer
  const setIndexImageHandler = useCallback(
    (index) =>
      setViewImageConfig({
        show: true,
        index: index,
      }),
    [viewImageConfig]
  );

  // Trigger input file agar terclick
  const onClickFilesHandler = () =>
    TYPE !== 'DETAIL' && inputFileRef.current.click();

  // Menangani saat file dipilih
  const onChangeFileHandler = (e) => {
    const file = e.target.files[0];

    // Check apakah ada file yang dipilih
    if (!file) return null;

    // Ambil file type lalu split dengan '/' dan ambil index ke 1 (cth: image/png menjadi png)
    const getFileType = file.type.split('/')[1];

    // Check jika type file sudah benar
    if (['jpg', 'png', 'gif', 'jpeg'].includes(getFileType)) {
      const generateName = file.name;
      const generateLink = URL.createObjectURL(file);
      const finalValue = { data: file, nama: generateName, link: generateLink };

      setValues((prev) => ({ ...prev, files: [...prev.files, finalValue] }));
    } else {
      window.alert(
        'File tidak valid! harap masukan gambar (.jpg, .png, .gif, .jpeg)'
      );
    }
  };

  // Menangani hapus file
  const onDeleteHandler = useCallback(() => {
    setModalDeleteConfig({ ...modalDeleteConfig, loading: true });
    const filterData = values.files.filter(
      ({ link }) => link !== modalDeleteConfig.link
    );

    setTimeout(() => {
      setValues((prev) => ({ ...prev, files: filterData }));
      setModalDeleteConfig({
        index: '',
        show: false,
        loading: false,
      });
    }, 300);
  }, [modalDeleteConfig]);

  // Menampilkan komponen foto
  const Image = ({ imageText, imageUrl, onView, onDownload, onDelete }) => {
    const [isHover, setIsHover] = useState(false);

    return (
      <OverlayTrigger
        placement="bottom"
        flip={true}
        overlay={
          <Tooltip style={{ zIndex: 1 }}>
            {imageText ?? 'Foto Barang Jadi'}
          </Tooltip>
        }
      >
        <Col
          sm={12}
          md={6}
          lg={4}
          xl={3}
          className="my-3"
          style={{ cursor: 'zoom-in', objectFit: 'cover' }}
          onClick={onView}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {/* Download Button */}
          <ActionButton
            size="sm"
            variant="primary"
            style={{ position: 'absolute', zIndex: 1, right: 20 }}
            className="m-2 shadow"
            onClick={onDownload}
          >
            <IoDownloadOutline />
          </ActionButton>

          {/* Delete Button */}
          {TYPE !== 'DETAIL' && (
            <ActionButton
              size="sm"
              variant="danger"
              style={{ position: 'absolute', zIndex: 1, top: 35, right: 20 }}
              className="m-2 shadow"
              onClick={onDelete}
            >
              <IoTrashOutline />
            </ActionButton>
          )}
          <img
            alt=""
            className={`img-fluid rounded border ${
              isHover ? 'shadow' : 'shadow-sm'
            }`}
            style={{
              height: 200,
              width: '100%',
              objectFit: 'cover',
              transform: isHover ? 'scale(1.03)' : 'none',
              transition: 'all 0.1s ease-in-out',
            }}
            src={imageUrl}
          />
        </Col>
      </OverlayTrigger>
    );
  };

  return (
    <div>
      {/* Button Section */}
      <div className="mb-3 text-right">
        <input
          ref={inputFileRef}
          type="file"
          accept="image/png, image/gif, image/jpeg, image/jpg"
          style={{ display: 'none' }}
          onChange={onChangeFileHandler}
        />
        {TYPE !== 'DETAIL' && (
          <ActionButton text="Tambah Gambar" onClick={onClickFilesHandler} />
        )}
      </div>

      {/* Image Section */}
      <Container
        fluid
        className="bg-light rounded overflow-auto border"
        onClick={onClickFilesHandler}
        style={{ cursor: 'pointer', maxHeight: '60vh' }}
      >
        {dataGambar && dataGambar.length > 0 ? (
          <Row>
            {dataGambar.map((val, index) => (
              <Image
                key={index}
                imageText={val.nama}
                imageUrl={val.link}
                onView={(e) => {
                  e.stopPropagation();
                  setIndexImageHandler(index);
                }}
                onDownload={(e) => {
                  e.stopPropagation();
                  FileSaver.saveAs(val.link, val.nama);
                }}
                onDelete={(e) => {
                  e.stopPropagation();
                  setModalDeleteConfig({
                    show: true,
                    loading: false,
                    link: val.link,
                  });
                }}
              />
            ))}
          </Row>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center py-5">
            <IoImageOutline size={60} />
            <b className="mt-2">Tidak ada gambar</b>
          </div>
        )}
      </Container>

      {/* Modal */}
      <DeleteModal
        show={modalDeleteConfig.show}
        title="Gambar"
        loading={modalDeleteConfig.loading}
        onConfirm={onDeleteHandler}
        onHide={() =>
          setModalDeleteConfig({
            show: false,
            index: '',
          })
        }
      />

      {/*Image Viewer  */}
      {viewImageConfig.show && (
        <div style={{ zIndex: 1000 }}>
          <ImageViever
            closeOnClickOutside
            disableScroll
            src={filterImageHandler}
            currentIndex={viewImageConfig.index}
            onClose={() => setViewImageConfig({ show: false, index: 0 })}
            backgroundStyle={{ zIndex: 1000 }}
          />
        </div>
      )}
    </div>
  );
};
