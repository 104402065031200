import React, {useState, useEffect} from 'react'
import { Formik } from 'formik'
import { useLocation } from 'react-router-dom'
import {
    FilterModal,
    DatePicker,
    SelectSearch
} from 'components'
import { DateConvert } from 'utilities'
import Axios from "axios"
import { PurchaseRequestApi } from 'api'

const ModalFilter = ({ show, setShow, data, setData }) => {
    const location = useLocation()
    const tab = location.state
// STATE DATA
    const [dataKeperluan, setDataKeperluan] = useState([
        {label: 'Semua', value: undefined},
        {label: 'Stok', value: 'stok'},
        {label: 'Produksi', value: 'produksi'}
    ])
    const [dataStatusApproval, setDataStatusApproval] = useState([
        {
            label: 'Semua',
            value: undefined
        },
        {
            label: 'APPROVED',
            value: 'APP'
        },
        {
            label: 'VERIFIED',
            value: 'VER'
        },
        {
            label: 'REVISION',
            value: 'REV'
        },
        {
            label: 'REJECTED',
            value: 'REJ'
        },
        {
            label: 'PENDING',
            value: 'PEN'
        }
    ])
    const [dataItemBarang, setDataItemBarang] = useState([{ label: 'Semua', value: undefined }])
    const [dataJabatan, setDataJabatan] = useState([{ label: 'Semua', value: undefined }])
    console.log(dataJabatan)
    const [dataKaryawanPengaju, setDataKaryawanPengaju] = useState([{ label: 'Semua', value: undefined }])
    const [loading, setLoading] = useState(true)
    const [loadingKaryawan, setLoadingKaryawan] = useState(false)

// REQUSET DATA SERVER
    const getDataDropdown = () => {

        Axios.all([
            PurchaseRequestApi.getDropdownItem(),
            PurchaseRequestApi.getDropdownJabatan(),
        ])
        .then(Axios.spread((barang, jabatan) => {
            const mapDataItem = barang?.data?.data ? barang.data.data.map(val => ({label: val?.nama_item, value: val?.id_item_buaso})) : []
            const mapDataJabatan = jabatan?.data?.data ? jabatan.data.data.map(val => ({label: val?.nama_jabatan, value: val?.id_jabatan})) : []

            setDataItemBarang([...dataItemBarang, ...mapDataItem])
            setDataJabatan([...dataJabatan, ...mapDataJabatan])
        }))
        .finally(() => setLoading(false))
    }
    const getKaryawan = (id) => {
        setLoadingKaryawan(true)
    
        PurchaseRequestApi.getDropdownKaryawan(id)
          .then((res) => {
            const mapDataKaryawan = res?.data?.data ? res.data.data.map(val => ({label: val?.nama_karyawan, value: val?.id_karyawan})) : []
            setDataKaryawanPengaju([...dataKaryawanPengaju, ...mapDataKaryawan])
          })
          .finally(() => setLoadingKaryawan(false));
      };
// USE EFFECT ALL DROPDOWN
    useEffect(() => {
        getDataDropdown()

    }, [])
    useEffect(() => { data?.filter?.jabatan && getKaryawan(data?.filter?.jabatan)}, [data?.filter?.jabatan])
// FORM VALUES
    const formInitialValues = {
        tgl_purchase_request_mulai: data?.filter?.tgl_purchase_request_mulai,
        tgl_purchase_request_selesai: data?.filter?.tgl_purchase_request_selesai,
        tgl_pemakaian_mulai: data?.filter?.tgl_pemakaian_mulai,
        tgl_pemakaian_selesai: data?.filter?.tgl_pemakaian_selesai,
        keperluan: data?.filter?.keperluan,
        status_approval: data?.filter?.status_approval,
        item_barang: data?.filter?.item_barang,
        karyawan_pengaju: data?.filter?.karyawan_pengaju,
        jabatan: data?.filter?.jabatan,
    }
    const formSubmitHandler = (values) => {
        const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

        if (checkActive) {
          setData({ 
              filter: {
                  ...values,
                  active: true
              },
              pagination: {
                  ...data.pagination,
                  page: 1
              }
           })
        } else { 
            setData({ 
                ...data,
                filter: {
                    ...values,
                    active: false
                }
             })
        }
    
        setShow(false)
    }

// HANDLE CHANGE 
    const onTanggalPRChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_purchase_request_mulai: startDate,
            tgl_purchase_request_selesai: endDate,
        })
    }
    const onTanggalPemakaianChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_pemakaian_mulai: startDate,
            tgl_pemakaian_selesai: endDate,
        })
    }
    const onResetButtonClick = (values, setValues) => {
        setValues({
            tgl_purchase_request_mulai: undefined,
            tgl_purchase_request_selesai: undefined,
            tgl_pemakaian_mulai: undefined,
            tgl_pemakaian_selesai: undefined,
            keperluan: undefined,
            status_approval: undefined,
            item_barang: undefined,
            karyawan_pengaju: undefined,
            jabatan: undefined,
        })
    }

    return (
        <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={formSubmitHandler}>
            
            {({values, handleSubmit, setFieldValue, setValues}) => (
                <FilterModal
                    show={show}
                    setShow={setShow}
                    onResetButtonClick={() => onResetButtonClick(values, setValues)}
                    onFilterButtonClick={handleSubmit}>
                    {console.log(values)}
                    <DatePicker 
                        selectsRange
                        label="Tgl. Purchase Request"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Pilih Tanggal Purchase Request"
                        startDate={values.tgl_purchase_request_mulai ? new Date(values.tgl_purchase_request_mulai) : ''}
                        endDate={values.tgl_purchase_request_selesai ? new Date(values.tgl_purchase_request_selesai) : ''}
                        onChange={dates => onTanggalPRChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <SelectSearch 
                        key={values.jabatan}
                        label="Jabatan"
                        placeholder="Pilih Jabatan"
                        defaultValue={dataJabatan.find(val => val.value === values.jabatan)}
                        option={dataJabatan}
                        onChange={(val) => {
                            setValues({
                                ...values,
                                jabatan: val.value,
                                karyawan_pengaju: undefined
                              })
                              getKaryawan(val.value, values, setValues)
                        }}
                        loading={loading}
                    />
                    <SelectSearch 
                        key={values.karyawan_pengaju}
                        label="Karyawan Pengaju"
                        placeholder="Pilih Karyawan Pengaju"
                        defaultValue={dataKaryawanPengaju.find(val => val.value === values.karyawan_pengaju)}
                        option={dataKaryawanPengaju}
                        onChange={val => setFieldValue('karyawan_pengaju', val.value)}
                        loading={loadingKaryawan}
                        isDisabled={values?.jabatan ? false : true}
                    />
                    <SelectSearch 
                        key={values.item_barang}
                        label="Item Barang"
                        placeholder="Pilih Item Barang"
                        defaultValue={dataItemBarang.find(val => val.value === values.item_barang)}
                        option={dataItemBarang}
                        onChange={val => setFieldValue('item_barang', val.value)}
                        loading={loading}
                    />
                    <SelectSearch 
                        key={values.keperluan}
                        label="Keperluan"
                        placeholder="Pilih Keperluan"
                        defaultValue={dataKeperluan.find(val => val.value === values.keperluan)}
                        option={dataKeperluan}
                        onChange={val => setFieldValue('keperluan', val.value)}
                        loading={loading}
                    />
                    <DatePicker 
                        selectsRange
                        label="Tgl. Pemakaian"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Pilih Tanggal Pemakaian"
                        startDate={values.tgl_pemakaian_mulai ? new Date(values.tgl_pemakaian_mulai) : ''}
                        endDate={values.tgl_pemakaian_selesai ? new Date(values.tgl_pemakaian_selesai) : ''}
                        onChange={dates => onTanggalPemakaianChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    {tab === "history"
                        ?   <SelectSearch
                                key={values.status_approval}
                                label="Status Approval"
                                placeholder="Pilih Status Approval"
                                defaultValue={dataStatusApproval.find(val => val.value === values.status_approval)}
                                option={dataStatusApproval}
                                onChange={val => setFieldValue('status_approval', val.value)}
                                loading={loading}
                            />
                        : ""
                    }
                </FilterModal>
            )}
        </Formik>
    )
}
export default ModalFilter;