import {
  ButtonBack,
  ButtonCreate,
  ButtonUpdate,
} from "@bhawanadevteam/react-core";
import { InfoItemHorizontal, Input, SelectSearch, TextArea } from "components";
import { Formik, useFormikContext } from "formik";
import React from "react";
import { Card, Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import { useQuery } from "react-query";
import TabGambar from "./TabGambar";
import TabFile from "./TabFile";

const DetailModal = () => {
  const { values } = useFormikContext();
  return (
    <Row className="mb-3">
      <Col>
        <InfoItemHorizontal
          label="Entitas Aset"
          text={values?.namaEntitasAset ?? "-"}
          width={130}
        />
        <InfoItemHorizontal
          label="Grup Aset"
          text={values?.namaGrupAset ?? "-"}
          width={130}
        />
        <InfoItemHorizontal
          label="Kategori Aset"
          text={values?.namaKategoriAset ?? "-"}
          width={130}
        />
        <InfoItemHorizontal
          label="Jenis Aset"
          text={values?.namaJenisAset ?? "-"}
          width={130}
        />
        <InfoItemHorizontal
          label="Nama Aset"
          text={values?.namaAset ?? "-"}
          width={130}
        />
      </Col>
      <Col>
        <InfoItemHorizontal
          label="Keperluan"
          text={values?.keperluanLabel ?? "-"}
          width={130}
        />
        <InfoItemHorizontal
          label="Metode Pengadaan"
          text={values?.metodePengadaanLabel ?? "-"}
          width={130}
        />
        <InfoItemHorizontal
          label="Unit Produksi"
          text={values?.namaUnitProduksi ?? "-"}
          width={130}
        />
        <InfoItemHorizontal
          label="Keterangan"
          text={values?.keterangan ?? "-"}
          width={130}
        />
      </Col>
    </Row>
  );
};

export const ModalForm = ({ setModalConfig, modalConfig }) => {
  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ show: false, data: {}, type: "" })}
      scrollable
      size="lg"
    >
      <Modal.Header closeButton>Tambah Item Aset Yang Diminta</Modal.Header>
      <Modal.Body>
        <DetailModal />
        <Card>
          <Tab.Container defaultActiveKey="tabGambar">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="tabGambar">Gambar</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tabFile">File</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Tab.Content>
              <Tab.Pane eventKey="tabGambar">
                <div className="m-2">
                  <TabGambar readOnly={modalConfig?.type === "read" && true} />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="tabFile">
                <div className="m-2">
                  <TabFile readOnly={modalConfig?.type === "read" && true} />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <ButtonBack
          onClick={() =>
            setModalConfig({ ...modalConfig, data: {}, show: false })
          }
        />
        {modalConfig?.type === "add" && (
          <ButtonCreate onClick={handleSubmit} loading={isSubmitting} />
        )}
        {modalConfig?.type === "update" && (
          <ButtonUpdate
            variant="success"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};
