import { InfoItemHorizontal } from "components";
import { useFormikContext } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DateConvert } from "utilities";

export const InfoKegiatanSection = ({ data }) => {
  const { values } = useFormikContext();
  return (
    <>
      <Row>
        <Col lg="6">
          <InfoItemHorizontal
            label="Tgl. Kegiatan"
            text={
              data?.tgl_kegiatan
                ? DateConvert(new Date(data?.tgl_kegiatan)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Kegiatan"
            text={data?.no_kegiatan ?? "-"}
          />
          <InfoItemHorizontal
            label="Nama Kegiatan"
            text={data?.nama_kegiatan ?? "-"}
          />
          <InfoItemHorizontal
            label="Penanggung Jawab"
            text={data?.nama_penanggung_jawab_kegiatan ?? "-"}
          />
        </Col>
        <Col lg="6">
          <InfoItemHorizontal
            label="Periode Mulai"
            text={
              data?.periode_mulai_kegiatan
                ? DateConvert(new Date(data?.periode_mulai_kegiatan)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Periode Selesai"
            text={
              data?.periode_selesai_kegiatan
                ? DateConvert(new Date(data?.periode_selesai_kegiatan)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Lama Periode"
            text={`${data?.lama_periode_kegiatan ?? "-"} Hari`}
          />
          <InfoItemHorizontal
            label="Deskripsi"
            text={data?.deskripsi_kegiatan ?? "-"}
          />
        </Col>
      </Row>
      <hr />
      <InfoItemHorizontal
        label="Jenis Anggaran"
        text={data?.nama_jenis_anggaran ?? "-"}
      />
      <InfoItemHorizontal
        label="Kelompok Anggaran"
        text={data?.nama_kelompok_anggaran ?? "-"}
      />
      <InfoItemHorizontal
        label="Sub Kelompok Anggaran"
        text={data?.nama_sub_kelompok_anggaran ?? "-"}
      />
    </>
  );
};
