// React
import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"

// API
import { JobMixDesignApi } from "../../../api"

// Form
import * as Yup from "yup"
import { Formik } from "formik"

// Component
import {
  Alert, DataStatus, BackButton, TextArea, ActionButton
} from "../../../components"
import { Card, Modal, Tab, Nav, Form } from "react-bootstrap"
import {  DateConvert } from "../../../utilities"

// Draft JS
import { 
  Body, Divider, SummarySection, TabAnalisa, TabFile, 
  TabGambar, TabInfo, TabInfoDetail, TabKonversiDetail, Title 
} from "./base_components"

const DetailJobMixDesign = ({ setNavbarTitle }) => {
  // Variables
  const title = "Job Mix Design"

  // Hooks
  const history = useHistory()
  const { id_jobmix_design, tab } = useParams()

  // States
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [data, setData] = useState({})
  const [isFetchingFailed, setIsFetchingFailed] = useState(false)
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [approveStatus, setApproveStatus] = useState("V")
  const [dataApproval, setDataApproval] = useState([])
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    // GET SINGLE DETAIL
    JobMixDesignApi.getSingle({ 
      id_jobmix_design 
    }).then(data => {
      const { approval, detail } = data.data.data
      const behavior = data.data.data.behavior ? data.data.data.behavior : "V"

      setData(detail)
      setDataApproval(approval ?? [])
      setApproveStatus(behavior.toUpperCase())
    }).catch(() => {
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Data gagal dimuat!",
      })

      setIsFetchingFailed(true)
    }).finally(() => setIsPageLoading(false))
  }

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>
                {modalConfig.title === "approve"
                  ? approveStatus === "A"
                    ? "Approve"
                    : "Verify"
                  : modalConfig.title}{" "}
                dengan catatan :
              </b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "APP"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type === "REJ"
                ? "Reject"
                : "Revise"
            }
            variant={
              modalConfig.type === "APP"
                ? "success"
                : modalConfig.type === "REV"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    )

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    )
  }

  const FormCard = ({ values, handleChange, validateForm, errors, dirty }) => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="col-md-4 mb-4">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    )

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval {title}</b>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-content-between flex-wrap">
            {dataApproval.map((val, index) => (
              <InfoItem
                key={index}
                title1={
                  val.status_approval === "VER" ? `PEMERIKSA ${val.approval_level}` : "PENGESAH"
                }
                value1={val.nama_karyawan ?? "-"}
                title2={"Catatan"}
                value2={val.catatan ?? "-"}
              />
            ))}
          </div>
          <hr />
          {tab !== "history" && (
            <div>
              <TextArea
                label="Catatan"
                name="catatan"
                placeholder="Masukan catatan"
                value={values.catatan}
                onChange={handleChange}
                error={errors.catatan && true}
                errorText={errors.catatan}
              />
              <div className="d-flex justify-content-end mt-3">
                {approveStatus === "A" ? (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REJ",
                            title: <span className="text-danger">Reject {title}</span>,
                          })
                        }
                        validateForm()
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "APP",
                            title: <span className="text-success">Approve {title}</span>,
                          })
                        }
                        validateForm()
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REJ",
                            title: <span className="text-danger">Reject {title}</span>,
                          })
                        }
                        validateForm()
                      }}
                    />
                    <ActionButton
                      text="Revise"
                      variant="warning"
                      className="m-1 text-white"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REV",
                            title: <span className="text-warning">Revise {title}</span>,
                          })
                        }
                        validateForm()
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "APP",
                            title: <span className="text-success">Approve {title}</span>,
                          })
                        }
                        validateForm()
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    )
  }

  useEffect(() => {
    setNavbarTitle(`Approval ${title}`)
    getInitialData()

    return () => {
      setIsPageLoading(false)
    }

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="mb-2 text-right">
        <BackButton onClick={() => history.goBack()} />
      </div>

      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : isFetchingFailed ? (
        <DataStatus text="Data gagal dimuat, tidak dapat menampilkan data!" />
      ) : (
        <>
          <Formik
            enableReinitialize
            initialValues={{
              ...data,
              nama_item: data.nama_item_base,
            }}
          >
            {(formik) => {
              const totalSummary = () => {
                return formik.values.analisa.reduce((val, {harga, qty}) => {
                  const subtotal = Math.round(parseFloat(qty ?? 0) * parseFloat(harga ?? 0))
                  return val + subtotal
                }, 0)
              }

              const summary = [
                {
                  buaso: "Bahan",
                  total: totalSummary()
                },
                {
                  buaso: "Upah",
                  total: 0
                },
                {
                  buaso: "Alat & Mesin",
                  total: 0
                },
                {
                  buaso: "Subkon",
                  total: 0
                },
                {
                  buaso: "Overhead",
                  total: 0
                }
              ]
              const total = summary.reduce((prev, { total }) => {
                return prev + total
              }, 0)

              return (
                <form onSubmit={formik.handleSubmit}>
                  <Divider>
                    <b>Detail Data {title}</b>
                    <Card>
                      <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                          <Nav variant="tabs">
                            <Nav.Item>
                              <Nav.Link eventKey="tab-1">Informasi Umum</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="tab-4">Konversi Satuan</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="tab-2">Gambar</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="tab-3">File</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Card.Header>
                        <Tab.Content>
                          <Tab.Pane eventKey="tab-1">
                            <Body>
                              <TabInfoDetail />
                            </Body>
                          </Tab.Pane>
                          <Tab.Pane eventKey="tab-4">
                            <Body>
                              <TabKonversiDetail />
                            </Body>
                          </Tab.Pane>
                          <Tab.Pane eventKey="tab-2">
                            <Body>
                              <TabGambar readOnly />
                            </Body>
                          </Tab.Pane>
                          <Tab.Pane eventKey="tab-3">
                            <Body>
                              <TabFile readOnly />
                            </Body>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </Card>
                  </Divider>

                  <Divider>
                    <div className="d-flex justify-content-between align-items-end">
                      <b>Data Analisa Barang Jadi</b>
                    </div>
                    <Card>
                      <Tab.Container id="left-tabs-example" defaultActiveKey="analisa-1">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                          <Nav variant="tabs">
                            <Nav.Item>
                              <Nav.Link eventKey="analisa-1">Bahan</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Card.Header>
                        <Tab.Content>
                          <Tab.Pane eventKey="analisa-1">
                            <TabAnalisa dropdown={{}} id_buaso='1' nama_buaso='Bahan' readOnly />
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </Card>
                  </Divider>

                  <Divider>
                    <SummarySection summary={summary} total={total} />
                  </Divider>
                </form>
              )
            }}
          </Formik>

          <Formik
            initialValues={{ catatan: "" }}
            validationSchema={Yup.object().shape({
              catatan: Yup.string().required("Masukan Catatan"),
            })}
            onSubmit={(values) => {
              const finalValues = {
                ...values,
                no_transaksi: data.id_jobmix_design,
                status_approval: modalConfig.type,
                tgl_approval: DateConvert(new Date()).default,
                approval_baseline: data.baseline,
              }

              JobMixDesignApi.approve(finalValues)
                .then(() => {
                  history.push("/human-resource/approval/job-mix-design", {
                    alert: {
                      show: true,
                      variant: "primary",
                      text: `${
                        modalConfig.type === "APP"
                          ? "Approve"
                          : modalConfig.type === "REV"
                          ? "Revise"
                          : "Reject"
                      } data berhasil!`,
                    },
                  })
                })
                .catch((err) => {
                  setAlertConfig({
                    show: true,
                    variant: "danger",
                    text: `Simpan approval gagal! (${err?.response?.data?.message ?? ""})`,
                  })
                })
                .finally(() => setModalConfig({ show: false }))
            }}
          >
            {({
              values,
              isSubmitting,
              handleChange,
              handleSubmit,
              errors,
              touched,
              validateForm,
              dirty,
            }) => (
              <>
                <FormCard
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  validateForm={validateForm}
                  dirty={dirty}
                />
                <ModalSection
                  values={values}
                  isSubmitting={isSubmitting}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                />
              </>
            )}
          </Formik>
        </>
      )}
    </>
  )
}

export default DetailJobMixDesign
