import React, { useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Row, Card } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { CRUDLayout, Approval } from 'components';
import { DataStatus, ButtonBack, ButtonUpdate } from 'components2';
import { useModalConfirm } from 'hooks2';
import {
  formInitialValues,
  formValidationSchema,
  formSubmitValueMapper,
} from './__PenerimaPettyCashUtils__';
import PenerimaanPettyCashApi from './__PenerimaanPettyCashApi__';
import {
  PenerimaanPettyCashContent,
  PenerimaanPettyCashConfirmationContent,
} from './__PenerimaanPettyCashComps__';
import { uploadImageServices } from './__PenerimaPettyCashUtils__/uploadImageServices';

export const PenerimaanPettyCashUbah = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id: id_penerimaan_petty_cash } = useParams();
  const modalConfirm = useModalConfirm();
  const { REACT_APP_SHOW_FILE_BASE_URL } = process.env;

  const { data, isLoading, isError } = useQuery(
    ['penerima_petty_cash', 'ubah', id_penerimaan_petty_cash],
    () =>
      PenerimaanPettyCashApi.getSingle({ id_penerimaan_petty_cash }).then(
        (res) => ({
          ...res,
          data: {
            ...res.data,
            foto_penerimaan_petty_cash: {
              url: `${REACT_APP_SHOW_FILE_BASE_URL}${res.data.foto_penerimaan_petty_cash}`,
              path: res.data.foto_penerimaan_petty_cash,
              name: res.data.foto_penerimaan_petty_cash?.split('/').pop(),
            },
          },
        })
      ),
    { enabled: !!id_penerimaan_petty_cash }
  );

  const updatePenerimaanPettyCash = useMutation((data) =>
    PenerimaanPettyCashApi.update(data)
  );

  const onSubmitForm = async (values, { resetForm }) => {
    await modalConfirm.trigger({
      size: 'md',
      type: 'update',
      component: (
        <Formik initialValues={values}>
          <PenerimaanPettyCashConfirmationContent />
        </Formik>
      ),

      onSubmit: async () => {
        modalConfirm.loading();

        const checkImage = async () => {
          if (values.bukti.data) {
            return uploadImageServices(values.bukti.data)
              .then(({ data }) => data.data)
              .catch((err) => {
                modalConfirm.infoError({
                  title: 'Data Penerimaan Petty Cash Gagal Diubah',
                  customTextInfoTitle: 'Penyebab Kegagalan:',
                  customTextInfoDetail: err?.response?.data?.message,
                });
                return null;
              });
          } else {
            return values.bukti.path;
          }
        };

        const uploadedImage = await checkImage();

        if (uploadedImage) {
          const finalValues = formSubmitValueMapper({
            ...values,
            foto_penerimaan_petty_cash: uploadedImage,
          });

          await updatePenerimaanPettyCash
            .mutateAsync(finalValues)
            .then(() =>
              modalConfirm.infoSuccess({
                title: 'Data Penerimaan Petty Cash Berhasil Diubah',
                customTextInfoDetail: 'Data telah tersimpan ke database',
                size: 'md',
                onHide: () => {
                  resetForm();
                  modalConfirm.close();
                  history.replace(
                    '/human-resource/transaksi/penerimaan-petty-cash'
                  );
                },
              })
            )
            .catch((err) =>
              modalConfirm.infoError({
                title: 'Data Penerimaan Petty Cash Gagal Diubah',
                customTextInfoTitle: 'Penyebab Kegagalan:',
                customTextInfoDetail: err?.response?.data?.message,
              })
            );
        }
      },
      onHide: () => modalConfirm.close(),
    });
  };

  useEffect(() => setNavbarTitle('Penerimaan Petty Cash'), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <ButtonBack size="md" onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {isLoading || isError ? (
        <DataStatus
          loading={isLoading}
          text={isLoading ? 'Memuat . . .' : 'Data gagal dimuat'}
        />
      ) : (
        <Formik
          enableReinitialize
          initialValues={formInitialValues(data.data, 'UPDATE')}
          validationSchema={formValidationSchema}
          onSubmit={onSubmitForm}
        >
          {({ handleSubmit }) => (
            <Card className="p-4 mt-4">
              <PenerimaanPettyCashContent action="UPDATE" />
              <Row className="mt-4 px-3 d-flex justify-content-end align-items-center">
                <ButtonUpdate size="md" onClick={() => handleSubmit()} />
              </Row>
              <Approval
                tab="history"
                title="Penerimaan Petty Cash"
                withLine={false}
                data={data.data.stakeholder ?? []}
              />
            </Card>
          )}
        </Formik>
      )}
    </CRUDLayout>
  );
};
