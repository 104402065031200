// React
import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

// API
import Axios from "axios";
import { SalesOrderApi } from "../../../api";

// Form
import * as Yup from "yup";
import { Formik } from "formik";

// Component
import {
  Alert,
  DataStatus,
  BackButton,
  TextArea,
  ActionButton,
  Tr,
  Td,
  TdFixed,
  TextEditor,
  DatePicker,
  Input,
  THead,
  ThFixed,
  Th,
  TBody,
  Table,
  ReactNumberFormat,
} from "../../../components";
import { Row, Col, Card, Modal } from "react-bootstrap";
import { RupiahConvert, DateConvert, DecimalConvert } from "../../../utilities";

// Draft JS
import { EditorState, ContentState, convertFromHTML } from "draft-js";

const DetailSalesOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams();
  const { no_sales_order, tab = "" } = location.state;
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [dataSO, setDataSO] = useState({});
  const [approveStatus, setApproveStatus] = useState("V");
  const [dataApproval, setDataApproval] = useState([]);

  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    // GET SINGLE DETAIL
    Axios.all([
      SalesOrderApi.getSingle({
        no_sales_order: no_sales_order,
        id_sales_order: id,
      }),
    ])
      .then(
        Axios.spread((data) => {
          const { approval, detail } = data.data.data;
          const behavior = data.data.data.behavior
            ? data.data.data.behavior
            : "V";
          setDataSO(detail);
          setDataApproval(approval ?? []);
          setApproveStatus(behavior.toUpperCase());
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const InfoPeluangSection = ({ data }) => {
    // States
    const [textEditorState, setTextEditorState] = useState(() =>
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML("<p></p>"))
      )
    );

    useEffect(
      () =>
        setTextEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(dataSO?.informasi_lainnya ?? "<p></p>")
            )
          )
        ),
      []
    );

    const List = ({ label, children }) => (
      <Tr>
        <Td>
          <b>{label}</b>
        </Td>
        <TdFixed>:</TdFixed>
        <Td>{children}</Td>
      </Tr>
    );

    return (
      <>
        <Row>
          <Col md>
            <List label="Tgl. Peluang">
              {data?.tgl_peluang
                ? DateConvert(new Date(data.tgl_peluang)).detail
                : "-"}
            </List>
            <List label="No. Peluang">{data?.no_peluang ?? "-"}</List>
            <List label="Peringkat Peluang">
              {data?.nama_peringkat_peluang ?? "-"}
            </List>
          </Col>

          <Col md>
            <List label="Customer">{data?.nama_customer ?? "-"}</List>
            <List label="ATT">{data?.att ?? "-"}</List>
            <List label="Proyek">{data?.nama_proyek ?? "-"}</List>
          </Col>
        </Row>

        <Row>
          <Col md>
            <TextArea
              label="Keterangan Pengiriman"
              disabled={true}
              value={data?.keterangan_pengiriman}
              rows="4"
            />

            <TextArea
              label="Lokasi Pengiriman"
              disabled={true}
              value={data?.lokasi_pengiriman}
              rows="4"
            />
          </Col>

          <Col md>
            <TextEditor
              readOnly
              label="Informasi Lainnya"
              editorState={textEditorState}
              options={[]}
              editorStyle={{ height: 200 }}
            />
          </Col>
        </Row>

        <hr />
      </>
    );
  };

  const InfoRABSection = ({ data }) => {
    const List = ({ label, children }) => (
      <Tr>
        <Td>
          <b>{label}</b>
        </Td>
        <TdFixed>:</TdFixed>
        <Td>{children}</Td>
      </Tr>
    );

    return (
      <>
        <Row>
          {/* <Col md>
            <List label="Tgl. Unit Cost">
              {data?.tgl_rae ? DateConvert(new Date(data.tgl_rae)).detail : "-"}
            </List>
            <List label="No. Unit Cost">{data?.no_rae ?? "-"}</List>
          </Col> */}

          <Col md>
            <List label="Tgl. RAB">
              {data?.tgl_rab ? DateConvert(new Date(data.tgl_rab)).detail : "-"}
            </List>
            <List label="No. RAB">{data?.no_rab ?? "-"}</List>
          </Col>
        </Row>

        <hr />
      </>
    );
  };

  const initialDataValues = {
    tgl_sales_order: dataSO?.tgl_sales_order
      ? DateConvert(new Date(dataSO.tgl_sales_order)).default
      : "",
    no_sales_order: dataSO?.no_sales_order ?? "",
    id_rab: dataSO?.id_rab ?? "",
    batas_waktu: dataSO?.batas_waktu
      ? DateConvert(new Date(dataSO.batas_waktu)).default
      : "",
    id_karyawan: dataSO?.id_sales ?? null,
    diskon: dataSO?.diskon ? parseFloat(dataSO.diskon) : 0,
    ppn: dataSO?.ppn ? parseFloat(dataSO.ppn) : 0,
    catatan: dataSO?.catatan ?? "",
    alamat_pengiriman: dataSO?.alamat_pengiriman ?? "",
    id_customer: dataSO?.id_customer ?? "",
    nama_sales: dataSO?.nama_sales ?? "",
  };

  const FormSection = ({ formik, readOnly = false }) => {
    return (
      <>
        <Row>
          <Col lg="2">
            <DatePicker
              disabled={readOnly}
              label="Tgl. Sales Order"
              placeholderText="Pilih Tgl. Sales Order"
              selected={
                formik.values.tgl_sales_order
                  ? new Date(formik.values.tgl_sales_order)
                  : ""
              }
              error={
                formik.errors.tgl_sales_order &&
                formik.touched.tgl_sales_order &&
                true
              }
              errorText={
                formik.errors.tgl_sales_order &&
                formik.touched.tgl_sales_order &&
                formik.errors.tgl_sales_order
              }
            />
          </Col>

          <Col lg="3">
            <Input
              label="No. Sales Order"
              value={formik.values.no_sales_order}
              readOnly={true}
            />
          </Col>

          <Col lg="2">
            <DatePicker
              disabled={readOnly}
              label="Tgl. Batas Akhir"
              placeholderText="Pilih Tgl. Batas Akhir"
              selected={
                formik.values.batas_waktu
                  ? new Date(formik.values.batas_waktu)
                  : ""
              }
              onChange={(val) =>
                formik.setFieldValue(
                  "batas_waktu",
                  DateConvert(new Date(val)).default
                )
              }
              error={
                formik.errors.batas_waktu && formik.touched.batas_waktu && true
              }
              errorText={
                formik.errors.batas_waktu &&
                formik.touched.batas_waktu &&
                formik.errors.batas_waktu
              }
            />
          </Col>

          <Col lg>
            <Input
              label="Nama Sales"
              value={formik.values.nama_sales}
              readOnly={true}
            />
          </Col>
        </Row>
      </>
    );
  };

  const ListBarangJadiSection = ({ dataSO, formik, readOnly = false }) => {
    // Variables
    const detail = dataSO ?? [];
    const diskon = formik.values.diskon
      ? formik.values.diskon === ""
        ? 0
        : parseFloat(formik.values.diskon)
      : 0;
    const ppn = formik.values.ppn
      ? formik.values.ppn === ""
        ? 0
        : parseFloat(formik.values.ppn)
      : 0;
    let total = 0;

    return (
      <>
        <small>List Barang Jadi RAB</small>
        <br />

        <Table>
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <ThFixed>Kode Barang Jadi</ThFixed>
              <Th>Nama Barang Jadi</Th>
              <Th>Gudang</Th>
              <ThFixed>Qty. RAB</ThFixed>
              <ThFixed>Satuan</ThFixed>
              <ThFixed>Harga Satuan</ThFixed>
              {/* <ThFixed>Unit Cost + Profit(10%)</ThFixed> */}
              <ThFixed>Sub Total</ThFixed>
            </Tr>
          </THead>

          <TBody>
            {detail?.length > 0 ? (
              <>
                {detail.map((val, index) => {
                  const profit = val.unit_cost
                    ? parseFloat(
                        parseFloat(val.unit_cost) +
                          (parseFloat(val.unit_cost) * 10) / 100
                      )
                    : 0;
                  const sub_total = val.qty_sales_order
                    ? val.unit_cost * parseFloat(val.qty_sales_order)
                    : 0;
                  total = total + sub_total;

                  return (
                    <Tr key="index">
                      <TdFixed>{index + 1}</TdFixed>
                      <TdFixed>{val.kode_barang}</TdFixed>
                      <Td>{val.nama_barang}</Td>
                      <Td>{val?.nama_gudang ?? "-"}</Td>
                      <TdFixed className="text-right">
                        {val?.qty_sales_order
                          ? DecimalConvert(val.qty_sales_order).getWithComa
                          : 0}
                      </TdFixed>
                      <TdFixed>{val.kode_satuan}</TdFixed>
                      <Td className="text-right text-nowrap">
                        {RupiahConvert(val.unit_cost.toString()).getWithComa}
                      </Td>
                      {/* <Td className="text-right text-nowrap">
                        {RupiahConvert(profit.toString()).getWithComa}
                      </Td> */}
                      <Td className="text-right text-nowrap">
                        {RupiahConvert(sub_total.toString()).getWithComa}
                      </Td>
                    </Tr>
                  );
                })}
                <Tr>
                  <Td colSpan={7} className="text-right">
                    <b>Total</b>
                  </Td>
                  <Td className="text-right text-nowrap">
                    {RupiahConvert(total.toString()).getWithComa}
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={6} className="text-right">
                    <b>Diskon (%)</b>
                  </Td>
                  <Td noPadding>
                    {/* <Input
                      noMargin
                      label=""
                      placeholder="Diskon"
                      name="diskon"
                      value={formik.values.diskon}
                      onChange={formik.handleChange}
                      error={
                        formik.errors.diskon && formik.touched.diskon && true
                      }
                      errorText=""
                      readOnly={readOnly}
                    /> */}
                    <ReactNumberFormat
                      disabled
                      className="text-right"
                      value={parseFloat(formik.values.diskon)}
                      // onChange={(e) => setFieldValue("ppn", e)}
                    />
                  </Td>
                  <Td className="text-right text-nowrap">
                    {
                      RupiahConvert(
                        parseFloat((total * diskon) / 100).toString()
                      ).getWithComa
                    }
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={7} className="text-right">
                    <b>Total Setelah Dikurangi Diskon</b>
                  </Td>
                  <Td className="text-right text-nowrap">
                    {
                      RupiahConvert(
                        (total - parseFloat((total * diskon) / 100)).toString()
                      ).getWithComa
                    }
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={6} className="text-right">
                    <b>PPn (%)</b>
                  </Td>
                  <Td noPadding>
                    {/* <Input
                      noMargin
                      label=""
                      placeholder="PPn"
                      name="ppn"
                      value={formik.values.ppn}
                      onChange={formik.handleChange}
                      error={formik.errors.ppn && formik.touched.ppn && true}
                      errorText=""
                      readOnly={readOnly}
                    /> */}
                    <ReactNumberFormat
                      disabled
                      className="text-right"
                      value={parseFloat(formik.values.ppn)}
                      // onChange={(e) => setFieldValue("ppn", e)}
                    />
                  </Td>
                  <Td className="text-right text-nowrap">
                    {
                      RupiahConvert(
                        parseFloat(
                          ((total - parseFloat((total * diskon) / 100)) * ppn) /
                            100
                        ).toString()
                      ).getWithComa
                    }
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={7} className="text-right">
                    <b>Total Setelah Ditambah PPn</b>
                  </Td>
                  <Td className="text-right text-nowrap">
                    {
                      RupiahConvert(
                        parseFloat(
                          total -
                            parseFloat((total * diskon) / 100) +
                            parseFloat(
                              ((total - parseFloat((total * diskon) / 100)) *
                                ppn) /
                                100
                            )
                        ).toString()
                      ).getWithComa
                    }
                  </Td>
                </Tr>
              </>
            ) : (
              <Tr>
                <Td colSpan={8}>
                  <DataStatus text="Tidak Ada Data" />
                </Td>
              </Tr>
            )}
          </TBody>
        </Table>
      </>
    );
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>
                {modalConfig.title === "approve"
                  ? approveStatus === "A"
                    ? "Approve"
                    : "Verify"
                  : modalConfig.title}{" "}
                dengan catatan :
              </b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "APP"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type === "REJ"
                ? "Reject"
                : "Revise"
            }
            variant={
              modalConfig.type === "APP"
                ? "success"
                : modalConfig.type === "REV"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }>
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({
    values,
    handleChange,
    validateForm,
    errors,
    touched,
    setTouched,
    dirty,
  }) => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="col-md-4 mb-4">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Sales Order</b>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-content-between flex-wrap">
            {dataApproval.map((val, index) => (
              <InfoItem
                key={index}
                title1={
                  val.status_approval === "VER"
                    ? `PEMERIKSA ${val.approval_level}`
                    : "PENGESAH"
                }
                value1={val.nama_karyawan ?? "-"}
                title2={"Catatan"}
                value2={val.catatan ?? "-"}
              />
            ))}
          </div>
          <hr />
          {tab !== "history" && (
            <div>
              <TextArea
                label="Catatan"
                name="catatan"
                placeholder="Masukan catatan"
                value={values.catatan}
                onChange={handleChange}
                error={errors.catatan && true}
                errorText={errors.catatan}
              />
              <div className="d-flex justify-content-end mt-3">
                {approveStatus === "A" ? (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REJ",
                            title: (
                              <span className="text-danger">
                                Reject Sales Order
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "APP",
                            title: (
                              <span className="text-success">
                                Approve Sales Order
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REJ",
                            title: (
                              <span className="text-danger">
                                Reject Sales Order
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text="Revise"
                      variant="warning"
                      className="m-1 text-white"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REV",
                            title: (
                              <span className="text-warning">
                                Revise Sales Order
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "APP",
                            title: (
                              <span className="text-success">
                                Approve Sales Order
                              </span>
                            ),
                          });
                        }
                        validateForm();
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    );
  };

  useEffect(() => {
    setNavbarTitle("Approval Sales Order");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Sales Order</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <InfoPeluangSection data={dataSO} />
              <InfoRABSection data={dataSO} />
              <Formik
                initialValues={initialDataValues}
                onSubmit={(e) => e.preventDefault()}>
                {(formik) => (
                  <>
                    <FormSection formik={formik} readOnly={true} />
                    <ListBarangJadiSection
                      dataSO={dataSO.detail}
                      formik={formik}
                      readOnly={true}
                    />
                  </>
                )}
              </Formik>
            </>
          )}
        </Card.Body>
      </Card>

      <Formik
        initialValues={{ catatan: "" }}
        validationSchema={Yup.object().shape({
          catatan: Yup.string().required("Masukan Catatan"),
        })}
        onSubmit={(values) => {
          const finalValues = {
            ...values,
            no_transaksi: dataSO.no_sales_order,
            status_approval: modalConfig.type,
            tgl_approval: DateConvert(new Date()).default,
            approval_baseline: dataSO.baseline,
          };

          SalesOrderApi.approve(finalValues)
            .then((res) => {
              history.push("/human-resource/approval/sales-order", {
                alert: {
                  show: true,
                  variant: "primary",
                  text: `${
                    modalConfig.type === "APP"
                      ? "Approve"
                      : modalConfig.type === "REV"
                      ? "Revise"
                      : "Reject"
                  } data berhasil!`,
                },
              });
            })
            .catch((err) => {
              setAlertConfig({
                show: true,
                variant: "danger",
                text: `Simpan approval gagal! (${
                  err?.response?.data?.message ?? ""
                })`,
              });
            })
            .finally(() => setModalConfig({ show: false }));
        }}>
        {({
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          errors,
          touched,
          validateForm,
          dirty,
        }) => (
          <>
            <FormCard
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              validateForm={validateForm}
              dirty={dirty}
            />
            <ModalSection
              values={values}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default DetailSalesOrder;
