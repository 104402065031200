import { Button } from "react-bootstrap";
import "./style.css";

const ApprovalStatusButton = ({ variant, children, style, ...rest }) => (
  <>
    <Button
      variant={variant}
      size="sm"
      block
      style={{ borderWidth: "2px", ...style }}
      className="no-hover"
      {...rest}>
      <b>{children}</b>
    </Button>
  </>
);

export default ApprovalStatusButton;
