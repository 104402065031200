import Services from "services";

const Mock = {
  get: (url, params) =>
    new Promise((res, rej) =>
      setTimeout(() => {
        switch (url) {
          case "/hrdu/realisasi_petty_cash/page":
            res({
              data: {
                data: [
                  {
                    nama_item: "coba keperluan hrdu/realisasi",
                    qty: "1",
                    nama_satuan: "Liter",
                    harga_satuan: "10000",
                    keterangan: "biaya bensin",
                    files: [
                      {
                        link: "https://dummyimage.com/600x400/000/fff&text=item1",
                      },
                      {
                        link: "https://dummyimage.com/600x400/000/fff&text=item2",
                      },
                    ],
                  },
                  {
                    nama_item: "coba keperluan hrdu/realisasi2",
                    qty: "2",
                    nama_satuan: "Liter",
                    harga_satuan: "10000",
                    keterangan: "biaya bensin2",
                    files: [
                      {
                        link: "https://dummyimage.com/600x400/000/fff&text=test",
                      },
                      {
                        link: "https://dummyimage.com/600x400/000/fff&text=test2",
                      },
                    ],
                  },
                ],
                total_page: 1,
              },
            });
            break;

          case "/v1/example/single":
            res({
              data: {
                tanggal: "2020-01-01",
                keterangan: "",
                karyawan: Array.from({ length: 2 }).map((_, i) => ({
                  id: i + 1,
                  nama: `User ${i + 1}`,
                })),
                detail: Array.from({ length: 50 }).map((_, i) => ({
                  barang: `Barang ${i}`,
                  qty: i + 10,
                  harga: i + 1000,
                })),
              },
            });
            break;

          case "/hrdu/realisasi_petty_cash/d_proyek":
            res({
              data: Array.from({ length: 50 }).map((_, i) => ({
                id: i,
                nama: `User ${i}`,
              })),
            });
            break;

          case "/hrdu/realisasi_petty_cash/no_baru":
            res({
              data: { data: "2022/02/HRDU/PC/000001" },
            });
            break;

          default:
            res({});
        }
      }, 500)
    ),
  post: (url, params) =>
    new Promise((res, rej) => setTimeout(() => res("berhasil"), 500)),
  patch: (url, params) =>
    new Promise((res, rej) => setTimeout(() => res("berhasil"), 500)),
  put: (url, params) =>
    new Promise((res, rej) => setTimeout(() => res("berhasil"), 500)),
  delete: (url, params) =>
    new Promise((res, rej) => setTimeout(() => res("berhasil"), 500)),
};

class RealisasiPettyCashApi {
  async getList(params) {
    const fetch = await Services.get("/hrdu/realisasi_petty_cash/page", {
      params,
    });
    return fetch.data;
  }

  async getSingle(params) {
    const fetch = await Services.get("/hrdu/realisasi_petty_cash/single", {
      params,
    });
    return fetch.data;
  }

  async getListPenerimaan(params) {
    const fetch = await Services.get(
      "/hrdu/realisasi_petty_cash/list_penerimaan",
      { params }
    );
    return fetch.data;
  }

  async getSinglePenerimaan(params) {
    const fetch = await Services.get(
      "/hrdu/realisasi_petty_cash/single_penerimaan",
      { params }
    );
    return fetch.data;
  }

  async getDropdownUnitProduksi() {
    const fetch = await Services.get(
      "/hrdu/pengajuan_petty_cash/unit_produksi_available"
    );
    return fetch?.data?.data?.map((val) => ({
      label: val.nama_unit_produksi,
      value: val.id_unit_produksi,
      limit_petty_cash: val.limit_petty_cash,
      id_penerima_petty_cash: val.id_penerima_petty_cash,
      id_pekerja: val.id_pekerja,
    }));
  }

  async dropdownSatuan() {
    const fetch = await Services.get("/hrdu/realisasi_petty_cash/d_satuan");
    return fetch.data.data.map((v) => ({
      value: v.id_satuan,
      label: v.nama_satuan,
      kode_satuan: v.kode_satuan,
    }));
  }

  async getDropdownStatusApproval() {
    return [
      { label: "PENDING", value: "PEN" },
      {
        value: "VER",
        label: "VERIFIED",
      },
      {
        value: "APP",
        label: "APPROVED",
      },
      {
        value: "REV",
        label: "REVISI",
      },
      {
        value: "REJ",
        label: "REJECT",
      },
    ];
  }

  generateNumber(params) {
    return Services.get("/hrdu/realisasi_petty_cash/no_baru", { params });
  }

  create(data) {
    return Services.post("/hrdu/realisasi_petty_cash", data);
  }

  update(data) {
    return Services.put("/hrdu/realisasi_petty_cash", data);
  }
}

export default new RealisasiPettyCashApi();
