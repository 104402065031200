import React from "react";
import { Row, Col } from "react-bootstrap";
import { DateConvert } from "utilities";

const InfoSection = ({ data }) => {
  const InfoItem = ({ title, text }) => (
    <tr>
      <td className="align-top" style={{ fontSize: 14 }}>
        {title}
      </td>
      <td className="pl-4 pr-2 align-top" style={{ fontSize: 14 }}>
        :
      </td>
      <td className="align-top" style={{ fontSize: 14 }}>
        {text}
      </td>
    </tr>
  );
  return (
    <>
      <Row>
        <Col md={6}>
          <InfoItem
            title="Tgl. Invoice"
            text={data.tgl_invoice ? DateConvert(new Date(data.tgl_invoice)).detail : "-"}
          />
          <InfoItem title="No. Invoice" text={data.no_invoice ?? "-"} />
        </Col>
        <Col mmd={6}>
          <InfoItem title="Customer" text={data.nama_customer ?? "-"} />
          <InfoItem title="Proyek" text={data.nama_proyek ?? "-"} />
        </Col>
      </Row>
    </>
  );
};

export default InfoSection;
