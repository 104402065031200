import Services from "../../services";

class InputBiayaAngkutApi {
  // API SERVER
  get(params) {
    return Services.get("hrdu/approval_input_biaya_angkut/list", {
      params,
    });
  }

  getHistory(params) {
    return Services.get("hrdu/approval_input_biaya_angkut/history/list", {
      params,
    });
  }

  getSingle(params) {
    return Services.get("hrdu/approval_input_biaya_angkut/detail", {
      params,
    });
  }

  approve(data) {
    return Services.post("hrdu/approval_input_biaya_angkut/approve", data);
  }
}

export default new InputBiayaAngkutApi();
