import React, { Fragment, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Row, Card } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { CRUDLayout } from 'components';
import { DataStatus, ButtonBack, ButtonCreate } from 'components2';
import { useModalConfirm } from 'hooks2';
import {
  formInitialValues,
  formValidationSchema,
  formSubmitValueMapper,
} from './__PengembalianSisaPettyCashUtils__';
import {
  RealisasiPettyCashContent,
  TableSection,
} from './__PengembalianSisaPettyCashComps__';
import PengembalianSisaPettyCashApi from './__PengembalianSisaPettyCashApi__';

const PengembalianSisaPettyCashTambah = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id: id_realisasi_petty_cash } = useParams();
  const modalConfirm = useModalConfirm();

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'single',
      'pengembalian_sisa_petty_cash',
      id_realisasi_petty_cash,
    ],
    queryFn: () =>
      PengembalianSisaPettyCashApi.getSingleRealisasi({
        id_realisasi_petty_cash,
      }),
  });

  const onSubmitForm = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    await modalConfirm.trigger({
      size: 'xl',
      type: 'create',
      customTextHeader: ' ',
      title:
        'Apakah Anda yakin akan menambah data Realisasi Petty Cash berikut?',
      component: (
        <Formik initialValues={values}>
          <Fragment>
            <RealisasiPettyCashContent action="DETAIL" />
            <hr />
            <TableSection action="CONFIRM" data={values?.detail ?? []} />
          </Fragment>
        </Formik>
      ),
      onSubmit: async () => {
        modalConfirm.loading();

        const finalValue = formSubmitValueMapper(values);

        PengembalianSisaPettyCashApi.create(finalValue)
          .then(() =>
            modalConfirm.infoSuccess({
              title: 'Data Pengembalian Sisa Petty Cash Berhasil Ditambah',
              customTextInfoDetail: 'Data telah tersimpan ke database',
              size: 'md',
              onHide: () => {
                resetForm();
                modalConfirm.close();
                history.replace(
                  '/human-resource/transaksi/pengembalian-sisa-petty-cash'
                );
              },
            })
          )
          .catch(({ response }) => {
            modalConfirm.infoError({
              title: 'Data Pengembalian Sisa Petty Cash Gagal Ditambah',
              customTextInfoTitle: 'Penyebab Kegagalan:',
              customTextInfoDetail: response?.data?.message,
            });
          });
      },
      onHide: () => modalConfirm.close(),
    });
  };

  useEffect(() => setNavbarTitle('Pengembalian Sisa Petty Cash'), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <ButtonBack size="md" onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {isLoading || isError ? (
        <DataStatus
          loading={isLoading}
          text={isLoading ? 'Memuat . . .' : 'Data gagal dimuat'}
        />
      ) : (
        <Formik
          enableReinitialize
          initialValues={formInitialValues(data.data)}
          validationSchema={formValidationSchema}
          onSubmit={onSubmitForm}
        >
          {({ values, handleSubmit, isSubmitting }) => (
            <Card className="p-4 mt-4">
              <RealisasiPettyCashContent action="CREATE" />
              <hr />
              <TableSection action="DETAIL" data={values?.detail ?? []} />
              <Row className="mt-4 px-3 d-flex justify-content-end align-items-center">
                <ButtonCreate
                  loading={isSubmitting}
                  size="md"
                  onClick={handleSubmit}
                />
              </Row>
            </Card>
          )}
        </Formik>
      )}
    </CRUDLayout>
  );
};

export default PengembalianSisaPettyCashTambah;
