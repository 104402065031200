import React, { useState } from "react";
import { Card, Nav } from "react-bootstrap";
import TableAnalisa from "./table/TableAnalisa";

const TableSection = ({ data }) => {
  const [dataAnalisa, setDataAnalisa] = useState(data?.analisa ?? []);
  const [tableTabs, setTableTabs] = useState("bahan");

  const onTabsChangeHandler = (e, newPage) => {
    e.preventDefault();
    setTableTabs(newPage);
  };

  const tabsConfig = [
    {
      tab: "bahan",
      label: "Bahan",
      component: () => (
        <TableAnalisa
          data={dataAnalisa.filter((item) => item.id_buaso === "1")}
          setData={setDataAnalisa}
          tableHead={{ column3: "Item Bahan", column5: "Harga Satuan Bahan" }}
        />
      ),
    },
    {
      tab: "upah",
      label: "Upah",
      component: () => (
        <TableAnalisa
          data={dataAnalisa.filter((item) => item.id_buaso === "2")}
          setData={setDataAnalisa}
          tableHead={{ column3: "Item Upah", column5: "Harga Satuan Kontrak" }}
        />
      ),
    },
    {
      tab: "alatmesin",
      label: "Alat & Mesin",
      component: () => (
        <TableAnalisa
          data={dataAnalisa.filter((item) => item.id_buaso === "3")}
          setData={setDataAnalisa}
          tableHead={{
            column3: "Item Alat & Mesin",
            column5: "Harga Satuan Penyusutan",
          }}
        />
      ),
    },
    {
      tab: "subcont",
      label: "Subcont",
      component: () => (
        <TableAnalisa
          data={dataAnalisa.filter((item) => item.id_buaso === "4")}
          setData={setDataAnalisa}
          tableHead={{
            column3: "Item Subcont",
            column5: "Harga Satuan Kontrak",
          }}
        />
      ),
    },
    {
      tab: "overhead",
      label: "Overhead",
      component: () => (
        <TableAnalisa
          data={dataAnalisa.filter(
            (item) => item.id_buaso === "5" && Boolean(!item.flag_kelompok)
          )}
          setData={setDataAnalisa}
          tableHead={{
            column3: "Item Overhead",
            column5: "Harga Satuan Overhead",
          }}
        />
      ),
    },
    {
      tab: "bok",
      label: "BOK",
      component: () => (
        <TableAnalisa
          data={dataAnalisa.filter(
            (item) => item.id_buaso === "5" && item.flag_kelompok === "BOK"
          )}
          setData={setDataAnalisa}
          tableHead={{
            column3: "Item BOK",
            column5: "Harga Satuan BOK",
          }}
        />
      ),
    },
  ];

  return (
    <Card className="pb-3 mt-3">
      <Card.Header>
        {/* Tab Header */}
        <Nav variant="tabs" defaultActiveKey={`#${tableTabs}`}>
          {tabsConfig.map((val, index) => (
            <Nav.Item key={`${index}-${val.label}`}>
              <Nav.Link
                href={`#${val.tab}`}
                onClick={(e) => onTabsChangeHandler(e, val.tab)}>
                {val.label}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Card.Header>

      <Card.Body>
        {tabsConfig.map(
          ({ tab, component: Component }, index) =>
            tab === tableTabs && <Component key={index} />
        )}
      </Card.Body>
    </Card>
  );
};

export default TableSection;
