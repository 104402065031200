import React from "react";
import NumberFormat from "react-number-format";
import {
  Form,
  InputGroup,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

const ReactNumberFormat = ({
  label,
  size = "sm",
  disabled,
  loading,
  value,
  placeholder,
  loadingText = "Memuat data . . .",
  valid,
  validText,
  noMargin,
  className,
  error = false,
  errorText,
  errorFetch,
  errorFetchText = "Data gagal dimuat",
  wrapperClassName,
  thousandSeparator = ".",
  decimalSeparator = ",",
  decimalScale = 2,
  tooltip,
  tooltipPlacement,
  tooltipText,
  onChange,
  ...props
}) => {
  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={tooltip ? <Tooltip>{tooltipText}</Tooltip> : <div></div>}
    >
      <Form.Group
        as="div"
        className={`${wrapperClassName ?? ""} ${
          noMargin ? "pb-0 mb-0" : "mb-2"
        }`}
      >
        <Form.Label as="small">{label}</Form.Label>
        <InputGroup hasValidation>
          <NumberFormat
            {...props}
            value={loading ? "" : errorFetch ? "" : value}
            placeholder={
              loading ? loadingText : errorFetch ? errorFetchText : placeholder
            }
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            decimalScale={decimalScale}
            className={`${className} form-control form-control-${size} ${
              error ? "is-invalid" : ""
            } ${valid ? "is-valid" : ""}`}
            onValueChange={({ floatValue }) => onChange && onChange(floatValue)}
            disabled={
              disabled ? disabled : loading ? true : errorFetch ? true : false
            }
          />
          {loading && (
            <InputGroup.Text style={{ background: "none", border: "none" }}>
              <Spinner animation="border" size="sm" variant="secondary" />
            </InputGroup.Text>
          )}
          <Form.Control.Feedback
            type={error ? "invalid" : "valid"}
            className="mt-0"
          >
            {validText}
            {errorText}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </OverlayTrigger>
  );
};

export default ReactNumberFormat;
