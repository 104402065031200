import React, { useState, useEffect } from "react";
import { ButtonGroup, Modal } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { Formik } from "formik";
import { debounce } from "lodash";
import {
  CRUDLayout,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  Pagination,
  ApprovalStatusButton,
} from "components";
import {
  InputSearch,
  DataStatus,
  ButtonCreate,
  ButtonDetail,
  ButtonUpdate,
  ButtonCancel,
  ButtonFilter,
  Select,
} from "components2";
import { tableNumber, dateConvert, rupiahConvert } from "utilities2";
import { useModalConfirm } from "hooks2";
import {
  FormPengajuanPettyCashContent,
  DetailPengajuanPettyCashContent,
  CatatanApprovalPengajuanPettyCashContent,
  ModalFilter,
} from "./__PengajuanPettyCashComps__";
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
  STATUS_APPROVAL,
  MODAL_TITLE,
} from "./__PengajuanPettyCashUtils__";
import PengajuanPettyCashApi from "./__PengajuanPettyCashApi__";
import * as Yup from "yup";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const PengajuanPettyCashList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const [pagination, setPagination] = useState({
    q: "",
    page: 1,
    per_page: 10,
  });

  const [filter, setFilter] = useState({
    tgl_pengajuan_petty_cash_start: undefined,
    tgl_pengajuan_petty_cash_end: undefined,
    id_unit_produksi: undefined,
    status_approval: undefined,
    active: false,
  });

  const [modalFilter, setModalFilter] = useState(false);

  const [modal, setModal] = useState({
    type: "CREATE", // CREATE | UPDATE | DETAIL
    show: false,
    data: {},
  });

  const getPengajuanPettyCash = useQuery(
    ["pengajuan_petty_cash", "list", pagination, filter],
    () => PengajuanPettyCashApi.getList({ ...pagination, ...filter })
  );

  const { data: unitProduksi, isFetching: isLoadingUnitProduksi } = useQuery(
    ["unitProduksi", "dropdown"],
    () => PengajuanPettyCashApi.getDropdownUnitProduksi()
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setPagination({
      ...pagination,
      q: searchKey,
    });
  };

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);

  const onCreateButtonClickHandler = () =>
    setModal({ show: true, type: "CREATE" });

  const onDetailButtonClickHandler = (data) =>
    setModal({ show: true, type: "DETAIL", data });

  const onUpdateButtonClickHandler = (data) =>
    setModal({ show: true, type: "UPDATE", data });

  const onPaginationChange = ({ selected }) =>
    setPagination({ ...pagination, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setPagination({ ...pagination, page: 1, per_page: e.target.value });

  const onSubmitForm = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    history.push(
      "/human-resource/transaksi/pengajuan-petty-cash/tambah/" +
        values.id_unit_produksi,
      {
        ...values,
      }
    );
  };

  const dismissModal = (resetForm) => {
    resetForm();
    setModal({ data: null, show: false, type: "" });
  };

  const formInitialValuesFilter = {
    tgl_pengajuan_petty_cash_start: filter?.tgl_pengajuan_petty_cash_start,
    tgl_pengajuan_petty_cash_end: filter?.tgl_pengajuan_petty_cash_end,
    id_unit_produksi: filter?.id_unit_produksi,
    status_approval: filter?.status_approval,
  };

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    });
    onFilterButtonClickkHandler();
  };

  useEffect(() => setNavbarTitle("Pengajuan Petty Cash"), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch
              onChange={debounce(searchHandler, 1500)}
              className="mr-2"
            />
            <ButtonFilter
              size="sm"
              className="text-nowrap"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ButtonCreate size="md" icon onClick={onCreateButtonClickHandler} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getPengajuanPettyCash.isLoading || getPengajuanPettyCash.isError ? (
        <DataStatus
          loading={getPengajuanPettyCash.isLoading}
          text={
            getPengajuanPettyCash.isLoading
              ? "Memuat . . ."
              : "Data gagal dimuat"
          }
        />
      ) : (
        <>
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Pengajuan Petty Cash
            </small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Aksi</ThFixed>
                <Th style={{ minWidth: 100 }} className="p-1">
                  Informasi Pengajuan Petty <br /> Cash
                </Th>
                <Th style={{ minWidth: 150 }} className="p-1">
                  Unit Produksi
                </Th>
                <Th style={{ width: 300 }} className="p-1">
                  Keperluan
                </Th>
                <Th style={{ minWidth: 150 }} className="p-1">
                  Nominal Pengajuan Petty <br /> Cash
                </Th>
                <Th style={{ width: 10 }} className="p-1">
                  Status Approval
                </Th>
              </Tr>
            </THead>
            <TBody>
              {getPengajuanPettyCash?.data?.data?.length > 0 ? (
                getPengajuanPettyCash?.data?.data?.map((e, index) => (
                  <Tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: pagination.page,
                        dataLength: pagination.per_page,
                        index,
                      })}
                    </TdFixed>
                    <TdFixed>
                      <ButtonGroup>
                        <ButtonDetail
                          icon
                          noText
                          variant="primary"
                          onClick={() => onDetailButtonClickHandler(e)}
                        />
                        {e.status_approval === "REV" && (
                          <ButtonUpdate
                            icon
                            noText
                            variant="success"
                            onClick={() => onUpdateButtonClickHandler(e)}
                          />
                        )}
                      </ButtonGroup>
                    </TdFixed>
                    <Td>
                      <div>
                        {dateConvert().getSlashDMY(
                          new Date(e.tgl_pengajuan_petty_cash)
                        )}
                      </div>
                      <div>{e.no_pengajuan_petty_cash ?? "-"}</div>
                    </Td>
                    <Td>{e.nama_unit_produksi ?? "-"}</Td>
                    <Td>{e.keperluan ?? "-"}</Td>
                    <Td textRight>
                      {rupiahConvert().getWithComa(
                        String(e.nominal_pengajuan ?? 0)
                      )}
                    </Td>
                    <Td>
                      <ApprovalStatusButton
                        variant={
                          STATUS_APPROVAL[e.status_approval ?? "PEN"]?.variant
                        }
                      >
                        {STATUS_APPROVAL[e.status_approval ?? "PEN"]?.label}
                      </ApprovalStatusButton>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={pagination?.per_page}
            dataNumber={
              pagination?.page * pagination?.per_page - pagination?.per_page + 1
            }
            dataPage={
              pagination?.dataCount < pagination?.per_page
                ? pagination?.dataCount
                : pagination?.page * pagination?.per_page
            }
            dataCount={getPengajuanPettyCash?.data?.data_count}
            currentPage={pagination?.page}
            totalPage={getPengajuanPettyCash?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}

      {/* Modal Create | Update | Detail */}

      <Formik
        enableReinitialize
        initialValues={
          modal.type === "DETAIL"
            ? formInitialValues(modal?.data, modal?.type)
            : {
                id_unit_produksi: "",
                limit_petty_cash: "",
                id_penerima_petty_cash: "",
                id_pekerja: "",
              }
        }
        validationSchema={Yup.object().shape({
          id_unit_produksi: Yup.string().required("Pilih unit produksi"),
        })}
        onSubmit={onSubmitForm}
      >
        {(formik) => (
          <Modal
            show={modal.show}
            size={modal.type === "CREATE" ? "md" : "lg"}
            onHide={() => dismissModal(formik.resetForm)}
          >
            <Modal.Header closeButton>
              <span>{MODAL_TITLE[modal.type]} Pengajuan Petty Cash </span>
            </Modal.Header>
            <Modal.Body>
              {modal.type === "DETAIL" && <DetailPengajuanPettyCashContent />}

              {modal.type === "CREATE" && (
                <Select
                  label="Unit Produksi"
                  placeholder="Pilih unit produksi"
                  name="id_unit_produksi"
                  options={unitProduksi ?? []}
                  loading={isLoadingUnitProduksi}
                  onChange={async (val) =>
                    formik.setValues({
                      ...formik.values,
                      id_unit_produksi: val.value,
                      limit_petty_cash: val?.limit_petty_cash,
                      id_penerima_petty_cash: val?.id_penerima_petty_cash,
                      id_pekerja: val?.id_pekerja,
                    })
                  }
                  defaultValue={
                    unitProduksi?.find(
                      ({ value }) => value === formik.values.id_unit_produksi
                    ) ?? null
                  }
                  error={
                    formik.errors.id_unit_produksi &&
                    formik.touched.id_unit_produksi &&
                    true
                  }
                  errorText={
                    formik.touched.id_unit_produksi &&
                    formik.errors.id_unit_produksi
                  }
                />
              )}
            </Modal.Body>

            <Modal.Footer>
              <div className="d-flex justify-content-center">
                <ButtonCancel
                  className="mr-2"
                  disabled={formik.isSubmitting}
                  onClick={() => dismissModal(formik.resetForm)}
                />

                {modal.type === "CREATE" && (
                  <ButtonCreate
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    onClick={() => formik.handleSubmit()}
                  />
                )}
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter
            data={filter}
            setData={setFilter}
            toggle={onFilterButtonClickkHandler}
          />
        </Formik>
      )}
    </CRUDLayout>
  );
};
