import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Card } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { CRUDLayout, Approval } from 'components';
import { DataStatus, ButtonBack } from 'components2';
import { formInitialValues } from './__PenerimaPettyCashUtils__';
import PenerimaanPettyCashApi from './__PenerimaanPettyCashApi__';
import { PenerimaanPettyCashContent } from './__PenerimaanPettyCashComps__';

export const PenerimaanPettyCashDetail = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id: id_penerimaan_petty_cash } = useParams();
  const { REACT_APP_SHOW_FILE_BASE_URL } = process.env;

  const { data, isLoading, isError } = useQuery(
    ['penerimaan_petty_cash', 'detail', id_penerimaan_petty_cash],
    () =>
      PenerimaanPettyCashApi.getSingle({ id_penerimaan_petty_cash }).then(
        (res) => ({
          ...res,
          data: {
            ...res.data,
            foto_penerimaan_petty_cash: {
              url: `${REACT_APP_SHOW_FILE_BASE_URL}${res.data.foto_penerimaan_petty_cash}`,
              path: res.data.foto_penerimaan_petty_cash,
              name: res.data.foto_penerimaan_petty_cash?.split('/').pop(),
            },
          },
        })
      ),
    { enabled: !!id_penerimaan_petty_cash }
  );

  useEffect(() => setNavbarTitle('Penerimaan Petty Cash'), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <ButtonBack size="md" onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {isLoading || isError ? (
        <DataStatus
          loading={isLoading}
          text={isLoading ? 'Memuat . . .' : 'Data gagal dimuat'}
        />
      ) : (
        <Formik initialValues={formInitialValues(data.data, 'DETAIL')}>
          <Card className="p-4 mt-4">
            <PenerimaanPettyCashContent action="DETAIL" />
            <Approval
              title="Penerimaan Petty Cash"
              tab="history"
              withLine={false}
              data={data?.data?.stakeholder ?? []}
            />
          </Card>
        </Formik>
      )}
    </CRUDLayout>
  );
};
