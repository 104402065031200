import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  Input,
  SelectSearch,
  ActionButton,
  Alert,
  DataStatus,
} from "components";
import { ProfilApi } from "api";

const FormDataAlamat = ({ dataKaryawan, dataProvinsi }) => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [showAlert, setShowAlert] = useState({
    show: false,
    text: "",
    variant: "primary",
  });

  const [dataAsal, setDataAsal] = useState({
    kabupaten: [],
    kecamatan: [],
    desa: [],
  });
  const [dataSekarang, setDataSekarang] = useState({
    kabupaten: [],
    kecamatan: [],
    desa: [],
  });
  const [isLoadingAsal, setIsLoadingAsal] = useState({
    kabupaten: false,
    kecamatan: false,
    desa: false,
  });
  const [isLoadingSekarang, setIsLoadingSekarang] = useState({
    kabupaten: false,
    kecamatan: false,
    desa: false,
  });

  const getDataKabupatenAsal = (id) => {
    setIsLoadingAsal({
      kabupaten: true,
      kecamatan: true,
      desa: true,
    });

    ProfilApi.getKabupaten(id)
      .then((res) =>
        setDataAsal({
          ...dataAsal,
          kabupaten: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoadingAsal({
          kabupaten: false,
          kecamatan: false,
          desa: false,
        })
      );
  };

  const getDataKecamatanAsal = (id) => {
    setIsLoadingAsal({
      ...isLoadingAsal,
      kecamatan: true,
      desa: true,
    });

    ProfilApi.getKecamatan(id)
      .then((res) =>
        setDataAsal({
          ...dataAsal,
          kecamatan: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoadingAsal({
          ...isLoadingAsal,
          kecamatan: false,
          desa: false,
        })
      );
  };

  const getDataDesaAsal = (id) => {
    setIsLoadingAsal({
      ...isLoadingAsal,
      desa: true,
    });

    ProfilApi.getDesa(id)
      .then((res) =>
        setDataAsal({
          ...dataAsal,
          desa: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoadingAsal({
          ...isLoadingAsal,
          desa: false,
        })
      );
  };

  const getDataKabupatenSekarang = (id) => {
    setIsLoadingSekarang({
      kabupaten: true,
      kecamatan: true,
      desa: true,
    });

    ProfilApi.getKabupaten(id)
      .then((res) =>
        setDataSekarang({
          ...dataSekarang,
          kabupaten: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoadingSekarang({
          kabupaten: false,
          kecamatan: false,
          desa: false,
        })
      );
  };

  const getDataKecamatanSekarang = (id) => {
    setIsLoadingSekarang({
      ...isLoadingSekarang,
      kecamatan: true,
      desa: true,
    });

    ProfilApi.getKecamatan(id)
      .then((res) =>
        setDataSekarang({
          ...dataSekarang,
          kecamatan: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoadingSekarang({
          ...isLoadingSekarang,
          kecamatan: false,
          desa: false,
        })
      );
  };

  const getDataDesaSekarang = (id) => {
    setIsLoadingSekarang({
      ...isLoadingSekarang,
      desa: true,
    });

    ProfilApi.getDesa(id)
      .then((res) =>
        setDataSekarang({
          ...dataSekarang,
          desa: res.data.data,
        })
      )
      .catch((err) => alert(err))
      .finally(() =>
        setIsLoadingSekarang({
          ...isLoadingSekarang,
          desa: false,
        })
      );
  };

  const getInitialData = () => {
    if (dataKaryawan.id_provinsi_asal) {
      Axios.all([
        // get data asal
        ProfilApi.getKabupaten(dataKaryawan.id_provinsi_asal),
        ProfilApi.getKecamatan(dataKaryawan.id_kabupaten_asal),
        ProfilApi.getDesa(dataKaryawan.id_kecamatan_asal),

        // get data saat ini
        ProfilApi.getKabupaten(dataKaryawan.id_provinsi_saat_ini),
        ProfilApi.getKecamatan(dataKaryawan.id_kabupaten_saat_ini),
        ProfilApi.getDesa(dataKaryawan.id_kecamatan_saat_ini),
      ])
        .then(
          Axios.spread(
            (
              kabupatenAsal,
              kecamatanAsal,
              desaAsal,
              kabupatenSekarang,
              kecamatanSekarang,
              desaSekarang
            ) => {
              setDataAsal({
                kabupaten: kabupatenAsal.data.data,
                kecamatan: kecamatanAsal.data.data,
                desa: desaAsal.data.data,
              });
              setDataSekarang({
                kabupaten: kabupatenSekarang.data.data,
                kecamatan: kecamatanSekarang.data.data,
                desa: desaSekarang.data.data,
              });
            }
          )
        )
        .catch((err) => alert(err))
        .finally(() => setIsPageLoading(false));
    } else {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const formInitialValues = {
    id_karyawan: dataKaryawan.id_karyawan,
    id_provinsi_asal: dataKaryawan.id_provinsi_asal,
    id_kabupaten_asal: dataKaryawan.id_kabupaten_asal,
    id_kecamatan_asal: dataKaryawan.id_kecamatan_asal,
    id_desa_asal: dataKaryawan.id_desa_asal,
    alamat_asal: dataKaryawan.alamat_asal,
    kode_pos_asal: dataKaryawan.kode_pos_asal,
    id_provinsi_saat_ini: dataKaryawan.id_provinsi_saat_ini,
    id_kabupaten_saat_ini: dataKaryawan.id_kabupaten_saat_ini,
    id_kecamatan_saat_ini: dataKaryawan.id_kecamatan_saat_ini,
    id_desa_saat_ini: dataKaryawan.id_desa_saat_ini,
    alamat_saat_ini: dataKaryawan.alamat_saat_ini,
    kode_pos_saat_ini: dataKaryawan.kode_pos_saat_ini,
  };

  const formValidationSchema = Yup.object().shape({
    id_provinsi_asal: Yup.string().required("Pilih provinsi asal").nullable(),
    id_kabupaten_asal: Yup.string().required("Pilih kabupaten asal").nullable(),
    id_kecamatan_asal: Yup.string().required("Pilih kecamatan asal").nullable(),
    id_desa_asal: Yup.string().required("Pilih desa asal").nullable(),
    alamat_asal: Yup.string().required("Masukan alamat asal").nullable(),
    kode_pos_asal: Yup.string().required("Masukan kode pos asal").nullable(),
    id_provinsi_saat_ini: Yup.string()
      .required("Pilih provinsi saat ini")
      .nullable(),
    id_kabupaten_saat_ini: Yup.string()
      .required("Pilih kabupaten saat ini")
      .nullable(),
    id_kecamatan_saat_ini: Yup.string()
      .required("Pilih kecamatan saat ini")
      .nullable(),
    id_desa_saat_ini: Yup.string().required("Pilih desa saat ini").nullable(),
    alamat_saat_ini: Yup.string()
      .required("Masukan alamat saat ini")
      .nullable(),
    kode_pos_saat_ini: Yup.string()
      .required("Masukan kode pos saat ini")
      .nullable(),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    ProfilApi.updateDataAlamat(values)
      .then(() => {
        setShowAlert({
          show: true,
          variant: "primary",
          text: "Ubah data sukses!",
        });
      })
      .catch((err) => {
        setShowAlert({
          show: true,
          variant: "danger",
          text: `Ubah data gagal! (${err})`,
        });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data alamat . . ." />
      ) : (
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form className="py-2" onSubmit={handleSubmit}>
              <Alert
                show={showAlert.show}
                showCloseButton={true}
                text={showAlert.text}
                variant={showAlert.variant}
                onClose={() =>
                  setShowAlert({
                    ...showAlert,
                    show: false,
                  })
                }
              />
              <Row>
                <Col lg>
                  <SelectSearch
                    label="Provinsi Asal"
                    placeholder="Pilih provinsi asal"
                    name="id_provinsi_asal"
                    defaultValue={{
                      value: dataKaryawan.id_provinsi_asal
                        ? dataKaryawan.id_provinsi_asal
                        : "",
                      label: dataKaryawan.nama_provinsi_asal
                        ? dataKaryawan.nama_provinsi_asal
                        : "Pilih provinsi asal",
                    }}
                    option={dataProvinsi.map((val) => {
                      return {
                        value: val.id_provinsi,
                        label: val.nama_provinsi,
                      };
                    })}
                    onChange={(val) => {
                      setFieldValue("id_provinsi_asal", val.value);
                      setFieldValue("id_kabupaten_asal", "");
                      setFieldValue("id_kecamatan_asal", "");
                      setFieldValue("id_desa_asal", "");
                      getDataKabupatenAsal(val.value);
                    }}
                    error={
                      errors.id_provinsi_asal &&
                      touched.id_provinsi_asal &&
                      true
                    }
                    errorText={
                      errors.id_provinsi_asal &&
                      touched.id_provinsi_asal &&
                      errors.id_provinsi_asal
                    }
                  />
                </Col>
                <Col lg>
                  <SelectSearch
                    label="Kabupaten Asal"
                    placeholder="Pilih kabupaten asal"
                    name="id_kabupaten_asal"
                    defaultValue={
                      values.id_kabupaten_asal
                        ? {
                            value: dataKaryawan.id_kabupaten_asal,
                            label: dataKaryawan.nama_kabupaten_asal,
                          }
                        : false
                    }
                    option={dataAsal.kabupaten.map((val) => {
                      return {
                        value: val.id_kabupaten,
                        label: val.nama_kabupaten,
                      };
                    })}
                    onChange={(val) => {
                      setFieldValue("id_kabupaten_asal", val.value);
                      setFieldValue("id_kecamatan_asal", "");
                      setFieldValue("id_desa_asal", "");
                      getDataKecamatanAsal(val.value);
                    }}
                    loading={isLoadingAsal.kabupaten}
                    isDisabled={values.id_provinsi_asal ? false : true}
                    error={
                      errors.id_kabupaten_asal &&
                      touched.id_kabupaten_asal &&
                      true
                    }
                    errorText={
                      errors.id_kabupaten_asal &&
                      touched.id_kabupaten_asal &&
                      errors.id_kabupaten_asal
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col lg>
                  <SelectSearch
                    label="Kecamatan Asal"
                    placeholder="Pilih kecamatan asal"
                    name="id_kecamatan_asal"
                    defaultValue={
                      values.id_kecamatan_asal
                        ? {
                            value: dataKaryawan.id_kecamatan_asal,
                            label: dataKaryawan.nama_kecamatan_asal,
                          }
                        : false
                    }
                    option={dataAsal.kecamatan.map((val) => {
                      return {
                        value: val.id_kecamatan,
                        label: val.nama_kecamatan,
                      };
                    })}
                    onChange={(val) => {
                      setFieldValue("id_kecamatan_asal", val.value);
                      setFieldValue("id_desa_asal", "");
                      getDataDesaAsal(val.value);
                    }}
                    loading={isLoadingAsal.kecamatan}
                    isDisabled={values.id_kabupaten_asal ? false : true}
                    error={
                      errors.id_kecamatan_asal &&
                      touched.id_kecamatan_asal &&
                      true
                    }
                    errorText={
                      errors.id_kecamatan_asal &&
                      touched.id_kecamatan_asal &&
                      errors.id_kecamatan_asal
                    }
                  />
                </Col>
                <Col lg>
                  <SelectSearch
                    label="Desa Asal"
                    placeholder="Pilih desa asal"
                    name="id_desa_asal"
                    defaultValue={
                      values.id_desa_asal
                        ? {
                            value: dataKaryawan.id_desa_asal,
                            label: dataKaryawan.nama_desa_asal,
                          }
                        : false
                    }
                    option={dataAsal.desa.map((val) => {
                      return {
                        value: val.id_desa,
                        label: val.nama_desa,
                      };
                    })}
                    onChange={(val) => {
                      setFieldValue("id_desa_asal", val.value);
                    }}
                    loading={isLoadingAsal.desa}
                    isDisabled={values.id_kecamatan_asal ? false : true}
                    error={errors.id_desa_asal && touched.id_desa_asal && true}
                    errorText={
                      errors.id_desa_asal &&
                      touched.id_desa_asal &&
                      errors.id_desa_asal
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col lg>
                  <Input
                    type="text"
                    label="Alamat Asal"
                    placeholder="Masukan alamat asal"
                    name="alamat_asal"
                    value={values.alamat_asal}
                    error={errors.alamat_asal && touched.alamat_asal && true}
                    errorText={errors.alamat_asal}
                    onChange={handleChange}
                  />
                </Col>
                <Col lg>
                  <Input
                    type="text"
                    label="Kode Pos"
                    placeholder="Masukan kode pos asal"
                    name="kode_pos_asal"
                    value={values.kode_pos_asal}
                    error={
                      errors.kode_pos_asal && touched.kode_pos_asal && true
                    }
                    errorText={errors.kode_pos_asal}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg>
                  <SelectSearch
                    label="Provinsi Saat Ini"
                    placeholder="Pilih provinsi saat ini"
                    name="id_provinsi_saat_ini"
                    defaultValue={{
                      value: dataKaryawan.id_provinsi_saat_ini
                        ? dataKaryawan.id_provinsi_saat_ini
                        : "",
                      label: dataKaryawan.nama_provinsi_saat_ini
                        ? dataKaryawan.nama_provinsi_saat_ini
                        : "Pilih provinsi saat ini",
                    }}
                    option={dataProvinsi.map((val) => {
                      return {
                        value: val.id_provinsi,
                        label: val.nama_provinsi,
                      };
                    })}
                    onChange={(val) => {
                      setFieldValue("id_provinsi_saat_ini", val.value);
                      setFieldValue("id_kabupaten_saat_ini", "");
                      setFieldValue("id_kecamatan_saat_ini", "");
                      setFieldValue("id_desa_saat_ini", "");
                      getDataKabupatenSekarang(val.value);
                    }}
                    error={
                      errors.id_provinsi_saat_ini &&
                      touched.id_provinsi_saat_ini &&
                      true
                    }
                    errorText={
                      errors.id_provinsi_saat_ini &&
                      touched.id_provinsi_saat_ini &&
                      errors.id_provinsi_saat_ini
                    }
                  />
                </Col>
                <Col lg>
                  <SelectSearch
                    label="Kabupaten Saat Ini"
                    placeholder="Pilih kabupaten saat ini"
                    name="id_kabupaten_saat_ini"
                    defaultValue={
                      values.id_kabupaten_saat_ini
                        ? {
                            value: dataKaryawan.id_kabupaten_saat_ini,
                            label: dataKaryawan.nama_kabupaten_saat_ini,
                          }
                        : false
                    }
                    option={dataSekarang.kabupaten.map((val) => {
                      return {
                        value: val.id_kabupaten,
                        label: val.nama_kabupaten,
                      };
                    })}
                    onChange={(val) => {
                      setFieldValue("id_kabupaten_saat_ini", val.value);
                      setFieldValue("id_kecamatan_saat_ini", "");
                      setFieldValue("id_desa_saat_ini", "");
                      getDataKecamatanSekarang(val.value);
                    }}
                    loading={isLoadingSekarang.kabupaten}
                    isDisabled={values.id_provinsi_saat_ini ? false : true}
                    error={
                      errors.id_kabupaten_saat_ini &&
                      touched.id_kabupaten_saat_ini &&
                      true
                    }
                    errorText={
                      errors.id_kabupaten_saat_ini &&
                      touched.id_kabupaten_saat_ini
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col lg>
                  <SelectSearch
                    label="Kecamatan Saat Ini"
                    placeholder="Pilih kecamatan saat ini"
                    name="id_kecamatan_saat_ini"
                    defaultValue={
                      values.id_kecamatan_saat_ini
                        ? {
                            value: dataKaryawan.id_kecamatan_saat_ini,
                            label: dataKaryawan.nama_kecamatan_saat_ini,
                          }
                        : false
                    }
                    option={dataSekarang.kecamatan.map((val) => {
                      return {
                        value: val.id_kecamatan,
                        label: val.nama_kecamatan,
                      };
                    })}
                    onChange={(val) => {
                      setFieldValue("id_kecamatan_saat_ini", val.value);
                      setFieldValue("id_desa_saat_ini", "");
                      getDataDesaSekarang(val.value);
                    }}
                    loading={isLoadingSekarang.kecamatan}
                    isDisabled={values.id_kabupaten_saat_ini ? false : true}
                    error={
                      errors.id_kecamatan_saat_ini &&
                      touched.id_kecamatan_saat_ini &&
                      true
                    }
                    errorText={
                      errors.id_kecamatan_saat_ini &&
                      touched.id_kecamatan_saat_ini &&
                      errors.id_kecamatan_saat_ini
                    }
                  />
                </Col>
                <Col lg>
                  <SelectSearch
                    label="Desa Saat Ini"
                    placeholder="Pilih desa saat ini"
                    name="id_desa_saat_ini"
                    defaultValue={
                      values.id_desa_saat_ini
                        ? {
                            value: dataKaryawan.id_desa_saat_ini,
                            label: dataKaryawan.nama_desa_saat_ini,
                          }
                        : false
                    }
                    option={dataSekarang.desa.map((val) => {
                      return {
                        value: val.id_desa,
                        label: val.nama_desa,
                      };
                    })}
                    onChange={(val) => {
                      setFieldValue("id_desa_saat_ini", val.value);
                    }}
                    loading={isLoadingSekarang.desa}
                    isDisabled={values.id_kecamatan_saat_ini ? false : true}
                    error={
                      errors.id_desa_saat_ini &&
                      touched.id_desa_saat_ini &&
                      true
                    }
                    errorText={
                      errors.id_desa_saat_ini &&
                      touched.id_desa_saat_ini &&
                      errors.id_desa_saat_ini
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col lg>
                  <Input
                    type="text"
                    label="Alamat Saat Ini"
                    placeholder="Masukan alamat saat ini"
                    name="alamat_saat_ini"
                    value={values.alamat_saat_ini}
                    error={
                      errors.alamat_saat_ini && touched.alamat_saat_ini && true
                    }
                    errorText={errors.alamat_saat_ini}
                    onChange={handleChange}
                  />
                </Col>
                <Col lg>
                  <Input
                    type="text"
                    label="Kode Pos Saat Ini"
                    placeholder="Masukan kode pos saat ini"
                    name="kode_pos_saat_ini"
                    value={values.kode_pos_saat_ini}
                    error={
                      errors.kode_pos_saat_ini &&
                      touched.kode_pos_saat_ini &&
                      true
                    }
                    errorText={errors.kode_pos_saat_ini}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end mt-2">
                <ActionButton
                  type="submit"
                  variant="success"
                  text="Ubah Data Alamat"
                  loading={isSubmitting}
                />
              </div>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default FormDataAlamat;
