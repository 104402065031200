import Services from "services";

class InfoPeluangApi {
  get(params) {
    return Services.get("/hrdu/approval_info_peluang/list/", { params });
  }

  getHistory(params) {
    return Services.get("/hrdu/approval_info_peluang/history/", { params });
  }

  getSingle(params) {
    return Services.get("/hrdu/approval_info_peluang/detail", { params });
  }

  approve(data) {
    return Services.post("/hrdu/approval_info_peluang/approve", data);
  }
}

export default new InfoPeluangApi();
