import { calcJumlah } from "./calcJumlah";

export const formInitialValues = (data = {}) => {
  const { REACT_APP_SHOW_FILE_BASE_URL } = process.env;
  const { sisa_petty_cash } = calcJumlah({
    data: data?.detail ?? [],
    petty_cash: data?.nominal_penerimaan_petty_cash ?? 0,
  });

  return Object({
    tgl_pengembalian_sisa_petty_cash:
      data?.tgl_pengembalian_sisa_petty_cash ?? "",
    no_pengembalian_sisa_petty_cash:
      data?.no_pengembalian_sisa_petty_cash ?? "",
    id_realisasi_petty_cash: data?.id_realisasi_petty_cash,
    tgl_realisasi_petty_cash: data?.tgl_realisasi_petty_cash,
    no_realisasi_petty_cash: data?.no_realisasi_petty_cash,
    nominal_realisasi_petty_cash: 0,
    detail: Object.values(data?.detail ?? []).map((item) => ({
      ...item,
      files: Object.values(item?.media ?? []).map((mediaItem) => ({
        ...mediaItem,
        nama: mediaItem?.path_media?.split("/").pop(),
        link: `${REACT_APP_SHOW_FILE_BASE_URL}/${mediaItem.path_media}`,
      })),
    })),

    id_penerimaan_petty_cash: data.id_penerimaan_petty_cash,
    tgl_penerimaan_petty_cash: data?.tgl_penerimaan_petty_cash,
    no_penerimaan_petty_cash: data?.no_penerimaan_petty_cash,
    nominal_penerimaan_petty_cash: data?.nominal_penerimaan_petty_cash,
    nama_proyek: data?.nama_proyek,
    tgl_awal_petty_cash: data?.tgl_awal_petty_cash,
    tgl_akhir_petty_cash: data?.tgl_akhir_petty_cash,
    sisa_durasi: data?.sisa_durasi,
    sisa_petty_cash: sisa_petty_cash,
    nama_unit_produksi: data?.nama_unit_produksi,
  });
};
