import React, { useState, useEffect } from 'react'
import InfoSection from './InfoSection'
import TableSection from './TableSection'
import { PeluangApi } from 'api'
import { DataStatus } from 'components'

const AnalisaBarangJadi = ({ processedData }) => {
  const [data, setData] = useState({})
  const [dataGambar, setDataGambar] = useState([])
  const [dataFile, setDataFile] = useState([])
  const [isFetch, setIsFetch] = useState({ loading: false, success: true })

  const getInitialData = () => {
    setIsFetch({ loading: true, success: true })
    const { id_item_atribut } = processedData

    PeluangApi.getAnalisa({ id_item_atribut })
      .then(({ data }) => {
        let gambar = []
        let file = []

        data?.data?.path_gambar?.map(val => {
          const generalFileType = val.path_gambar.split('.').pop()
          // Check apakah data adalah gambar
          if (['jpg', 'png', 'gif', 'jpeg'].includes(generalFileType)) {
            return gambar.push(val)
          }
          return file.push(val)
        })

        setData(data?.data ?? {})
        setDataGambar(gambar ?? [])
        setDataFile(file ?? [])
        setIsFetch({ loading: false, success: true })
      })
      .catch(err => setIsFetch({ loading: false, success: false }))
  }

  useEffect(() => {
    getInitialData()
    return () => { }
  }, [])

  useEffect(() => {
    { console.log(isFetch) }
    return () => { }
  }, [isFetch])

  return (
    <>
      {
        isFetch.loading ? <DataStatus loading={isFetch.loading} text='Memuat data ... ' />
          : !isFetch.success && !isFetch.loading ? <DataStatus text='Gagal memuat data!' />
            :
            <>
              <InfoSection data={data} dataGambar={dataGambar} dataFile={dataFile} />
              <TableSection data={data} />
            </>
      }
    </>
  )
}

export default AnalisaBarangJadi
