import { InfoItemHorizontal } from "components";
import { useFormikContext } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DateConvert } from "utilities";

export const InfoSubKegiatanSection = ({ data }) => {
  const { values } = useFormikContext();
  return (
    <Row>
      <Col lg="6">
        <InfoItemHorizontal
          label="Tgl. Sub Kegiatan"
          text={
            data.tgl_sub_kegiatan
              ? DateConvert(new Date(data.tgl_sub_kegiatan)).detail
              : "-"
          }
        />
        <InfoItemHorizontal
          label="No. Sub Kegiatan"
          text={data.no_sub_kegiatan ?? "-"}
        />
        <InfoItemHorizontal
          label="Nama Kegiatan"
          text={data.nama_sub_kegiatan ?? "-"}
        />
        <InfoItemHorizontal
          label="Penanggung Jawab"
          text={data.nama_penanggung_jawab_sub_kegiatan ?? "-"}
        />
      </Col>
      <Col lg="6">
        <InfoItemHorizontal
          label="Periode Mulai"
          text={
            data.periode_mulai_sub_kegiatan
              ? DateConvert(new Date(data.periode_mulai_sub_kegiatan)).detail
              : "-"
          }
        />
        <InfoItemHorizontal
          label="Periode Selesai"
          text={
            data.periode_selesai_sub_kegiatan
              ? DateConvert(new Date(data.periode_selesai_sub_kegiatan)).detail
              : "-"
          }
        />
        <InfoItemHorizontal
          label="Lama Periode"
          text={`${data.lama_periode_sub_kegiatan ?? "-"} Hari`}
        />
        <InfoItemHorizontal
          label="Deskripsi"
          text={data.deskripsi_sub_kegiatan ?? "-"}
        />
      </Col>
    </Row>
  );
};
