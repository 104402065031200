import React, { Fragment, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useFormikContext } from "formik";
import { Input, DatePicker, InputCurrency } from "components2";
import { rupiahConvert } from "utilities2";
import {
  generateNoPettyCash,
  calcJumlah,
} from "../__RealisasiPettyCashUtils__";
import { InfoItemHorizontal, InfoItemVertical } from "components";
import { DateConvert, RupiahConvert } from "utilities";

const DetailFormContent = ({ jumlah }) => {
  const { values } = useFormikContext();
  return (
    <Row>
      <Col md="3">
        <InfoItemVertical
          label="Tgl. Penerimaan Petty Cash"
          text={
            values?.tgl_realisasi_petty_cash
              ? DateConvert(new Date(values?.tgl_realisasi_petty_cash)).detail
              : "-"
          }
        />
      </Col>
      <Col md="3">
        <InfoItemVertical
          label="No. Penerimaan Petty Cash"
          text={values.no_realisasi_petty_cash ?? "-"}
        />
      </Col>
      <Col md="3">
        <InfoItemVertical
          label="Sisa Petty Cash"
          text={rupiahConvert().getWithComa(jumlah.sisa_petty_cash)}
        />
      </Col>
    </Row>
  );
};
export const RealisasiPettyCashContent = ({ action }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  const TODAY = new Date();

  const petty_cash = values.nominal_penerimaan_petty_cash;
  const data = values.detail ?? [];
  const jumlah = calcJumlah({ data, petty_cash });

  useEffect(async () => {
    if (action === "CREATE") {
      const noPettyCash = await generateNoPettyCash(TODAY);

      setValues({
        ...values,
        no_realisasi_petty_cash: noPettyCash,
        tgl_realisasi_petty_cash: TODAY,
      });
    }
  }, [action]);

  return (
    <Fragment>
      <Row>
        <Col>
          <InfoItemHorizontal
            label="Tgl. Penerimaan Petty Cash"
            width={200}
            text={
              values?.tgl_penerimaan_petty_cash
                ? DateConvert(new Date(values?.tgl_penerimaan_petty_cash))
                    .detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Penerimaan Petty Cash"
            width={200}
            text={values?.no_penerimaan_petty_cash ?? "-"}
          />
          <InfoItemHorizontal
            label="Nominal Penerimaan Petty Cash"
            width={200}
            text={
              RupiahConvert(
                parseFloat(
                  values?.nominal_penerimaan_petty_cash ?? 0
                ).toString()
              ).getWithComa
            }
          />
          <InfoItemHorizontal
            label="Unit Produksi"
            width={200}
            text={values?.nama_unit_produksi ?? "-"}
          />
        </Col>
        <Col>
          <InfoItemHorizontal
            label="Tgl. Awal Petty Cash"
            width={200}
            text={
              values?.tgl_awal_petty_cash
                ? DateConvert(new Date(values?.tgl_awal_petty_cash)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Tgl. Akhir Petty Cash"
            width={200}
            text={
              values?.tgl_akhir_petty_cash
                ? DateConvert(new Date(values?.tgl_akhir_petty_cash)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Sisa Masa Berlaku Petty Cash"
            width={200}
            text={`${values?.sisa_durasi ?? 0} Hari`}
          />
        </Col>
      </Row>

      <hr />
      {action === "DETAIL" ? (
        <DetailFormContent jumlah={jumlah} />
      ) : (
        <Row>
          <Col md="3">
            <DatePicker
              disabled={action === "DETAIL"}
              label="Tgl. Realisasi Petty Cash"
              dateFormat="dd/MM/yyyy"
              selected={
                values.tgl_realisasi_petty_cash
                  ? new Date(values.tgl_realisasi_petty_cash)
                  : ""
              }
              onChange={async (date) => {
                if (action === "CREATE") {
                  const noPettyCash = await generateNoPettyCash(date);

                  setValues({
                    ...values,
                    no_realisasi_petty_cash: noPettyCash,
                    tgl_realisasi_petty_cash: date,
                  });
                  return;
                }
                setValues({ ...values, tgl_realisasi_petty_cash: date });
              }}
              error={
                errors.tgl_realisasi_petty_cash &&
                touched.tgl_realisasi_petty_cash &&
                true
              }
              errorText={errors.tgl_realisasi_petty_cash}
            />
          </Col>

          <Col md="3">
            <Input
              disabled
              label="No. Realisasi Petty Cash"
              className="d-flex flex-column"
              value={values.no_realisasi_petty_cash}
            />
          </Col>

          <Col md="3">
            <InputCurrency
              disabled
              label="Sisa Petty Cash"
              className="d-flex flex-column text-right"
              value={rupiahConvert().getWithComa(jumlah.sisa_petty_cash)}
            />
          </Col>
        </Row>
      )}
    </Fragment>
  );
};
