export const formInitialValues = (data) => {
  const base_url = process.env.REACT_APP_ASM_FILE_BASE_URL;
  return Object({
    id_purchase_order_aset_detail:
      data?.id_purchase_order_aset_detail ?? undefined,
    id_petugas_penerimaan: data?.id_petugas_penerimaan ?? undefined,
    tgl_penerimaan_aset: data?.tgl_penerimaan_aset ?? undefined,
    no_penerimaan_aset: data?.no_penerimaan_aset ?? undefined,
    no_surat_jalan_vendor: data?.no_surat_jalan_vendor ?? undefined,
    nama_petugas_penerimaan: data?.nama_petugas_penerimaan ?? undefined,
    tgl_purchase_order_aset: data?.tgl_purchase_order_aset ?? undefined,
    no_purchase_order_aset: data?.no_purchase_order_aset ?? "",
    tgl_seleksi_vendor_aset: data?.tgl_seleksi_vendor_aset ?? undefined,
    no_seleksi_vendor_aset: data?.no_seleksi_vendor_aset ?? "",
    nama_grup_aset: data?.nama_grup_aset ?? "",
    nama_jenis_aset: data?.nama_jenis_aset ?? "",
    nama_kategori_aset: data?.nama_kategori_aset ?? "",
    nama_aset: data?.nama_aset ?? "",
    keperluan: data?.keperluan ?? "",
    file:
      data?.file?.length > 0 ? data?.file?.map((val) => val?.path_file) : [],
    bukti_penerimaan: data?.bukti_penerimaan
      ? base_url + data?.bukti_penerimaan
      : undefined,
  });
};
