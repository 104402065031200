import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { useFormikContext } from "formik";
import { InfoItemVertical } from "components2";
import { dateConvert } from "utilities2";
import { DateConvert, RupiahConvert } from "utilities";
import { InfoItemHorizontal } from "components";

export const PenerimaanPettyCashConfirmationContent = () => {
  const { values } = useFormikContext();

  return (
    <Fragment>
      <Row>
        <Col>
          <InfoItemHorizontal
            label="Tgl. Pengajuan Petty Cash"
            text={
              values?.tgl_pengajuan_petty_cash
                ? DateConvert(new Date(values?.tgl_pengajuan_petty_cash)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Pengajuan Petty Cash"
            text={values?.no_pengajuan_petty_cash ?? "-"}
          />
          <InfoItemHorizontal
            label="Nominal Pengajuan Petty Cash"
            text={
              RupiahConvert(
                parseFloat(values?.nominal_pengajuan ?? 0).toString()
              ).getWithComa
            }
          />
          <InfoItemHorizontal
            label="Unit Produksi"
            text={values?.nama_unit_produksi ?? "-"}
          />
          <InfoItemHorizontal label="Divisi" text={values.kode_modul} />
        </Col>
        <Col>
          <InfoItemHorizontal
            label="Tgl. Mutasi Petty Cash"
            text={
              values?.tgl_pengajuan_petty_cash
                ? DateConvert(new Date(values?.tgl_pengajuan_petty_cash)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Mutasi Petty Cash"
            text={values?.no_bukti_mutasi ?? "-"}
          />
          <InfoItemHorizontal
            label="Nominal Mutasi Petty Cash"
            text={
              RupiahConvert(parseFloat(values?.nominal_mutasi ?? 0).toString())
                .getWithComa
            }
          />
          <InfoItemHorizontal
            label="Tgl. Awal Petty Cash"
            text={
              values?.tgl_awal_petty_cash
                ? DateConvert(new Date(values?.tgl_awal_petty_cash)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Tgl. Akhir Petty Cash"
            text={
              values?.tgl_akhir_petty_cash
                ? DateConvert(new Date(values?.tgl_akhir_petty_cash)).detail
                : "-"
            }
          />
        </Col>
      </Row>

      <hr />

      <Row>
        <Col>
          <InfoItemVertical
            label="Tgl. Penerimaan Petty Cash"
            text={dateConvert().getSlashDMY(
              new Date(values?.tgl_penerimaan_petty_cash)
            )}
          />
        </Col>
        <Col>
          <InfoItemVertical
            label="No. Penerimaan Petty Cash"
            text={values?.no_penerimaan_petty_cash}
          />
        </Col>
        <Col>
          <InfoItemVertical
            label="Nominal Penerimaan Petty Cash"
            text={
              RupiahConvert(
                parseFloat(
                  values?.nominal_penerimaan_petty_cash ?? 0
                ).toString()
              ).getWithComa
            }
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <InfoItemVertical label="Keterangan" text={values?.keterangan} />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="d-flex flex-column mb-2">
            <small style={{ fontSize: 12, display: "block" }}>
              Bukti Penerimaan Petty Cash
            </small>

            <img
              alt=""
              className="mt-2 "
              style={{ minWidth: 150, maxWidth: 200, height: "auto" }}
              src={values.bukti.url}
            />
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};
