export const formInitialValues = (data) => {
  return Object({
    id_permintaan_aset: data?.id_permintaan_aset ?? undefined,
    tgl_permintaan_aset: data?.tgl_permintaan_aset ?? undefined,
    tgl_seleksi_vendor_aset: data?.tgl_seleksi_vendor_aset ?? undefined,
    no_permintaan_aset: data?.no_permintaan_aset ?? undefined,
    no_seleksi_vendor_aset: data?.no_seleksi_vendor_aset ?? undefined,
    catatan_permintaan: data?.catatan_permintaan ?? "",
    nama_entitas_aset: data?.nama_entitas_aset ?? "",
    nama_grup_aset: data?.nama_grup_aset ?? "",
    nama_kategori_aset: data?.nama_kategori_aset ?? "",
    nama_jenis_aset: data?.nama_jenis_aset ?? "",
    nama_aset: data?.nama_aset ?? "",
    keperluan: data?.keperluan ?? "",
    metode_pengadaan: data?.metode_pengadaan ?? "",
    nama_unit_organisasi: data?.nama_unit_organisasi ?? "",
    keterangan: data?.keterangan ?? "",
    nama_vendor: data?.nama_vendor ?? "",
    harga_kesepakatan: data?.harga_kesepakatan ?? "",
    stakeholder: data?.stakeholder ?? [],
    file:
      data?.file?.length > 0 ? data?.file?.map((val) => val?.path_file) : [],
  });
};
