import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Modal } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Input,
  DatePicker,
  TextArea,
} from "../../../components";
import { DateConvert, RupiahConvert } from "../../../utilities";
import { MutasiAntarGudangApi } from "../../../api";

const DetailMutasiAntarGudang = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const no_mutasi_antar_gudang = state?.no_mutasi_antar_gudang;
  const tab = state?.tab ?? "";
  const { id } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataMutasiGudang, setDataMutasiGudang] = useState([]);
  const [approveStatus, setApproveStatus] = useState("V");
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    MutasiAntarGudangApi.getSingle({ no_mutasi_antar_gudang: no_mutasi_antar_gudang })
      .then(({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : "V";
        setDataMutasiGudang(data.data);
        setApproveStatus(behavior.toUpperCase());
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const whenNoMutasiGudang = () => {
    setIsPageLoading(false);
    setIsFetchingFailed(true);
  };

  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10);

    const checkStatus = () => {
      if (modalConfig.type === "revise") {
        return "REV";
      }

      if (modalConfig.type === "approve") {
        return "APP";
      }

      if (modalConfig.type === "reject") {
        return "REJ";
      }
    };

    const finalValues = {
      no_transaksi: dataMutasiGudang.detail.no_transaksi,
      status_approval: checkStatus(),
      catatan: values.catatan,
      tgl_approval: today,
      approval_baseline: dataMutasiGudang.detail.baseline,
    };

    MutasiAntarGudangApi.approve(finalValues)
      .then(() =>
        history.push("/human-resource/approval/mutasi-antar-gudang", {
          alert: {
            show: true,
            variant: "primary",
            text: `Approval berhasil disimpan!`,
          },
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Approval gagal disimpan!",
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        getInitialData();
      });
  };

  useEffect(() => {
    setNavbarTitle("Approval Mutasi Antar Gudang");
    getInitialData();
    no_mutasi_antar_gudang ? getInitialData() : whenNoMutasiGudang();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const InfoSection = () => {
    const InfoItem = ({ title, value, isPaddingTop = false }) => (
      <tr>
        <td className="align-top" style={isPaddingTop ? { paddingTop: "10px" } : {}}>
          {title}
        </td>
        <td className="pl-4 pr-2 align-top">:</td>
        <td className="align-top">{value}</td>
      </tr>
    );

    return (
      <Row>
        <Col md>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="Tgl. Mutasi"
                value={
                  dataMutasiGudang?.detail?.tgl_mutasi_antar_gudang
                    ? DateConvert(new Date(dataMutasiGudang?.detail?.tgl_mutasi_antar_gudang))
                      .detail
                    : "-"
                }
              />
              <InfoItem
                title="No. Mutasi"
                value={dataMutasiGudang?.detail?.no_mutasi_antar_gudang ?? "-"}
              />
              <InfoItem
                title="Gudang Asal"
                value={dataMutasiGudang?.detail?.nama_gudang_asal ?? "-"}
              />
              <InfoItem
                title="Gudang Tujuan"
                value={dataMutasiGudang?.detail?.nama_gudang_tujuan ?? "-"}
              />
              <InfoItem
                title="Item Barang"
                value={`${dataMutasiGudang?.detail?.kode_item
                    ? `(${dataMutasiGudang?.detail?.kode_item})`
                    : ""
                  } ${dataMutasiGudang?.detail?.nama_item ?? "-"}`}
              />
              <InfoItem
                title="Qty. Mutasi"
                value={`${dataMutasiGudang?.detail?.qty_mutasi ?? "0"} ${dataMutasiGudang?.detail?.nama_satuan ?? ""
                  }`}
              />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const ModalSection = ({ formik }) => {
    const { values, isSubmitting, handleSubmit } = formik;

    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <h6>
            <b>
              {modalConfig.title === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.title}{" "}
              dengan catatan :
            </b>
          </h6>
          <span>{values.catatan}</span>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type
            }
            variant={
              modalConfig.type === "approve"
                ? "success"
                : modalConfig.type === "revise"
                  ? "warning"
                  : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({ formik }) => {
    const { values, errors, touched, dirty, validateForm, handleChange } = formik;
    const dataPengaju = dataMutasiGudang?.approval ? dataMutasiGudang.approval : [];

    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Detail Mutasi Antar Gudang</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataPengaju.map((val, index) => (
              <Col sm>
                <InfoItem
                  title1={
                    val.status_approval == "VER" ? `Pemeriksa ${val.approval_level}` : "Pengesah"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2="Catatan"
                  value2={val.catatan ?? "-"}
                />
              </Col>
            ))}
          </Row>
          {dataPengaju.length > 1 && <hr />}
          {tab !== "history" && <div>
            <TextArea
              label="Catatan"
              name="catatan"
              placeholder="Masukan catatan"
              value={values.catatan}
              onChange={handleChange}
              error={errors.catatan && true}
              errorText={errors.catatan}
            />
            <div className="d-flex justify-content-end mt-3">
              {approveStatus === "A" ? (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "reject",
                          title: (
                            <span className="text-danger">Reject Detail Mutasi Antar Gudang</span>
                          ),
                        });
                      }

                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "approve",
                          title: (
                            <span className="text-success">
                              {approveStatus === "A" ? "Approve" : "Verify"} Detail Mutasi Antar
                              Gudang
                            </span>
                          ),
                        });
                      }

                      validateForm();
                    }}
                  />
                </>
              ) : (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "reject",
                          title: (
                            <span className="text-danger">Reject Detail Mutasi Antar Gudang</span>
                          ),
                        });
                      }

                      validateForm();
                    }}
                  />
                  <ActionButton
                    text="Revise"
                    variant="warning"
                    className="m-1 text-white"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "revise",
                          title: (
                            <span className="text-warning">Revise Detail Mutasi Antar Gudang</span>
                          ),
                        });
                      }

                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "approve",
                          title: (
                            <span className="text-success">
                              {approveStatus === "A" ? "Approve" : "Verify"} Detail Mutasi Antar
                              Gudang
                            </span>
                          ),
                        });
                      }

                      validateForm();
                    }}
                  />
                </>
              )}
            </div>
          </div>}
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Mutasi Antar Gudang</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />

          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <InfoSection />
          )}
        </Card.Body>
      </Card>
      {!isFetchingFailed && !isPageLoading && (
        <Formik
          initialValues={{ catatan: "" }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required("Masukan catatan"),
          })}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <>
              <FormCard formik={formik} />
              <ModalSection formik={formik} />
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default DetailMutasiAntarGudang;
