import React, { useState, useEffect } from "react";
import { Alert, DataStatus } from "components";
import { DecimalConvert, RupiahConvert } from "utilities";

const TableAnalisa = ({ data, tableHead }) => {
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const tableStyling = {
    padding: "0.5px",
    fontSize: "14px",
    verticalAlign: "middle",
  };

  const getTotal = () =>
    data?.reduce(
      (total, { sub_total }) => total + parseFloat(sub_total ?? 0),
      0
    );

  const ListDataTable = ({ index, val }) => {
    return (
      <tr key={index}>
        <td style={tableStyling} className="text-center">
          {index + 1}
        </td>
        <td style={tableStyling} className="px-2">
          {val.kode_item}
        </td>
        <td style={tableStyling} className="px-2">
          {val.nama_item}
        </td>
        <td style={tableStyling} className="px-2">
          {val.nama_satuan}
        </td>
        <td style={tableStyling} className="text-right">
          {val.harga &&
            RupiahConvert(parseFloat(val.harga).toString()).getWithComa}
        </td>
        <td style={tableStyling} className="text-right">
          {val.qty ? DecimalConvert(val.qty).getWithComa : "0"}
        </td>
        <td style={tableStyling} className="text-right">
          {RupiahConvert(String(parseFloat(val.sub_total ?? 0))).getWithComa}
        </td>
      </tr>
    );
  };

  return (
    <>
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />
      <div className="table-responsive">
        <table className="table table-bordered bg-white table-sm">
          <thead className="text-center bg-light">
            <tr>
              <th
                className="align-middle p-2"
                style={{ ...tableStyling, width: 10 }}
              >
                No.
              </th>
              <th
                className="align-middle p-2"
                style={{ ...tableStyling, width: 105 }}
              >
                Kode Item
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                {tableHead?.column3 ?? "Nama Item"}
              </th>
              <th
                className="align-middle p-2"
                style={{ ...tableStyling, width: 200 }}
              >
                Satuan
              </th>
              <th
                className="align-middle p-2"
                style={{ ...tableStyling, width: 200 }}
              >
                {tableHead?.column5 ?? "Harga Satuan"}
              </th>
              <th
                className="align-middle p-2"
                style={{ ...tableStyling, width: 80 }}
              >
                Qty
              </th>
              <th
                className="align-middle p-2"
                style={{ ...tableStyling, width: 200 }}
              >
                Total Price (Rp)
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((val, index) => {
                const qty = parseFloat(val?.qty ?? 0);
                const harga = parseFloat(val?.harga ?? 0);
                const sub_total = Math.round(qty * harga);
                val.sub_total = sub_total;
                return <ListDataTable key={index} index={index} val={val} />;
              })
            ) : (
              <tr>
                <td colSpan={7} className="text-center">
                  Tidak ada data
                </td>
              </tr>
            )}
            {data?.length > 0 && (
              <tr className="bg-light">
                <td colSpan={6} className="text-right p-2" style={tableStyling}>
                  <b>Total Price : </b>
                </td>
                <td className="text-right" style={tableStyling}>
                  <b>{RupiahConvert(String(getTotal())).getWithComa}</b>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableAnalisa;
