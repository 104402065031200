import Services from 'services';

class PenerimaPettyCashApi {
  get(params) {
    return Services.get('/hrdu/approval_penerima_petty_cash/list', { params });
  }

  getHistory(params) {
    return Services.get('/hrdu/approval_penerima_petty_cash/history/list', {
      params,
    });
  }

  getSingle(params) {
    return Services.get('/hrdu/approval_penerima_petty_cash/detail', {
      params,
    });
  }

  approve(data) {
    return Services.post('/hrdu/approval_penerima_petty_cash/approve', data);
  }
}

export default new PenerimaPettyCashApi();
