import Services from 'services';

class PengembalianSisaPettyCashApi {
  async getList(params) {
    const fetch = await Services.get(
      '/hrdu/pengembalian_sisa_petty_cash/page',
      {
        params,
      }
    );
    return fetch.data;
  }

  async getSingle(params) {
    const fetch = await Services.get(
      '/hrdu/pengembalian_sisa_petty_cash/single',
      {
        params,
      }
    );
    return fetch.data;
  }

  async getListRealisasi(params) {
    const fetch = await Services.get(
      '/hrdu/pengembalian_sisa_petty_cash/list_realisasi',
      { params }
    );
    return fetch.data;
  }

  async getSingleRealisasi(params) {
    const fetch = await Services.get(
      '/hrdu/pengembalian_sisa_petty_cash/single_realisasi',
      { params }
    );
    return fetch.data;
  }

  async dropdownSatuan() {
    const fetch = await Services.get(
      '/hrdu/pengembalian_sisa_petty_cash/d_satuan'
    );
    return fetch.data.data.map((v) => ({
      value: v.id_satuan,
      label: v.nama_satuan,
      kode_satuan: v.kode_satuan,
    }));
  }

  generateNumber(params) {
    return Services.get('/hrdu/pengembalian_sisa_petty_cash/no_baru', {
      params,
    });
  }

  create(data) {
    return Services.post('/hrdu/pengembalian_sisa_petty_cash', data);
  }

  update(data) {
    return Services.put('/hrdu/pengembalian_sisa_petty_cash', data);
  }
}

export default new PengembalianSisaPettyCashApi();
