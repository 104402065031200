import { InfoItemHorizontal } from "components";
import { useFormikContext } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DateConvert } from "utilities";

export const InfoProgramSection = ({ data }) => {
  const { values } = useFormikContext();
  return (
    <Row>
      <Col lg="6">
        <InfoItemHorizontal
          label="Tgl. Program"
          text={
            data?.tgl_program
              ? DateConvert(new Date(data?.tgl_program)).detail
              : "-"
          }
        />
        <InfoItemHorizontal
          label="No. Program"
          text={data?.no_program ?? "-"}
        />
        <InfoItemHorizontal
          label="Nama Program"
          text={data?.nama_program ?? "-"}
        />
        <InfoItemHorizontal
          label="Unit Organisasi"
          text={data?.nama_unit_organisasi ?? "-"}
        />
      </Col>
      <Col lg="6">
        <InfoItemHorizontal
          label="Penanggung Jawab"
          text={data?.nama_penanggung_jawab_program ?? "-"}
        />
        <InfoItemHorizontal
          label="Periode Mulai"
          text={
            data?.periode_mulai_program
              ? DateConvert(new Date(data?.periode_mulai_program)).detail
              : "-"
          }
        />
        <InfoItemHorizontal
          label="Periode Selesai"
          text={
            data?.periode_selesai_program
              ? DateConvert(new Date(data?.periode_selesai_program)).detail
              : "-"
          }
        />
        <InfoItemHorizontal
          label="Lama Periode"
          text={`${data?.lama_periode_program ?? "-"} Hari`}
        />
        <InfoItemHorizontal
          label="Deskripsi"
          text={data?.deskripsi_program ?? "-"}
        />
      </Col>
    </Row>
  );
};
