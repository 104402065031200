export const formInitialValues = (data = {}, action = "CREATE") => {
  const isCreate = action === "CREATE";

  return Object({
    id_realisasi_petty_cash: isCreate
      ? undefined
      : data?.id_realisasi_petty_cash,
    tgl_realisasi_petty_cash: isCreate ? "" : data?.tgl_realisasi_petty_cash,
    no_realisasi_petty_cash: isCreate ? "" : data?.no_realisasi_petty_cash,
    nominal_realisasi_petty_cash: 0,
    detail: data?.detail ?? [],

    id_penerimaan_petty_cash: data.id_penerimaan_petty_cash,
    tgl_penerimaan_petty_cash: data?.tgl_penerimaan_petty_cash,
    no_penerimaan_petty_cash: data?.no_penerimaan_petty_cash,
    nominal_penerimaan_petty_cash: data?.nominal_penerimaan_petty_cash,
    nama_proyek: data?.nama_proyek,
    tgl_awal_petty_cash: data?.tgl_awal_petty_cash,
    tgl_akhir_petty_cash: data?.tgl_akhir_petty_cash,
    sisa_durasi: data?.sisa_durasi,
    sisa_petty_cash: data?.nominal_penerimaan_petty_cash,
    keterangan: data?.keterangan,
    kode_modul: data?.kode_modul,
    nama_unit_produksi: data?.nama_unit_produksi,
  });
};
