import Services from "../../services";

class PurchaseOrderAsetApi {
  get(params) {
    return Services.get("/hrdu/approval_purchase_order_aset/list", { params });
  }

  getHistory(params) {
    return Services.get("/hrdu/approval_purchase_order_aset/history/list", {
      params,
    });
  }

  getSingle(params) {
    return Services.get("/hrdu/approval_purchase_order_aset/detail", {
      params,
    });
  }

  save(data) {
    return Services.post("/hrdu/approval_purchase_order_aset/approve", data);
  }
}

export default new PurchaseOrderAsetApi();
