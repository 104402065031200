import React, { Fragment, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useFormikContext } from "formik";
import { Input, DatePicker, InputCurrency } from "components2";
import { rupiahConvert } from "utilities2";
import { generateNoPettyCash } from "../__PengembalianSisaPettyCashUtils__";
import { InfoItemHorizontal } from "components";
import { DateConvert, RupiahConvert } from "utilities";

export const RealisasiPettyCashContent = ({ action }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  const TODAY = new Date();

  useEffect(
    () =>
      (async () => {
        if (action === "CREATE") {
          const noPettyCash = await generateNoPettyCash(TODAY);

          setValues({
            ...values,
            no_pengembalian_sisa_petty_cash: noPettyCash,
            tgl_pengembalian_sisa_petty_cash: TODAY,
          });
        }
      })(),
    [action]
  );

  return (
    <Fragment>
      <Row>
        <Col lg="4">
          <InfoItemHorizontal
            label="Tgl. Realisasi Petty Cash"
            fontSize={12}
            width={170}
            text={
              values?.tgl_realisasi_petty_cash
                ? DateConvert(new Date(values?.tgl_realisasi_petty_cash)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Realisasi Petty Cash"
            fontSize={12}
            width={170}
            text={values?.no_realisasi_petty_cash ?? "-"}
          />
          <InfoItemHorizontal
            label="Nominal Realisasi Petty Cash"
            fontSize={12}
            width={170}
            text={
              RupiahConvert(
                parseFloat(values?.nominal_realisasi_petty_cash ?? 0).toString()
              ).getWithComa
            }
          />
          <InfoItemHorizontal
            label="Nominal Sisa Petty Cash"
            fontSize={12}
            width={170}
            text={
              RupiahConvert(parseFloat(values?.sisa_petty_cash ?? 0).toString())
                .getWithComa
            }
          />
        </Col>
        <Col lg="4">
          <InfoItemHorizontal
            label="Tgl. Penerimaan Petty Cash"
            fontSize={12}
            width={170}
            text={
              values?.tgl_penerimaan_petty_cash
                ? DateConvert(new Date(values?.tgl_penerimaan_petty_cash))
                    .detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Penerimaan Petty Cash"
            fontSize={12}
            width={170}
            text={values?.no_penerimaan_petty_cash ?? "-"}
          />
          <InfoItemHorizontal
            label="Nominal Penerimaan Petty Cash"
            fontSize={12}
            width={170}
            text={
              RupiahConvert(
                parseFloat(
                  values?.nominal_penerimaan_petty_cash ?? 0
                ).toString()
              ).getWithComa
            }
          />
          <InfoItemHorizontal
            label="Unit Produksi"
            fontSize={12}
            width={170}
            text={values?.nama_unit_produksi ?? "-"}
          />
        </Col>
        <Col lg="4">
          <InfoItemHorizontal
            label="Tgl. Awal Petty Cash"
            fontSize={12}
            width={170}
            text={
              values?.tgl_awal_petty_cash
                ? DateConvert(new Date(values?.tgl_awal_petty_cash)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Tgl. Akhir Petty Cash"
            fontSize={12}
            width={170}
            text={
              values?.tgl_akhir_petty_cash
                ? DateConvert(new Date(values?.tgl_akhir_petty_cash)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Sisa Masa Berlaku Petty Cash"
            fontSize={12}
            width={170}
            text={`${values?.sisa_durasi} Hari`}
          />
        </Col>
      </Row>
      <hr />

      <Row>
        <Col md="3">
          <DatePicker
            disabled={action === "DETAIL"}
            label="Tgl. Pengembalian Sisa Petty Cash"
            dateFormat="dd/MM/yyyy"
            selected={
              values.tgl_pengembalian_sisa_petty_cash
                ? new Date(values.tgl_pengembalian_sisa_petty_cash)
                : ""
            }
            onChange={async (date) => {
              if (action === "CREATE") {
                const noPettyCash = await generateNoPettyCash(date);

                setValues({
                  ...values,
                  no_pengembalian_sisa_petty_cash: noPettyCash,
                  tgl_pengembalian_sisa_petty_cash: date,
                });
                return;
              }
              setValues({ ...values, tgl_pengembalian_sisa_petty_cash: date });
            }}
            error={
              errors.tgl_pengembalian_sisa_petty_cash &&
              touched.tgl_pengembalian_sisa_petty_cash
            }
            errorText={errors.tgl_pengembalian_sisa_petty_cash}
          />
        </Col>

        <Col md="3">
          <Input
            disabled
            label="No. Pengembalian Sisa Petty Cash"
            className="d-flex flex-column"
            value={values.no_pengembalian_sisa_petty_cash}
          />
        </Col>
      </Row>
    </Fragment>
  );
};
