const ApprovalStatus = (data, approveStatus) => {
  if (data === 'REJ') {
    return { text: 'Reject', variant: 'danger' };
  } else if (data === 'REV') {
    return { text: 'Revise', variant: 'warning' };
  } else if (data === 'APP' || data === 'VER') {
    return {
      text: approveStatus === 'A' ? 'Approve' : 'Verify',
      variant: 'success',
    };
  }
};

export default ApprovalStatus;
